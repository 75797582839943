// This utility is used to build queries 
import store from '@/store'
import 
{ 
  shoppingCartGetDepartureDate, 
  shoppingCartGetReturnDate,
  shoppingCartGetOriginId,
  shoppingCartGetOriginName,
  shoppingCartGetDestinationId,
  shoppingCartGetDestinationName,
  shoppingCartGetDepartureTripId,
  shoppingCartGetFares
} from '@/utilities/ShoppingCartUtility'
import forEach from 'lodash/forEach'

// BuildQuery from shoppingCart
export function createQueryFromShoppingCart (editId, editBookingNumber, capacity_error) {
  const shoppingCart = store.getters.shoppingCart

  let query = {}

  if (shoppingCart.discount_code) {
    query.discountCode = shoppingCart.discount_code
  }

  const departureDate = shoppingCartGetDepartureDate()
  if (departureDate) { 
    query.departureDate = departureDate
  }

  const returnDate = shoppingCartGetReturnDate()
  if (returnDate) {
    query.returnDate = returnDate
  }

  const originId = shoppingCartGetOriginId()
  if (originId) {
    query.originId = originId
  }

  const originName = shoppingCartGetOriginName() 
  if (originName) {
    query.originName = originName
  }

  const destinationId = shoppingCartGetDestinationId()
  if (destinationId) {
    query.destinationId = destinationId
  }

  const destinationName = shoppingCartGetDestinationName()
  if (destinationName) {
    query.destinationName = destinationName
  }

  const departureTripId = shoppingCartGetDepartureTripId()
  if (departureTripId) {
    query.departureTripId = departureTripId
  }

  const fares = shoppingCartGetFares()
  forEach(fares, fare => {
    query['qty_' + fare.id] = fare.quantity
  })

  if (editId) { 
    query.edit_id = editId
  }

  if(editBookingNumber) {
    query.edit_booking_number = editBookingNumber
  }

  if (capacity_error) {
    query.capacity_error = capacity_error
  }

  return query
}

export default {
  createQueryFromShoppingCart
}


