import api from '../api/index'
import router from '@/router/'
import Vue from 'vue'
import Vuex from 'vuex'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _sum from 'lodash/sum'
import _toLower from 'lodash/toLower'
import _upperFirst from 'lodash/upperFirst'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import { texts } from '../utilities/texts'
import { generateTripId } from '../utilities/TripUtility'
import { memberId } from '@/utilities/UserUtility'

Vue.use(Vuex)

const state = {

  edit: null, // Used to hold the edit object when editing a booking
  dateTimeOnServer: new Date(), // Only for legacy support
  initialized: false,
  query: null,
  lang: 'nb-NO',
  apiUrl: '',
  origin: undefined,
  destination: undefined,
  fares: [
    {
      id: '3d2fe8d3-c3c9-44b8-917c-725d859376ba',
      isDefault: true,
      groupName: 'Billetter',
      count: 0
    },
    {
      id: 'b942bbca-99df-446f-99e8-ee8191066fa6',
      isDefault: false,
      groupName: 'Billetter',
      count: 0
    },
    {
      id: '21650b77-1a9b-48eb-972f-d5ea43a78faf',
      isDefault: false,
      groupName: 'Billetter',
      count: 0
    },
    {
      id: '83d3e907-afd2-4e26-9d73-c812efa9fc83',
      isDefault: false,
      groupName: 'Billetter',
      count: 0
    },
    {
      id: '89c95211-ea6a-49d8-a46d-2513b82e8e39',
      isDefault: false,
      groupName: 'Billetter',
      count: 0
    },
    {
      id: 'b06ed0cd-5b19-4d43-91d1-e7b318d1cb5e',
      isDefault: false,
      groupName: 'Billetter',
      count: 0
    },
    {
      id: '2beab689-2023-421a-8112-6efbca15e961',
      isDefault: false,
      groupName: 'Billetter',
      count: 0
    }
  ],
  texts: [],

  departureTrip: null,
  departureTrips: [],
  departureTripsError: false,
  departureTripsLoading: false,

  returnTrip: null,
  returnTrips: [],
  returnTripsError: false,
  returnTripsLoading: false,

  existingBooking: null, // If the user is changing an existing booking,
  shoppingCart: null, // This is the shopping cart that is used to create a booking
  shoppingCartLoading: false,
  serverTime: null
}

const getters = {

  origin: state => state.origin,
  destination: state => state.destination,

  dateOnServer: state => {
    return `${state.dateTimeOnServer.getFullYear()}-${('0' + (state.dateTimeOnServer.getMonth() + 1)).slice(-2)}-${('0' + state.dateTimeOnServer.getDate()).slice(-2)}`
  },

  existingBooking: state => {
    return state.existingBooking
  },

  query: state => state.query,

  lang: state => state.lang,

  apiUrl: state => state.apiUrl,

  text: state => (key) => {
    if (state.query != null && state.query.showkeys !== undefined) { // Intended for the admins to help locate the different keys used in the system
      return '[KEY: "' + key + '"]'
    }

    let keyLower = _toLower(key) // cache this result
    let keys = keyLower.split('.')
    let text = state.texts
    try {
      keys.forEach(key => {
        text = text[key]
      })
      
      let item = text ? text[state.lang] : undefined

      if (item) {
        let firstIsUpper = key.length > 0 ? key.charAt(0) === key.charAt(0).toUpperCase() : false
        return firstIsUpper ? _upperFirst(item) : item
      }
    } catch (e) { 
      console.log(e)
    }
    return key
  },

  hours: state => () => {
    let hours_ = []
    for (let i = 0; i < 24; i++) {
      let hour = ('0' + i).slice(-2)
      let defaultMinutes = '00'
      hours_.push({ 'key': hour + ':' + defaultMinutes, 'value': hour + ':' + defaultMinutes, 'default': (hour === '00') })
    }
    return hours_
  },

  originId: state => _get(state.query, 'originId'),
  originName: state => _get(state.query, 'originName'),
  destinationId: state => _get(state.query, 'destinationId'),
  destinationName: state => _get(state.query, 'destinationName'),
  step: state => parseInt(_get(state.query, 'step', 0), 10),

  // returns object with fares, each decorated with count property and value fetched from query
  fares: (state, getters) => {
    let fares = state.fares.map(fare => {
      let key = 'qty_' + fare.id
      fare.count = state.query && state.query.hasOwnProperty(key) ? parseInt(state.query[key], 10) : 0
      fare.name = getters.text(`Fare-${fare.id}-name`)
      fare.helpText = getters.text(`Fare-${fare.id}-text`)
      return fare
    })

    // if all fares has count = 0, set the fare with isDefault = true to count = 1
    let temp = fares.filter(fare => fare.count > 0)
    if (temp.length === 0) {
      let fare = find(state.fares, { isDefault: true })
      if (fare) {
        fare.count = 1
      }
    }
    return fares
  },

  capacityError: state =>  {
    const error = _get(state.query, 'capacity_error', false)
    return error === 'true' || error === true ? true : false
  },

  // returns fares with one or more travellers (count) has been chosen
  faresWithCount: (state, getters) => _filter(getters.fares, fare => fare.count > 0),

  // returns the total number of travellers
  totalCount: (state, getters) => _sum(_map(getters.fares, fare => fare.count)),

  showEarlierDepartureTrips: state => _get(state.query, 'showEarlierDepartureTrips', '0') === '1',

  showLaterDepartureTrips: state => _get(state.query, 'showLaterDepartureTrips', '0') === '1',

  showEarlierReturnTrips: state => _get(state.query, 'showEarlierReturnTrips', '0') === '1',

  showLaterReturnTrips: state => _get(state.query, 'showLaterReturnTrips', '0') === '1',

  ret: state => (
    state.query.returnDate !== null && 
    state.query.returnDate !== undefined && 
    state.query.returnDate != ''
  ),

  edit: state => state.edit,

  discountCode: (state, getters) => {
    return _get(state.query, 'discountCode')
  },

  // returns departureDate from query, otherwise default to today
  departureDate: (state, getters) => {
    return _get(state.query, 'departureDate', getters.dateOnServer)
  },

  // returns returnDate from query, otherwise default to today
  returnDate: (state, getters) => {
    return _get(state.query, 'returnDate', null)
  },

  // returns the departure time as string

  // returns the return time as string

  departureDateTime: (state, getters) => getters.departureDate + ' ' + getters.departureTime,

  departureTripId: state => String(_get(state.query, 'departureTripId')),

  returnTripId: state => String(_get(state.query, 'returnTripId')),

  reference: state => _get(state.query, 'reference', null),

  returnTrip: state => state.returnTrip,

  departureTrip: state => state.departureTrip,

  shoppingCart: state => state.shoppingCart,

  shoppingCartLoading: state => state.shoppingCartLoading,

  err: state => state.query ? state.query.err : null, // payment error,

  serverTime: state => state.serverTime,

  showPriceRules: state => (state.query?.show_price_rules || state.query?.showPriceRules || state.query?.showpricerules) ? true : false,
}

const mutations = {

  // general
  setInitialized (state) {
    state.initialized = true
  },

  setShoppingCart (state, shoppingCart) {
    state.shoppingCart = shoppingCart
  },

  setShoppingCartLoading(state, value) {
    state.shoppingCartLoading = value
  },

  setTexts (state, texts) {
    state.texts = texts
  },

  setQuery (state, query) {
    state.query = query
    state.destination = query.destinationId ? {
      id: _get(query, 'destinationId'),
      name: _get(query, 'destinationName')
    } : null,
    state.origin = query.originId ? {
      id: _get(query, 'originId'),
      name: _get(query, 'originName')
    } : null
    if (query && query.hasOwnProperty('edit_id') && query.hasOwnProperty('edit_booking_number')) {
      state.edit = {
        id: _get(query, 'edit_id'),
        bookingNumber: _get(query, 'edit_booking_number')
      }
    }
  },

  setExistingBooking (state, booking) {
    state.existingBooking = booking
  },

  setShoppingCartQuery (state, id) {
    router.push({ query: { shoppingCart: id } }).catch(() => {})
  },

  setOriginAndDestination (state, location) {
    router.push({ query: { ...router.currentRoute.query, ...location } }).catch(() => {})
  },
  // origin
  setOrigin (state, location) {
    if (location != null) {
      state.origin = location
      router.push({ query: { ...router.currentRoute.query, originId: location.id, originName: location.name } }).catch(() => {})
    } else {
      state.origin = undefined
      router.replace({ query: { ...router.currentRoute.query, originId: undefined, originName: undefined } }).catch(() => {})
    }
  },

  // destination
  setDestination (state, location) {
    if (location != null) {
      state.destination = location
      router.push({ query: { ...router.currentRoute.query, destinationId: location.id, destinationName: location.name } }).catch(() => {})
    } else {
      state.destination = location
      router.push({ query: { ...router.currentRoute.query, destinationId: undefined, destinationName: undefined } }).catch(() => {})
    }
  },

  // settings

  setDiscountCode (state, code) { // Adds placeholder to make the router do the update
    let query = router.currentRoute.query
    if (code === '') {
      delete query.discountCode
      router.push({ query: { ...query, discountPlaceholder: true } }).catch(() => {})
    } else {
      delete query.discountPlaceholder
      router.push({ query: { ...query, discountCode: code } }).catch(() => {})
    }
  },

  setDepartureDate (state, date) {
    router.push({ query: { ...router.currentRoute.query, departureDate: date } }).catch(() => {})
  },

  setReturnDate (state, date) {
      router.push({ query: { ...router.currentRoute.query, returnDate: date } }).catch(() => {})
  },

  increaseFare (state, fare) {
    let query = { ...router.currentRoute.query }
    let key = 'qty_' + fare.id
    query[key] = query.hasOwnProperty(key) ? parseInt(query[key], 10) + 1 : fare.isDefault ? 2 : 1
    router.push({ query }).catch(() => {})
  },

  decreaseFare (state, fare) {
    let query = { ...router.currentRoute.query }
    let key = 'qty_' + fare.id
    let qty = query.hasOwnProperty(key) ? parseInt(query[key], 10) : 1
    query[key] = qty > 0 ? qty -= 1 : 0
    router.push({ query }).catch(() => {})
  },

  // departures
  setDepartureTrips (state, trips) {
    state.departureTrips = trips
  },

  setDepartureTripsLoading (state, isLoading) {
    state.departureTripsLoading = isLoading
  },

  setDepartureTrip (state, trip) {
    state.departureTrip = trip
  },


  setDepartureTripId (state, id) {
    let query = router.currentRoute.query
    router.push({ query: { ...query, departureTripId: id } }).catch((e) => {})
  },

  setReturnTrips (state, trips) {
    state.returnTrips = trips
  },

  setReturnTripsLoading (state, isLoading) {
    state.returnTripsLoading = isLoading
  },

  setReturnTrip (state, trip) {
    state.returnTrip = trip
  },

  setReturnTripId (state, id) {
    router.push({ query: { ...router.currentRoute.query, returnTripId: id } }).catch(() => {})
  },

  setLanguage (state, lang) {
    if (lang.toLowerCase() === 'en-us') {
      state.lang = 'en'
    } else {
      state.lang = lang
    }
  },

  setApiUrl (state, url) {
    state.apiUrl = url
  },

  setServerTime (state, time) {
    state.serverTime = time
  },

  resetPaymentError () {
    router.push({ query: { ...router.currentRoute.query, err: null } }).catch(() => {})
  },

  resetCapacityError () {
    router.replace({ query: { ...router.currentRoute.query, capacity_error: null } }).catch(() => {})
  }
}

const actions = {

  init ({ commit, state, getters }, shoppingCart = null) {
    commit('setTexts', texts)
    commit('setLanguage', window.__NWLANG || 'nb-NO')
    commit('setApiUrl', process.env.VUE_APP_API_ENDPOINT)
    if (shoppingCart) {
      commit('setShoppingCart', shoppingCart)
    }
    commit('setInitialized')
  },

  flipTravel ({ commit, state, getters }, next = true) {
    commit('setReturnTrips', [])
    commit('setDepartureTrips', [])

    let originId = getters.originId
    let originName = getters.originName
    let destinationId = getters.destinationId
    let destinationName = getters.destinationName

    commit('setOriginAndDestination', { originId: destinationId, originName: destinationName, destinationId: originId, destinationName: originName })
  },

  async fetchDepartureTrips ({ commit, state, getters }) {
    if (getters.originId && getters.destinationId) {
      commit('setDepartureTripsLoading', true)
      state.serverTime = await api.GetLocalTime()

      
      let result = await api.Trips(
        getters.originId,
        getters.destinationId,
        getters.departureDate,
        getters.faresWithCount,
        getters.discountCode,
        getters.ret,
        getters.lang,
        memberId()
      )
      let trips = result.trips

      // Eagle does not have unique trip ids, so we generate one
      forEach(trips, trip => {
        trip.id = generateTripId(trip)
      })

      // Not sure if we need this try catch, but it was in the old code
      try {
        commit('setDepartureTrips', trips)
      }
      catch {
        commit('setDepartureTrips', [])
      }
      commit('setDepartureTripsLoading', false)

      if (getters.departureTripId){
        commit('setDepartureTrip', find(state.departureTrips, r => r.id === getters.departureTripId))
      } 
    }
  },

  async fetchReturnTrips ({ commit, state, getters }) {
    if (getters.originId && getters.destinationId) {
      commit('setReturnTripsLoading', true)

      state.serverTime = await api.GetLocalTime()

      let result = await api.Trips(
        getters.destinationId,
        getters.originId,
        getters.returnDate,
        getters.faresWithCount,
        getters.discountCode,
        getters.ret,
        getters.lang,
        memberId()
      )
      let trips = result.trips
      if (result.error) {
        state.returnTripsError = true
      } else {
        state.returnTripsError = false
      }

      forEach(trips, trip => {
        trip.id = generateTripId(trip)
      })

      try {
        commit('setReturnTrips', trips)
      }
      catch {
        commit('setReturnTrips', [])
      }

      commit('setReturnTripsLoading', false)
      if (getters.returnTripId){
        commit('setReturnTrip', find(state.returnTrips, r => r.id === getters.returnTripId))
      }
      
    }
  },

  query ({ commit, getters, dispatch }, query) {
    commit('setQuery', query)
  }
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})

export default store
