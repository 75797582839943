import store from '@/store'
import find from 'lodash/find'
import first from 'lodash/first'
import filter from 'lodash/filter'
import last from 'lodash/last'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import api from '@/api/index'
import { createShoppingCartObject } from './CheckoutUtility'


async function createShoppingCart(departureTrip, returnTrip, transactions, memberId, discountCode) {
  const createShoppingCartInput = createShoppingCartObject(departureTrip, returnTrip, store.getters.lang === 'nb' ? 'nb-NO' : 'en-US', memberId, discountCode, transactions)
  return await api.CreateShoppingCart(createShoppingCartInput)
}

export async function buildShoppingCart (memberId) {
  const ret = store.getters.ret
  const edit = store.getters.edit
  const departureTrip = store.getters.departureTrip
  const returnTrip = store.getters.returnTrip
  const discountCode = store.getters.discountCode
  const existingBooking = store.getters.existingBooking

  store.commit('setShoppingCartLoading', true)
  if (!ret && !edit && departureTrip) { // No shoppingCart is created, no return, and no edit
    const shoppingCart = await createShoppingCart(departureTrip, null, null, memberId, discountCode)
    store.commit('setShoppingCart', shoppingCart)
    return shoppingCart
  } else if (ret && !edit && departureTrip && returnTrip) { // No shoppingCart is created, with return and no edit
    const shoppingCart = await createShoppingCart(departureTrip, returnTrip, null, memberId, discountCode)
    store.commit('setShoppingCart', shoppingCart)
    return shoppingCart
  } else if (edit && !ret && departureTrip && existingBooking) {
    const shoppingCart = await createShoppingCart(departureTrip, null, existingBooking.transactions, memberId, discountCode)
    store.commit('setShoppingCart', shoppingCart)
    return shoppingCart
  } else if (edit && ret && departureTrip && returnTrip && existingBooking) {
    const shoppingCart = await createShoppingCart(departureTrip, returnTrip, existingBooking.transactions, memberId, discountCode)
    store.commit('setShoppingCart', shoppingCart)
    return shoppingCart
  }
  console.log('No shoppingCart created')
  store.commit('setShoppingCartLoading', false)
  return null
}

export async function updateShoppingCart (product, memberId) {
  let shoppingCart = store.getters.shoppingCart
  if (find(shoppingCart.product_lines, r => r.product_id === product.product_id) && product.quantity > 0) {
    let item = {
      shopping_cart: shoppingCart,
      product_id: product.product_id,
      quantity: product.quantity
    }
    const updatedShoppingCart = await api.UpdateCart(item)
    store.commit('setShoppingCart', updatedShoppingCart)
  } else if (product.quantity === 0) {
    let item = {
      shopping_cart: shoppingCart,
      product_id: product.product_id
    }
    const updatedShoppingCart = await api.RemoveFromCart(item)
    store.commit('setShoppingCart', updatedShoppingCart)
  } else {
    let item = {
      shopping_cart: shoppingCart,
      product: product,
      quantity: product.quantity
    }
    const updatedShoppingCart = await api.AddToCart(item)
    store.commit('setShoppingCart', updatedShoppingCart)
  }

  shoppingCart = store.getters.shoppingCart

  forEach(shoppingCart.legs, (leg, index) => {
    leg.product_lines = shoppingCart.product_lines
    leg.tax_lines = shoppingCart.tax_lines
    leg.steps = shoppingCart.legs[index].steps
    leg.total = shoppingCart.legs[index].total
  })

  calculateShoppingCart(memberId)
}

export async function calculateShoppingCart (memberId) {
  const shoppingCart = store.getters.shoppingCart
  if (memberId) {
    shoppingCart.customer_id = memberId
  }
  const updatedShoppingCart =  await api.CalculateShoppingCart(shoppingCart)
  store.commit('setShoppingCart', updatedShoppingCart)
}

function _getOriginTransit () {
  const shoppingCart = store.getters.shoppingCart
  const leg = first(shoppingCart.legs)
  if (leg) {
    const step = first(leg.steps)
    if (step) {
      return step.transit
    }
  }
  return null
}

function _getDestinationTransit () {
  const shoppingCart = store.getters.shoppingCart
  const leg = first(shoppingCart.legs)
  if (leg) {
    const step = last(leg.steps)
    if (step) {
      return step.transit
    }
  }
  return null
}

function _getReturnTransit () {
  const shoppingCart = store.getters.shoppingCart

  // For a return date to be present we must have two legs in the shoppingcart
  if (shoppingCart.legs && shoppingCart.legs.length > 1) {
    const leg = last(shoppingCart.legs)
    if (leg) {
      const step = first(leg.steps)
      if (step) {
        return step.transit
      }
    }
  }
  return null
}

export function shoppingCartGetDepartureTripId () {
  const shoppingCart = store.getters.shoppingCart
  const leg = first(shoppingCart.legs)
  if (leg) {
    return leg.id
  }
  return null
}

export function shoppingCartGetFares () {
  const shoppingCart = store.getters.shoppingCart
  const filteredFares = filter(shoppingCart.product_lines, r => r.additional === false) // Only faretypes
  const fares = map(filteredFares, fare => {
    return {
      id: fare.product_id,
      quantity: fare.quantity
    }
  })
  return fares
}

export function shoppingCartGetOriginId () {
  const transit = _getOriginTransit()
  if (transit) {
    if (transit.departure_stop) {
      return transit.departure_stop.id
    }
  }
  return null
}

export function shoppingCartGetDestinationId () {
  const transit = _getDestinationTransit()
  if (transit) {
    if (transit.arrival_stop) {
      return transit.arrival_stop.id
    }
  }
  return null 
}

export function shoppingCartGetOriginName () {
  const transit = _getOriginTransit()
  
  if (transit) {
    if (transit.departure_stop) {
      return transit.departure_stop.name
    }
  }
  return null
}

export function shoppingCartGetDestinationName () {
  const transit = _getDestinationTransit()
  if (transit) {
    if (transit.arrival_stop) {
      return transit.arrival_stop.name
    }
  }
  return null 
}

export function shoppingCartGetDepartureDate () {
  const transit = _getOriginTransit()
  if (transit && transit.depart_at) {
    return transit.depart_at.split('T')[0]
  }
  return null
}

export function shoppingCartGetReturnDate () {
  const transit = _getReturnTransit()
  if (transit && transit.depart_at) {
    return transit.depart_at.split('T')[0]
  }
  return null
}


export default 
{
  calculateShoppingCart,
  buildShoppingCart, 
  updateShoppingCart, 
  shoppingCartGetDepartureDate, 
  shoppingCartGetReturnDate,
  shoppingCartGetOriginId,
  shoppingCartGetOriginName,
  shoppingCartGetDestinationId,
  shoppingCartGetDestinationName,
  shoppingCartGetDepartureTripId,
  shoppingCartGetFares
}