<template>
  <div class="flex p-6 py-3 border-b border-grey-400 bg-white flex-grow">
    <div 
      class="flex-row w-7/12 md:w-9/12"
    >
      <span 
        class="flex text-nw-base" 
        :class="product.quantity > 0 ? 'font-bold' : ''"
      >
        {{ product.title }}
      </span>
      <span class="flex text-nw-xs text-black">{{ product.description }}</span>
    </div>
    <div class="flex w-2/12 md:w-1/12">
      <span class="text-right my-auto w-full">{{ product.total }} ,-</span>
    </div>
    <div class="flex text-right pl-2 justify-between w-3/12 md:w-2/12">
      <button
        class="my-auto"
        :class="product.quantity === 0 ? 'opacity-50' : ''"
        :disabled="product.quantity === 0"
        @click.prevent="remove(product)"
      >
        <icon-remove class="cursor-pointer w-6" />
      </button>
      <span class="text-blue-500 my-auto w-6 text-center">{{
        product.quantity
      }}</span>
      <button
        class="my-auto"
        :class="product.quantity === max ? 'opacity-50' : ''"
        :disabled="product.quantity === max"
        @click.prevent="add(product)"
      >
        <icon-add class="cursor-pointer w-6" />
      </button>
    </div>
  </div>
</template>

<script>
import IconAdd from './icons/IconAdd.vue'
import IconRemove from './icons/IconRemove.vue'
import { mapGetters } from 'vuex'
import find from 'lodash/find'
export default {
  name: 'ProductPicker',
  components: {
    IconAdd,
    IconRemove
  },
  props: {
    baseProduct: {
      type: Object,
      default: () => {}
    },
    shoppingCart: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      product: this.baseProduct
    }
  },
  computed: {
    ...mapGetters(['text']),
    max () {
      return this.product.available_amount
    }
  },
  mounted () {
    // If we get a shopping cart, make sure we set our quantity to the amount in the cart
    const productInCart = find(this.shoppingCart.product_lines, { product_id: this.product.product_id })
    if (productInCart) {
      this.product.quantity = productInCart.quantity
    }
  },
 
  methods: {
    add (product) {
      product.quantity++
      this.$emit('add', product)
    },
    remove (product) {
      product.quantity--
      this.$emit('remove', product)
    }
  }
}
</script>
