<template>
  <label
    class="flex justify-start"
    @click.prevent="toggle"
  >
    <div 
      class="bg-white border-2 border-blue-500 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500" 
      :class="val ? 'bg-blue-500' : ''"
    >
      <input
        v-model="val"
        type="checkbox"
        class="opacity-0 absolute"
      >
      <svg
        class="fill-current w-4 h-4 text-white pointer-events-none"
        viewBox="0 0 20 20"
      >
        <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
      </svg>
    </div>
    <div class="select-none leading-6">
      {{ label }}
    </div>
  </label>
</template>

<script>
export default {
  name: 'NCheckbox',
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      val: this.value
    }
  },
  methods: {
    toggle () {
      this.val = !this.val
      this.$emit('input', this.val)
    }
  }
}
</script>

