<template>
  <div>
    <h1 class="text-blue-500 text-nw-3xl">
      {{ text("Forgotten password") }}
    </h1>

    <success-message 
      v-if="success" 
      class="mt-5" 
      :title="text('New password confirmation header')" 
      :text="text('New password confirmation text')" 
    />

    <error-message 
      v-if="error" 
      class="mt-5"
      :text="error"
    />

    <form @submit.prevent="submit">
      <n-input 
        v-if="!success"
        id="email"
        v-model="email" 
        :required="false"
        type="email"
        :label="text('Email')"
        class="mt-3" 
      />

      <div class="flex flex-wrap w-full mt-5 justify-between">
        <n-button 
          v-if="!success"
          :working="working" 
          @submit="submit"
        >
          {{ working ? text('Common.sending') : text('Common.send') }}
        </n-button>
        <div class="flex ml-2">
          <span
            v-if="!success"
            class="mr-2"
          >
            {{ text("or") }}
          </span>
          <router-link 
            class="mr-2 text-blue-500 underline"
            to="/innlogging"
          >
            {{ text("Back to login") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../api'
import { mapGetters } from 'vuex'
import NInput from '../components/forms/NInput.vue'
import NButton from '../components/forms/NButton.vue'
import SuccessMessage from '../components/SuccessMessage.vue'
import ErrorMessage from '../components/ErrorMessage.vue'

export default {
  name: 'ForgottenPassword',
  components: {
    NInput,
    NButton,
    SuccessMessage,
    ErrorMessage
  },
  data () {
    return {
      email: null,
      error: null,
      success: false,
      working: false
    }
  },
  computed: {
    ...mapGetters([
      'text',
    ])
  },
  mounted () {
    this.$nextTick(function () {
      window.scrollTo(0, 0)
    })
  },
  methods: {
    async submit () {
      this.working = true
      this.error = ''
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(this.email)) {
        this.error = this.text('Invalid email')
      } else {
        await api.ResetPassword(this.email)
        this.success = true
      }
      this.working = false
    }
  }
}
</script>
