<template>
  <div>
    <a
      class="underline mx-5 cursor-pointer inline-block"
      @click.prevent="goBack"
    >
      &#8592; {{ text('Common.back') }}
    </a>
    <h2 class="text-nw-xl mx-5 mt-5 text-blue-norway">
      {{ text('Authenticate.title') }}
    </h2>

    <div class="mx-5 mt-5">
      <span>{{ text('Authenticate.subheading') }}</span>
      <ul class="list-disc px-5">
        <li>
          {{ text('authenticate.benefit1') }}
        </li>
        <li>
          {{ text('authenticate.benefit2') }}
        </li>
      </ul>
    </div>

    <form 
      class="mx-5 mt-5"
      @submit.prevent="submit"
    >
      <n-input
        id="email"
        v-model="email" 
        class="mt-3 max-w-sm" 
        :required="true" 
        type="email" 
        :placeholder="text('Common.email')"
        autocomplete="email"
      />

      <n-input 
        id="password" 
        v-model="password" 
        :required="true" 
        type="password" 
        :placeholder="text('Common.password')"
        class="mt-3 max-w-sm" 
        autocomplete="current-password"
      />

      <div class="mt-5">
        <a
          class="text-blue-500 cursor-pointer underline" 
          @click="goToResetPassword"
        >
          {{ text('Authenticate.forgottenpassword') }}
        </a>
      </div>

      <div 
        v-if="error" 
        class="mt-5"
      >
        <span class="text-red-error">
          {{ text('Authenticate.error') }}
        </span>
      </div>

      <div class="mt-5 flex content-center flex-wrap">
        <n-button 
          :working="working"
          @submit="submit"
        >
          {{ working ? text("Login in progress") : text("Login") }}
        </n-button>
        <n-button
          type="secondary"
          class="ml-5"
          @click.prevent="goToCreateAccount" 
        >
          {{ text('Authenticate.createprofile') }}
        </n-button>
      </div>
    </form>
    <div class="mx-5 mt-10">
      <h2 class="text-nw-lg text-blue-norway">
        {{ text('Authenticate.continuewithoutlogin') }}
      </h2>
      <p class="mt-5">
        {{ text('Authenticate.warning') }}
      </p>
      <n-button
        class="mt-5"
        :inverted="true"
        @click.prevent="goToPayment"
      >
        {{ text('Authenticate.continuewithoutloginbutton') }}
      </n-button>
    </div>
  </div>
</template>
<script>
import NButton from '@/components/forms/NButton.vue'
import NInput from '@/components/forms/NInput.vue'
import { mapGetters } from 'vuex'
import { setAuthenticated } from '@/utilities/UserUtility'
import { calculateShoppingCart } from '@/utilities/ShoppingCartUtility'
import api from '@/api/index'
import { get } from 'lodash'
export default {
  name: 'BookingAuthenticate',
  components: {
    NButton,
    NInput
  },
  props: {
    shoppingCart: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      email: '',
      password: '',
      error: false,
      cookiesDisabled: false,
      working: false
    }
  },
  computed: {
    ...mapGetters(['text', 'query'])
  },
  mounted () {
    this.$scrollTo('#app')
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'produkter', query: { ...this.query, back: true } })
    },
    goToResetPassword() {
      this.$router.push({
        name: 'glemt-passord',
        query: {
          shoppingCart: this.shoppingCart
        }
      })
    },
    goToPayment () {
      this.$router.push({
        name: 'betale',
        query: {
          shoppingCart: this.shoppingCart
        }
      })
    },
    goToCreateAccount () {
      this.$router.push({
        name: 'registrere',
        query: {
          shoppingCart: this.shoppingCart
        }
      })
      return false
    },
    async submit () {
      this.working = !this.working
      try {
        const authResult = await api.ProfileAuth(this.email, this.password)
        setAuthenticated (
          get(authResult, 'auth.token'),
          get(authResult, 'auth.data.userId'), 
          get(authResult, 'auth.data.email'),
          get(authResult, 'auth.data.phone')
        )

        // Recalculate the shopping cart
        await calculateShoppingCart(get(authResult, 'auth.data.userId'))

        this.$router.push({
          name: 'betale',
          query: {
            shoppingCart: this.shoppingCart
          }
        })
      } catch(e) {
        console.log(e)
        this.working = !this.working
        this.error = true
      }
    }
  }
}
</script>
