<template>
  <div 
    id="app"
    class="font-Ginto"
  >
    <main v-if="initialized">
      <router-view />
    </main>
    <div v-else>
      <div class="loading" />
    </div>
    <div 
      v-if="showDebug" 
      class="bg-grey-200 text-nw-xxs rounded-lg px-4 mt-20 mx-5 py-2 my-2 border-2 border-red-500"
    >
      <div class="flex flex-row justify-between">
        <h2 class="text-nw-base-plus">
          Deployment config: <strong>{{ deployEnv }}</strong>
        </h2>
        <a 
          href="#"
          title="toggle"
          @click.prevent="debugOpen = !debugOpen"
        >
          <IconArrow :class="debugOpen ? 'rotate-180' : 'h-full'" />
        </a>
      </div>
      <div
        v-if="debugOpen"
        class="mt-2"
      >
        <ul>
          <li>Bruker api: <strong>{{ apiEnpoint }}</strong></li>
          <li>Kjører eagle i testmodus: <strong>{{ eagleMode }}</strong></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import { isEqual } from 'lodash'
import IconArrow from './components/icons/IconArrow.vue'
import api from './api/index'
import find from 'lodash/find'

export default {
  name: 'App',
  components: {
    IconArrow
  },
  data () {
    return {
      deployEnv: process.env.VUE_APP_DEPLOY_ENV,
      apiEnpoint: process.env.VUE_APP_API_ENDPOINT,
      eagleMode: process.env.VUE_APP_MODE_TEST,
      debugOpen: false
    }
  },
  computed: {
    ...mapState(['initialized', 'query', 'existingBooking']),
    ...mapGetters(['text','edit', 'shoppingCart', 'ret']),
    showDebug () {
      return this.deployEnv && (this.deployEnv === 'local' || this.deployEnv === 'next' || this.deployEnv === 'staging' || this.deployEnv === 'testing')
    }
  },
  watch : {
    edit: {
      async handler (val) {
        if (val) {
          // Check if we should load existing booking
          if (this.edit) {
            try {
              this.editOrder = true // This user is attempting to edit a booking
              const user = await api.UserGet()
              const customerBookings = await api.GetOrdersByCustomer(user.id)
              if (find(customerBookings, booking => { return booking.booking_number === this.edit.bookingNumber })) {
                const existing = await api.GetOrderByBookingAndId(this.edit.bookingNumber, this.edit.id)
                this.$store.commit('setExistingBooking', existing)
              }
            } catch (e) {
              this.$store.commit('setExistingBooking', null)
              this.$router.push({ name: 'login', query: { message: this.text('You must be logged in to edit orders') } }) // Not logged in, redirect
            }
          }
        }
      },
      deep: true
    },
    query: {
      async handler (val, oldVal) { // Will initiate data loading as soon as query changes
        if (isEqual(val, oldVal)) {
          return
        }
        // Todo. We need to relate to the query object in a better way
        //  Because we end up doing multiple equal calls to the api
        if (val && val.originId && val.destinationId) {
          this.$store.dispatch('fetchDepartureTrips')
          if (this.ret) {
            this.$store.dispatch('fetchReturnTrips', this.ret)
          }
        }
        if (val && this.query.shoppingCart && !this.shoppingCart) {
          const shoppingCart = await api.LoadShoppingCart(this.query.shoppingCart)
          this.$store.commit('setShoppingCart', shoppingCart)
        }
      },
      immediate: true
    },
    '$route': {
      handler: function (val, old) {
        if (val) {
          this.$store.dispatch('query', val.query)
        }
      },
      immediate: true
    }
  },

  async created () {
    this.$store.dispatch('init')
    
    if (window.dataLayer === undefined) {
      window.dataLayer = []
    }
  }
}
</script>

<style scoped>
  .text-base {
    font-size: 1rem;
  }
</style>
