<template>
  <div class="text-red-error">
    <h2 
      v-if="title"
      class="text-nw-base-plus leading-5" 
    >
      {{ title }}
    </h2>
    <p
      v-if="text"
      class="mb-0 text-nw-base"
      :class="title ? 'mt-2' : ''"
    >
      {{ text }}
    </p>
    <ul
      v-if="list.length > 0"
      class="text-nw-base"
    >
      <li 
        v-for="(t, index) in list"
        :key="index"
      >
        {{ t }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'ErrorMessage',
  props: {
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    },
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>
