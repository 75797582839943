<template>
  <div>
    <div class="px-5 py-2 bg-grey-norway">
      <div>
        <div class="flex w-full justify-between">
          <h3 class="text-nw-base-plus leading-6 flex-grow-1">
            {{ toTravelDate(lang, returnDate) }}
          </h3>
          <router-link
            class="flex-grow-0 underline leading-6 text-nw-sm"
            :to="{ name: 'reise', query: { ...query }}"
          >
            {{ text('Change') }}
          </router-link>
        </div>
      </div>
      <div class="text-nw-sm mt-3">
        <span>{{ destinationName }} - {{ originName }}</span>
      </div>
      <span class="block text-nw-sm">{{ selectionText }}</span>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { filter } from 'lodash'
  import { DateMixin } from '../components/mixins/DateMixin'
  export default {
    name: 'ReturnInfo',
    mixins: [ DateMixin ],
    computed: {
      ...mapState(['query']),
      ...mapGetters([ 'lang', 'text', 'returnDate', 'originName', 'destinationName', 'fares' ]),
      selectionText: {
        get () {
          let text = filter(this.fares, o => o.count > 0)
            .map(o => o.count + ' ' + o.name.toLowerCase())
            .join(', ')
          return text === '' ? this.text('Select ticket type') : text
        }
      },
    },
      
    methods: {
      edit () {
        this.$router.push({name: 'reise', query: { ...this.query }})
      }
    }
    
  }
</script>