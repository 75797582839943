<template>
  <div class="border-2 rounded shadow-xl border-grey-500 mt-4 px-3">
    <div 
      v-for="(leg, index) in order.legs" 
      :key="index"
      class="w-full mt-3"
    >
      <h3 class="text-nw-lg">
        {{ toTravelDate(lang, departAt(leg)) }}
      </h3>
      <div class="mt-1 text-nw-base font-bold">
        {{ departureStop(leg) }} - {{ arrivalStop(leg) }}
      </div>
      <div class="text-nw-base">
        {{ toTime(lang, departAt(leg)) }} - {{ toTime(lang, arriveAt(leg)) }}
      </div>
    </div>

    <div class="flex flex-col mt-3">
      <div class="flex justify-end pt-2">
        <router-link
          class="text-nw-base text-blue-400 underline"
          :to="{
            name: 'receipt-new',
            params: {
              reference: order.booking_number,
              id: order.id,
            },
          }"
        >
          {{ text('Show tickets') }}
        </router-link>
        <a
          v-if="canEdit"
          class="text-nw-base cursor-pointer underline ml-3 text-blue-400"
          @click.prevent="edit = true"
        >
          {{ text('Edit ticket') }}
        </a>
      </div>
      <div
        v-if="isOpen"
        class="flex text-nw-base justify-end"
      >
        <a 
          id="find-my-bus"
          target="_blank"
          :href="`https://hvorerbussen.nor-way.no/booking/${order.booking_number}`"
          class="underline text-blue-500"
        >
          {{ text('common.findmybus') }}
        </a>
      </div>
    </div>
    <div 
      class="h-3 -mx-3 mt-2" 
      :class="statusClassesBottom" 
    />
    <modal
      v-if="edit"
      :title="text('Edit modal title')"
      :text="text('Edit modal text')"
      :action-text="text('Edit modal action')" 
      @close="edit = false"
      @proceed="proceedToEdit"
    />
  </div>
</template>

<script>
import { DateMixin } from '../../components/mixins/DateMixin'
import { mapGetters } from 'vuex'
import last from 'lodash/last'
import first from 'lodash/first'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import { orderStatus, orderCanEdit } from '../../utilities/OrderUtility.js'
import Modal from '../Modal'

export default {
  name: 'Order',
  components: {
    Modal
  },
  mixins: [ DateMixin ],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      edit: false
    }
  },
    computed: {
    ...mapGetters([
      'text',
      'lang'
    ]),
    hasReturnTrip () {
      return this.order.legs.length > 1
    },
    canEdit () {
      return orderCanEdit(this.order)
    },
    origin () {
      let leg = first(this.order.legs)
      let step = first(leg.steps)
      return step.transit
    },
    destination () {
      let leg = first(this.order.legs)
      let step = last(leg.steps)
      return step.transit
    },
    departureId () {
      return this.origin.departure_stop.id
    },
    departureName () {
      return this.origin.departure_stop.name
    },
    destinationId () {
      return this.destination.arrival_stop.id
    },
    destinationName () {
      return this.destination.arrival_stop.name
    },
    status () {
      return this.orderStatus(this.order)
    },
    isOpen () {
      return this.status.archived === false
    },
    statusText () {
      if (this.status.archived === true) {
        return this.text('Archived')
      } else {
        return this.text('Open')
      }
    },
    statusClasses () {
      if (this.status.archived === true) {
        return 'text-grey-500'
      } else {
        return 'text-green-400'
      }
    },
    statusClassesBottom () {
      if (this.status.archived === true) {
        return 'bg-grey-500'
      } else {
        return 'bg-green-400'
      }
    },
    departureDate () {
      try {
        return this.origin.depart_at
      } catch {
        return '-'
      }
    },
    returnDate () {
      try {
        let leg = last(this.order.legs)
        let step = first(leg.steps)
        return step.transit.depart_at
      } catch {
        return '-'
      }
    },
    departureRaw () {
      try {
        return this.order.trips[0].departure
      } catch {
        return '-'
      }
    },
    email () {
      try {
        return this.order.contact.email
      } catch {
        return '-'
      }
    },
    fares () {
      try {
        return filter(this.order.product_lines, (fare) => {
          return fare.additional === false
        })
      } catch {
        return []
      }
    },
    redirectBaseUrl () {
      return this.lang === 'en' ? window.__NWREDIRECT_EN : window.__NWREDIRECT_NO
    },
    query () {
      const query = {
        edit_booking_number: this.order.booking_number,
        edit_id: this.order.id,
        step: 2,
        originId: `${this.departureId}`,
        originName: this.departureName,
        destinationId: `${this.destinationId}`,
        destinationName: this.destinationName,
        departureDate: this.toDate(this.departureDate),
      }
      if (this.order.roundtrip) {
        query.returnDate = this.order.roundtrip ? this.toDate(this.returnDate) : '',
        query.ret = this.hasReturnTrip
      }

      forEach(this.fares, fare => {
        query['qty_' + fare.product_id] = fare.quantity
      })

      return query
    },
    editUrl () {
      return `${this.redirectBaseUrl}?${new URLSearchParams(this.query).toString()}`
    }
  },
  methods: {
    orderStatus,
    last,
    proceedToEdit () {
      window.location.href = this.editUrl
    },
    purchaseDate (input) {
      return input.substring(0, 10)
    },
    departAt (leg) {
      try {
        let step = first(leg.steps)
        return step.transit.local_depart_at
      } catch {
        return '-'
      }
    },
    arriveAt (leg) {
      try {
        let step = last(leg.steps)
        return step.transit.local_arrive_at
      } catch {
        return '-'
      }
    },
    departureStop (leg) {
      try {
        let step = first(leg.steps)
        return step.transit.departure_stop.name
      } catch {
        return '-'
      }
    },
    arrivalStop (leg) {
      try {
        let step = last(leg.steps)
        return step.transit.arrival_stop.name
      } catch {
        return '-'
      }
    }
  }
}
</script>
