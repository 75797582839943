<template>
  <form
    v-if="profile"
    :submit="update"
    class="p-5"
  >
    <h1 class="text-nw-3xl text-blue-500">
      {{ text('My profile') }}
    </h1>
    <hr class="mt-2 md:mt-4">

    <div class="flex flex-wrap">
      <n-input
        id="givenName"
        v-model="profile.given_name" 
        class="mt-4 w-full md:w-1/2 md:pr-2"
        :label="text('Firstname')" 
      />
      <n-input
        id="lastName"
        v-model="profile.family_name"
        class="mt-4 w-full md:w-1/2 md:pl-2"
        :label="text('Lastname')"
      />
    </div>

    <n-input
      id="email"
      v-model="profile.email" 
      class="mt-4"
      :readonly="true"
      :disabled="true" 
      :label="text('Email')"
    />
    <n-input
      id="mobile" 
      v-model="profile.phone_number" 
      class="mt-4" 
      type="tel" 
      :label="text('Mobile')" 
    />
    <n-input 
      id="street" 
      v-model="profile.address.address_line_1" 
      class="mt-4" 
      type="text" 
      :label="text('Street')"
    />
    <div class="flex flex-wrap">
      <n-input 
        id="zip"
        v-model="profile.address.postal_code"
        class="mt-4 w-full md:w-1/2 md:pr-2"
        type="text"
        :label="text('Zip')" 
      />
      <n-input
        id="city"
        v-model="profile.address.region" 
        class="mt-4 w-full md:w-1/2 md:pl-2"
        type="text"
        :label="text('City')"
      />
    </div>
    <n-input
      id="country" 
      class="mt-4" 
      type="text" 
      :label="text('Country')"
    />
    <n-checkbox
      id="newsletter"
      v-model="profile.newsletter"
      class="mt-4"
      :label="text('Subscribe to newsletter')"
    />
    <success-message 
      v-if="status == 0" 
      class="mt-5" 
      :text="text('Profile updated')" 
    />
    <error-message 
      v-else-if="status > 0"
      class="mt-8"
      :text="text('Could not update profile')" 
    />
    <div class="flex mt-10 flex-wrap justify-between">
      <n-button
        :working="working"
        @click="update()"
      >
        {{ working ? text('Updating') : text('Update profile') }}
      </n-button>
      <router-link 
        class="text-nw-sm my-auto text-blue-500 underline"
        :to="{ name: 'account'}"
      >
        {{ text('Back') }}
      </router-link>
    </div>
  </form>
</template>

<script>

import { mapGetters } from 'vuex'
import api from '../api'
import NInput from './../components/forms/NInput'
import NCheckbox from './../components/forms/NCheckbox'
import NButton from '../components/forms/NButton'
import { localStorageGet } from '../utilities/LocalStorage'
import ErrorMessage from '../components/ErrorMessage.vue'
import SuccessMessage from '../components/SuccessMessage.vue'

export default {

  name: 'EditProfile',
  components: {
    NInput,
    NCheckbox,
    NButton,
    ErrorMessage,
    SuccessMessage
  },
  data () {
    return {
      profile: undefined,
      status: -1,
      working: false
    }
  },
  computed: {
    ...mapGetters([
      'text'
    ])
  },
  async created () {
    this.profile = await api.GetProfileById(localStorageGet('memberId'))
    this.profile.newsletter = this.profile.newsletterStatus === 'Subscribed'
    this.status = -1
  },
  methods: {
    async update () {
      this.working = true
      this.status = -1
      try {
        await api.UpdateProfile(this.profile)
        this.status = 0
      } catch {
        this.status = 1
      }
      this.working = false
    },
    back () {
      this.$router.push({ name: 'account' })
    }
  },
}
</script>
