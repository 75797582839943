<template>
  <div class="w-full">
    <div class="max-w-sm mt-8 mx-auto">
      <trip-message-list
        class="mb-3"
        :trip="trip"
        :with-details="true" 
      />
      <price-details 
        v-if="!soldOut && showPrice" 
        :trip="trip" 
      />

      <div
        v-for="(step, index) in trip.steps"
        :key="index"
      >
        <div 
          v-if="index > 0"
          class="flex flex-row space-x-3 justify-center"
        >
          <div>
            <icon-info class="w-6" />
          </div>
          <div class="text-blue-norway">
            Ventetid {{ calculateWaitTime(trip.steps[index-1], step) }}
          </div>
        </div>
        <trip-expanded-stop 
          :step="step" 
        />
      </div>

      <div class="mb-4 mt-4 text-center">
        <button 
          class="text-blue-500 underline" 
          @click.prevent="close()"
        >
          {{ text('Common.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import TripExpandedStop from './TripExpandedStop.vue'
import PriceDetails from './PriceDetails.vue'
import TripMessageList from './TripMessageList.vue'
import IconInfo from '@/components/icons/IconInfo.vue'
import { DateMixin } from './mixins/DateMixin'

export default {

  name: 'TripItemDetails',

  components: {
    TripExpandedStop,
    PriceDetails,
    TripMessageList,
    IconInfo
  },

  mixins: [DateMixin],

  props: {
    trip: {
      type: Object,
      required: true
    },
    soldOut: {
      type: Boolean,
      default: false,
      required: false
    },
    showPrice: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters([
      'text',
      'totalCount',
      'lang'
    ])
  },

  methods: {
    calculateWaitTime(firstStep, secondStep) {
      let arrival = firstStep.transit.local_arrive_at
      let departure = secondStep.transit.local_depart_at
      return this.toDuration(this.lang, arrival, departure)
    },
    handleTripSelected(trip) {
      this.$emit('tripSelected', trip)
    },
    close(parent) {
      this.$emit('closeTripDetails')
    }
  }
}

</script>
