import last from 'lodash/last'
import first from 'lodash/first'
import map from 'lodash/map'

export function tripDepartureDate (trip) {
  return trip.steps[0].transit.local_depart_at
}

export function tripArrivalDate (trip) {
  return last(trip.steps).transit.local_arrive_at
}

export function tripDepartureStop (trip) {
  return trip.steps[0].transit.departure_stop
}

export function tripArrivalStop (trip) {
  return last(trip.steps).transit.arrival_stop
}

export function legDepartureName(leg) {
  return tripDepartureStop(leg).name
}

export function legArrivalName(leg) {
  return tripArrivalStop(leg).name
}

export function legDepartureDate (leg) {
  const firstStep = first(leg.steps)
  if (firstStep && firstStep.transit) {
    return firstStep.transit.local_depart_at
  } else {
    return null
  }
}

export function legDepartureTime (leg) {
  return stepDepartureTime(first(leg.steps))
}

export function legArrivalTime (leg) {
  return stepArrivalTime(last(leg.steps))
}

export function legShortNames (leg) {
  return map(leg.steps, r => r.transit.line.short_name)
}

export function stepDepartureTime (step) {
  if (step && step.transit) {
    return step.transit.local_depart_at
  }
  return null
}

export function stepArrivalTime (step) {
  if (step && step.transit) {
    return step.transit.local_arrive_at
  }
  return null
}

export function stepDepartureName (step) {
  if (step && step.transit) {
    return step.transit.departure_stop.name
  }
  return null
}

export function stepArrivalName (step) {
  if (step && step.transit) {
    return step.transit.arrival_stop.name
  }
  return null
}

// This is needed to create reproducible ids for the trips. The trips all comes back from the 
// api with an id = 0
export function generateTripId (trip) {
  var hash = 0
  const id = trip.steps[0].transit.departure_stop.id + trip.steps[0].transit.arrival_stop.id + trip.steps[0].transit.depart_at + trip.steps[0].transit.arrive_at
  if (id.length === 0) return hash
  for (var i = 0; i < id.length; i++) {
    var chr = id.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash.toString()
}

export default { 
  generateTripId, 
  tripDepartureDate, 
  tripArrivalDate, 
  tripDepartureStop, 
  tripArrivalStop, 
  legDepartureName, 
  legArrivalName,
  legDepartureDate,
  legDepartureTime,
  legArrivalTime,
  legShortNames,
  stepDepartureTime,
  stepArrivalTime,
  stepDepartureName,
  stepArrivalName
 }
