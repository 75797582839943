// Used to represent the date and time as given by the server.
// No local client timezone should interfer with this
export class LocalDateTime {
  constructor (input) {
    this.original = input
    this.originalDate = input.split('T')[0]
    this.originalTime = input.split('T')[1] ? input.split('T')[1] : '00:00:00'

    let date = this.originalDate.split('-')
    let time = this.originalTime.split(':')

    this.year = date[0]
    this.month = date[1]
    this.day = date[2]
    this.hour = time[0]
    this.minute = time[1]

    this.locale = {
      nb: {
        weekdays: 'Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag'.split('_'),
        weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
        months: 'januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember'.split('_'),
        monthsShort: 'jan_feb_mar_apr_mai_jun_jul_aug_sep_okt_nov_des'.split('_')
      },
      en: {
        weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_')
      }
    }
  }

  nextDay () {
    let t = new Date(this.year, this.month - 1, this.day)
    t.setDate(t.getDate() + 1)
    return this._asStandardString(t)
  }

  previousDay () {
    let t = new Date(this.year, this.month - 1, this.day)
    t.setDate(t.getDate() - 1)
    return this._asStandardString(t)
  }

  toTime (lang) {
    /*
    if (lang.toLowerCase() === 'en' || lang.toLowerCase() === 'en-us') {
      let postfix = this.hour < 12 ? 'AM' : 'PM'
      let adjustedHour = this.hour % 12 || 12
      return `${adjustedHour}:${this.minute} ${postfix}`
    } */
    return `${this.hour}:${this.minute}`
  }

  asUtc () {
    return Date.UTC(this.year, this.month - 1, this.day, this.hour, this.minute)
  }

  format (lang, pattern) {
    let d = new Date(this.year, this.month - 1, this.day)
    if (pattern === 'ddd D. MMM') {
      return `${this.locale[lang].weekdaysShort[d.getDay()]} ${d.getDate()}. ${this.locale[lang].monthsShort[d.getMonth()]}`
    } else if (pattern === 'dddd DD. MMM YYYY') {
      return `${this.locale[lang].weekdays[d.getDay()]} ${d.getDate()}. ${this.locale[lang].monthsShort[d.getMonth()]} ${d.year}`
    } else if (pattern === 'dddd DD. MMMM') {
      return `${this.locale[lang].weekdays[d.getDay()]} ${d.getDate()}. ${this.locale[lang].months[d.getMonth()]}`
    } else if (pattern === 'LLLL') {
      return `${this.locale[lang].weekdays[d.getDay()]}, ${d.getDate()}. ${this.locale[lang].months[d.getMonth()]} ${this.year} ${this.toTime(lang)}`
    } else {
      return `Pattern: ${pattern} not supported`
    }
  }

  _asStandardString (input) {
    return `${input.getFullYear()}-${('0' + (input.getMonth() + 1)).slice(-2)}-${('0' + input.getDate()).slice(-2)}`
  }
}
