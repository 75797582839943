<template>
  <div v-if="success">
    <success-message 
      class="text-center" 
      :text="text('Registration successful')"
    />
    <n-button
      v-if="isModal"
      class="mx-auto mt-5"
      @click="backToPurchase"
    >
      {{ text('Back to purchase') }}
    </n-button>
    <n-button
      v-else 
      class="mx-auto mt-5"
      @click="backToFrontpage"
    >
      {{ text('Back to frontpage') }}
    </n-button>
  </div>
  <form 
    v-else 
    id="registration-form" 
    @submit.prevent="submit"
  >
    <h1 
      v-if="showTitle"
      class="text-nw-3xl text-blue-500"
    >
      {{ text('Register.title') }}
    </h1>

    <n-input
      id="email"
      v-model="email" 
      :required="true"
      type="email"
      :label="text('Email')" 
      class="mt-2" 
    />
    <n-input
      id="mobile" 
      v-model="mobile" 
      :required="true" 
      type="tel" 
      :label="text('Mobile')"
      class="mt-2" 
    />
    <n-input
      id="password"
      v-model="password"
      :required="true"
      type="password"
      :label="text('Password')"
      class="mt-2" 
    />
    <n-input
      id="confirm-password"
      v-model="passwordConfirm"
      :required="true" 
      type="password" 
      :label="text('Password confirm')" 
      class="mt-2" 
    />
    <div 
      v-if="false" 
      class="flex flex-wrap"
    >
      <n-input 
        id="firstname"
        v-model="firstName" 
        type="text" 
        :label="text('Firstname')" 
        :sublabel="text('optional')" 
        class="mt-3 w-full md:w-1/2 md:pr-2" 
      />
      <n-input 
        id="lastname" 
        v-model="lastName" 
        type="text" 
        :label="text('Lastname')" 
        :sublabel="text('optional')" 
        class="mt-3 w-full md:w-1/2 md:pl-2" 
      />
    </div>

    <div class="mt-2 text-nw-sm text-black">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="text('register terms')" />
    </div>

    <div class="mt-2 text-black display-block">
      <n-checkbox
        id="newsletter"
        v-model="newsletter"
        class="mt-5 text-nw-sm"
        :label="text('Subscribe to newsletter')"
      />
    </div>

    <error-message 
      v-if="errors.length > 0"
      id="register-user-error"
      class="mt-5" 
      :list="errors"
    />

    <div class="flex flex-wrap justify-center xs:justify-between text-nw-sm mt-6 items-baseline">
      <n-button
        class="registration-button"
        :working="submitting"
        @submit="submit"
      >
        <span v-if="!submitting">
          {{ text("Register me") }}
        </span>
        <span v-else>
          {{ text("Registration in progress") }}
        </span>
      </n-button>
      <div 
        v-if="showLink"
        class="flex ml-5 mt-5 xs:mt-0"
      >
        <span class="text-black">{{ text('or are you') }} </span>
        <router-link
          class="flex ml-2 text-blue-500 underline"
          to="/innlogging"
        >
          {{ text("existing customer") }}?
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
import api from '../api/index'
import { mapGetters } from 'vuex'
import NInput from './forms/NInput.vue'
import NCheckbox from './forms/NCheckbox.vue'
import NButton from './forms/NButton.vue'
import ErrorMessage from './ErrorMessage.vue'
import SuccessMessage from './SuccessMessage.vue'
import { setAuthenticated } from '@/utilities/UserUtility'
import get from 'lodash/get'

export default {
  name: 'RegisterUser',
  components: {
    NInput,
    NCheckbox,
    NButton,
    ErrorMessage,
    SuccessMessage
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    showLink: {
      type: Boolean,
      default: true
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      email: '',
      mobile: '',
      password: '',
      passwordConfirm: '',
      firstName: '',
      lastName: '',
      newsletter: false,
      errors: [],
      submitting: false,
      success: false
    }
  },
  computed: {
    ...mapGetters([
      'text',
      'authToken'
    ])
  },
  mounted () {
    if (!this.isModal) {
      this.$nextTick(function () {
        window.scrollTo(0, 0)
      })
    }
  },
  methods: {
    backToPurchase () {
      this.$emit('close')
    },
    backToFrontpage () {
      this.$router.push({ name: 'booking' })
    },

    // Attempt to register the user and then log in
    async submit () {
      this.submitting = true
      this.errors = []
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(this.email)) { 
        this.errors.push(this.text('Error invalid email')) 
      }
      if (this.password.length < 5) { 
        this.errors.push(this.text('Error password to short')) 
      }
      if (this.password !== this.passwordConfirm) { 
        this.errors.push(this.text('Error passwords do not match')) 
      }

      if (this.errors.length === 0) {
        try {
          await api.RegisterProfile(this.email, this.password, this.mobile, this.firstName, this.lastName, this.newsletter)
          const authResult = await api.ProfileAuth(this.email, this.password)
          setAuthenticated (
            get(authResult, 'auth.token'),
            get(authResult, 'auth.data.userId'), 
            get(authResult, 'auth.data.email'),
            get(authResult, 'auth.data.phone')
          )
          this.success = true
          this.$emit('registered')
        } catch {
          this.submitting = false
          this.errors.push(this.text('registration failed'))
        }
      } else {
        this.submitting = false
      }
      if (this.errors.length > 0) {
        this.$nextTick(() => {
          let el = this.$el.getElementsByClassName('registration-button')[0]
          if (el) {
            el.scrollIntoView()
          }
        })
      }
    }
  }
}
</script>
