<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 0L19.5933 17.25H1.40673L10.5 0Z"
      fill="#FFDF5A" 
    />
    <path
      d="M9.456 12.8H10.928V4.8H9.456V12.8ZM10.224 16.24C10.848 16.24 11.296 15.744 11.296 15.152C11.296 14.528 10.848 14.048 10.224 14.048C9.584 14.048 9.12 14.528 9.12 15.152C9.12 15.744 9.584 16.24 10.224 16.24Z"
      fill="white" 
    />
  </svg>
</template>

<script>
  export default {
    name: 'IconWarning',
  }
</script>