<template>
  <div class="mx-5">
    <a
      class="underline cursor-pointer inline-block"
      @click.prevent="goBack"
    >
      &#8592; {{ text('Common.back') }}
    </a>
    <h2 class="text-nw-xl mt-5 text-blue-norway">
      {{ text('Resetpassword.title') }}
    </h2>
    
    <div 
      v-if="done" 
      class="mt-15 border border-green-success p-5"
    >
      <p class="text-green-success font-medium">
        {{ text('Resetpassword.message') }}
        <span class="font-semibold text-black">{{ email }}</span>
      </p>
    </div>

    <form
      v-else
      class="mt-5"
      @submit.prevent="submit"
    >
      <div>
        <span>
          {{ text('Resetpassword.text') }}
        </span>
      </div>

      <n-input 
        id="email" 
        v-model="email" 
        :required="true" 
        type="email" 
        :label="text('Common.Email')" 
        class="mt-5" 
      />

      <n-button
        class="mt-5"
        :working="working"
      >
        {{ text('Resetpassword.button') }}
      </n-button>
    </form>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import NInput from '@/components/forms/NInput.vue'
  import NButton from '@/components/forms/NButton.vue'
  import api from '@/api/index'
  export default {
    name: 'BookingResetPassword',
    components: {
      NInput,
      NButton
    },
    props: {
      shoppingCart: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        email: '',
        working: false,
        done: false
      }
    },
    computed: {
      ...mapGetters(['text']),
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      async submit () {
        this.working = !this.working
        try {
          await api.ResetPasswordWithShoppingCart(this.email, this.shoppingCart)
          this.done = true
          this.working = false
        } catch {
          this.done = true
          this.working = false
        }
      }
    },
  }
</script>