<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 283.5">
    <g id="Layer_2-2" data-name="Layer 2">
      <circle class="cls-1" cx="140" cy="140" r="140" />
      <text class="cls-2" transform="translate(106.9 228.29)">
        <tspan x="0" y="0">i</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconInfo'
}
</script>

<style scoped>
.cls-1 {
  fill: #000;
  stroke-width: 0px;
}

.cls-2 {
  fill: #fff;
  font-family: GintoNormal-Bold, 'Ginto Normal';
  font-size: 232px;
  font-weight: 700;
}
</style>
