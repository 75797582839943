<template>
  <svg
    class="mr-auto ml-auto"
    :class="cssClass"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 512 512"
  >
    <g id="icomoon-ignore" />
    <path d="M512 176l-64-64-192 192-192-192-64 64 255.999 256 256.001-256z" />
  </svg>
</template>

<script>
export default {
  name: 'IconArrow',
  props: {
    cssClass: {
      type: String,
      default: ''
    }
  }
}
</script>
