import store from '@/store'
import first from 'lodash/first'
import last from 'lodash/last'
import filter from 'lodash/filter'

export function orderStatus (order) {
    let lastLeg = order.legs[order.legs.length - 1]
    let lastStep = lastLeg.steps[lastLeg.steps.length - 1]
    if (order.status !== 'closed' || new Date(lastStep.transit.local_arrive_at) < new Date(store.getters.serverTime)) {
      return { archived: true, text: 'Arkivert' }
    } else {
      return { archived: false, text: 'Åpen' }
    }
}

export function orderCanEdit(order) {
  let status = order.status === 'closed'
  let target = new Date(store.getters.serverTime)
  target.setHours(target.getHours() + 1) // You can edit upto one hour before departure
  let departure = new Date(order.legs[0].steps[0].transit.local_depart_at)
  return status && departure > target
}

export function orderOrigin (order) {
  const leg = first(order.legs)
  const step = first(leg.steps)
  return step.transit
}

export function orderDestination (order) {
  const leg = first(order.legs)
  const step = last(leg.steps)
  return step.transit
}

export function orderHasReturn (order) {
  return order.legs.length > 1
}

export function orderReturnOrigin (order) {
  const leg = last(order.legs)
  const step = first(leg.steps)
  return step.transit
}

export function orderFares (order) {
  return filter(order.product_lines, r => r.additional === false) // Only faretypes
}

export function orderReturnDestination (order) {
  const leg = last(order.legs)
  const step = last(leg.steps)
  return step.transit
}

export default { orderStatus, orderOrigin, orderCanEdit, orderDestination, orderHasReturn, orderReturnOrigin, orderReturnDestination, orderFares }
