<template>
  <div 
    v-if="shoppingCart"
    class="text-nw-base mt-5"
  >
    <h2 
      v-if="edit"
      class="text-nw-lg text-blue-norway leading-none"
    >
      {{ text('Payment.newjourney') }}
    </h2>
    <div 
      v-for="(leg, i) in shoppingCart.legs" 
      :key="i" 
      class="mb-5 border border-blue-norway p-3 mt-2"
    >
      <div
        class="text-nw-base" 
      >
        <div>
          <div class="text-nw-base leading-6 text-blue-norway">
            {{ legDepartureName(leg) + ' - ' + legArrivalName(leg) }}
          </div>

          <div class="mt-2 text-nw-base">
            {{ toTravelDate(lang, legDepartureDate(leg)) + ', ' + toTime(lang, legDepartureTime(leg)) + ' - ' + toTime(lang, legArrivalTime(leg)) }}
          </div>

          <div v-if="leg.steps.length > 1">
            <div
              v-for="(step, j) in leg.steps"
              :key="j"
              class="text-grey-font leading-4 mt-2 text-nw-xs"
            >
              <div 
                v-if="step.travel_mode === 'transit'" 
                class=""
              >
                <span>{{ toTime(lang, stepDepartureTime(step)) + ' - ' + toTime(lang, stepArrivalTime(step)) + ' ' + stepDepartureName(step) + ' - ' + stepArrivalName(step) }}</span>
              </div>
            </div>
          </div>
          <div>
            <ul
              class="mt-4"
            >
              <li
                v-for="(name, j) in legShortNames(leg)"
                :key="j"
                class="bg-grey-400 w-auto inline mr-2 py-1 px-4 text-nw-sm rounded-full"
              >
                {{ name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Discount -->
    <div 
      v-if="shoppingCart.discount_code" 
      class="mt-5"
    >
      <span>{{ text('Discount') }}: {{ shoppingCart.discount_code }}</span>
    </div>

    <!-- Price specification edit order -->
    <div
      v-if="edit"
      id="price-details"
      class="w-full"
    >
      <h3 class="text-nw-lg block text-blue-norway">
        {{ text('Ticket details') }}
      </h3>
      <table class="text-nw-base w-full mt-2">
        <tbody class="">
          <tr 
            v-for="item in shoppingCart.product_lines" 
            :key="item.productId"
          >
            <td class="text-left w-auto">
              {{ item.title }} x {{ item.quantity }}
            </td>
            <td class="text-right">
              {{ item.line_total }} kr
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Total -->
      <div class=" -mr-5 -ml-5 mt-5 p-5 bg-grey-300">
        <table class="w-full">
          <tr>
            <td class="text-left">
              Du har betalt:
            </td>
            <td class="text-right">
              {{ shoppingCart.paid_total }} kr
            </td>
          </tr>
          <tr>
            <td class="text-left">
              Pris for ny reise:
            </td>
            <td class="text-right">
              {{ shoppingCart.total }} kr
            </td>
          </tr>
          <tr class="text-nw-base-plus">
            <td class="text-left pt-5">
              {{ shoppingCart.payment_due > -1 ? "Å betale:" : "Du får kreditert" }}
            </td>
            <td class="text-right pt-5">
              {{ Math.abs(shoppingCart.payment_due) }} kr
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Price specification new purchase-->
    <div 
      v-else 
      id="price-details"
      class="w-full"
    >
      <h3 class="text-nw-lg block text-blue-norway">
        {{ text('Ticket details') }}
      </h3>
      <table class="text-nw-base w-full mt-2">
        <tbody class="">
          <tr 
            v-for="item in shoppingCart.product_lines" 
            :key="item.productId"
          >
            <td class="text-left w-auto">
              {{ item.title }} x {{ item.quantity }}
            </td>
            <td class="text-right">
              {{ item.line_total }} kr
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Total -->
      <div class=" -mr-5 -ml-5 mt-5 p-5 text-nw-base-plus bg-grey-300">
        <span>{{ text('Total price alt') }}:</span>
        <span class="float-right">{{ shoppingCart.payment_due }} kr</span>
      </div>
    </div>

    <!-- Price rule has forced new price warning -->
    <transition
      name="fade" 
      appear
    >
      <message-box 
        v-if="priceChange"
        id="price-change" 
        class="mt-5"
      >
        <template #header>
          {{ text('payment.pricechange.title') }}
        </template>
        <template #main>
          {{ text('payment.pricechange.text') }}
        </template>
      </message-box>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateMixin } from '../components/mixins/DateMixin'
import map from 'lodash/map'
import MessageBox from '../components/MessageBox'
import { 
  tripDepartureDate, 
  legDepartureName, 
  legArrivalName, 
  legDepartureDate, 
  legDepartureTime, 
  legArrivalTime,
  stepDepartureTime,
  stepArrivalTime,
  stepDepartureName,
  stepArrivalName,
  legShortNames
} from '../utilities/TripUtility'
export default {
  name: 'PurchaseDetails',
  components: {
    MessageBox
  },
  mixins: [DateMixin],
  props: {
    shoppingCart: {
      type: Object,
      default: () => { return null }
    },
    priceChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['lang', 'text', 'edit']),
  },
  methods: {
    tripDepartureDate,
    legDepartureName,
    legArrivalName,
    legDepartureDate,
    legDepartureTime,
    legArrivalTime,
    stepDepartureTime,
    stepArrivalTime,
    stepDepartureName,
    stepArrivalName,
    legShortNames,
    map,
    toggleMap (input) {
      if (input.hasOwnProperty('showMapOrigin')) {
        input.showMapOrigin = !input.showMapOrigin
      } else {
        this.$set(input, 'showMapOrigin', true)
      }
    }
  }
}
</script>
