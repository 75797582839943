<template>
  <div>
    <a
      class="underline mx-5 cursor-pointer inline-block"
      @click.prevent="goBack"
    >
      &#8592; {{ text('Common.back') }}
    </a>

    <h2 class="text-nw-xl mx-5 mt-5 mb-5 text-blue-norway">
      {{ text('Return.title') }}
    </h2>

    <existing-booking-accordion class="mb-5" />
    <return-info class="" />
    <TripList
      slot="card"
      :trips="returnTrips"
      :is-return="true"
      :date="returnDate"
      :trips-loading="returnTripsLoading"
      :error="returnTripsError"
      @tripSelected="handleTripSelected"
      @dateChanged="handleDateChanged"
    />
  </div>
</template>

<script>
import TripList from '../components/TripList.vue'
import { mapState, mapGetters } from 'vuex'
import { DateMixin } from '../components/mixins/DateMixin'
import { memberId } from '../utilities/UserUtility'
import { buildShoppingCart } from '../utilities/ShoppingCartUtility'
import ReturnInfo from '../components/ReturnInfo.vue'
import ExistingBookingAccordion from '../components/ExistingBookingAccordion.vue'
import first from 'lodash/first'
import filter from 'lodash/filter'
import { logViewItemList } from '@/utilities/GaUtility'

export default {
  
  name: 'BookingReturn',
  components: {
    ExistingBookingAccordion,
    ReturnInfo,
    TripList
  },
  mixins: [ DateMixin ],
  computed: {
    ...mapState([
      'query',
      'returnTrip',
      'returnTripLoading',
      'returnTrips',
      'returnTripsError',
      'returnTripsLoading'
    ]),
    ...mapGetters([
      'destinationName',
      'originName',
      'step',
      'returnDate',
      'returnTrip',
      'returnDate',
      'ret',
      'serverTime',
      'text',
      'edit',
      'discountCode'
    ])
  },
  watch: {
    returnTrips: function (trips) {
      const visibleTrips = filter(trips, r => first(r.steps).transit.local_depart_at > this.serverTime)
      logViewItemList(visibleTrips, this.ret, true, this.discountCode)
    }
  },
  mounted () {
    // Make sure that to clear any shoppingCarts on this step
    this.$store.commit('setShoppingCart', undefined);
    this.$scrollTo('#app')
  },

  methods: {
    async handleTripSelected (trip) {
      this.$store.commit('setReturnTrip', trip)
      const shoppingCart = await buildShoppingCart(memberId())
      
      if (this.edit) {
          this.$router.push({ name: 'produkter', query: { shoppingCart: shoppingCart.id, edit_id: this.edit.id, edit_booking_number: this.edit.bookingNumber } })
      } else {
        this.$router.push({ name: 'produkter', query: { shoppingCart: shoppingCart.id } })
      }
    },
    handleDateChanged (date) {
      this.$store.commit('setReturnDate', date)
    },
    goBack () {
      this.$router.push({ name: 'utreise', query: { ...this.query } })
    }
  }
}
</script>