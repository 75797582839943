<template>
  <div 
    class="datepicker relative" 
    :class="classes"
  >
    <flat-pickr
      v-model="date" 
      :placeholder="placeholder" 
      class="w-full h-12" 
      aria-label="select traveldate" 
      :tabindex="tabIndex" 
      :config="options"
    />
    <a 
      v-if="clearable && date != null"
      title="Fjern retur" 
      class="absolute right-3 top-3 z-10 cursor-pointer" 
      @click.prevent="clearInput" 
    >
      <icon-remove class="h-6 w-6" />
    </a>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import IconRemove from '@/components/IconRemove.vue'
import { Norwegian } from 'flatpickr/dist/l10n/no.js'

export default {
  name: 'DatePicker',
  components: {
    flatPickr,
    IconRemove
  },
  props: {
    options: {
      type: Object,
      default () {}
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: undefined,
    tabIndex: undefined,
    icon: {
      type: Boolean,
      default: false
    },
    dualIcon: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      startDate: this.value,
      date: this.value
    }
  },
  
  computed: {
    isWrap () {
      if (this.options) {
        return !!this.options.wrap
      }
      return false
    },
    classes () {
      const res = []
      res.push(this.date ? 'not-empty' : 'empty')
      if (this.icon) {
        res.push('calendar-icon')
      }
      if (this.dualIcon) {
        if (this.date) {
          res.push('calendar-icon')
        } else {
          res.push('plus-icon')
        }
      }
      if (!this.valid) {
        res.push('invalid')
      }
      return res.join(' ')
    }
  },
  watch: {
    date (val) {
      this.date = val
      this.$emit('input', this.date)
    },
    startDate () {
      if (this.startDate > this.date) {
        this.date = this.startDate
        this.$emit('input', this.date)
      }
    },
    options (newOptions) {
      // Supports dynamically updating the min date of this datepicker
      // this.flatPickr.set('minDate', newOptions.minDate)
      if (this.startDate < newOptions.minDate) {
        this.startDate = newOptions.minDate
        // this.flatPickr.setDate(newOptions.minDate)
      }
    },
  },
  created () {
    Norwegian.months.shorthand.forEach((month, index) => {
      Norwegian.months.shorthand[index] = month.charAt(0).toLowerCase() + month.slice(1)
    })
  },
  methods: {
    changeVal () {
      this.$emit('input', this.startDate)
    },
    handleClear () {
      this.flatPickr && this.flatPickr.clear()
    },
    clearInput () {
      this.date = null
      this.$emit('input', this.date)
    }
  },
  
}
</script>

<style>
@import '~flatpickr/dist/themes/airbnb.css';

.datepicker input {
  @apply
  border-blue-norway 
  text-nw-base
  w-full 
  bg-white 
  text-left
  p-2
  px-3
  border
  text-color-input-active;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.invalid input {
  @apply border-red-500 border-2
}

.datepicker.calendar-icon input {
  @apply
  bg-calendar
  bg-no-repeat
  bg-left
  pl-12;
  background-position-x: 14px !important;

}

.datepicker.plus-icon input {
  @apply
  bg-plus
  bg-no-repeat
  bg-left
  pl-12;
  background-position-x: 16px;
}


.datepicker input::-webkit-date-and-time-value {
  text-align: left;
}

.datepicker.empty input[type='date']::after { 
  content: attr(placeholder);
  position: absolute;
  width: 100%;
}

.datepicker input::placeholder {
  @apply text-color-input-placeholder pl-0
}

.datepicker input:focus {
  @apply border-2 border-yellow-500 outline-none;
}

.flatpickr-current-month {
  @apply pt-1;
}

.flatpickr-calendar {
  font-family: 'Ginto', sans-serif;
  border: 2px solid #e6e6e6; /* Needed to override the default theme border */
  @apply block mt-1 border-yellow-500 !important;
  @apply border-2 !important;
}

.flatpickr-calendar::before {
  @apply border-2 !important;
}

.flatpickr-calendar::after {
  @apply border-2 !important;
}

.flatpickr-prev-month, .flatpickr-next-month {
  @apply mt-1 text-black fill-black
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
   @apply fill-blue-norway !important
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  @apply bg-white;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background-color: #e9e9e9;
}

.numInputWrapper:hover {
  background-color: #e9e9e9;
}

span.flatpickr-day.today {
  @apply border-b-2 border-b-blue-norway !important
}

span.flatpickr-day.selected {
  @apply bg-blue-norway text-white;
}

span.flatpickr-day.selected:hover {
  @apply bg-blue-norway text-white opacity-80;
}

span.flatpickr-day.disabled {
  background: #e9e9e9;
}
</style>
