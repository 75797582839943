<template>
  <div class="flex bg-grey-norway border-t border-grey-400 flex-row px-5 py-2 text-center">
    <div class="text-left flex justify-center">
      <a 
        href="#"
        class="flex text-nw-sm text-blue-norway"
        @click.prevent="previous(yesterday)"
      >
        <icon-arrow class="rotate-90 fill-blue-500 m-auto" />
        <span class="ml-1">
          {{ toTravelDateShort(lang, yesterday) }}
        </span>
      </a>
    </div>
    <div class="grow">
      <!-- Only show heading if the customer is in the process of buying a trip with returntrip else its not useful -->
    </div>
    <div class="text-right flex justify-center">
      <a
        href="#"
        class="flex text-nw-sm text-blue-norway"
        @click.prevent="next(tomorrow)"
      >
        <span> 
          {{ toTravelDateShort(lang, tomorrow) }}
        </span>
        <icon-arrow class="-rotate-90 ml-1 fill-blue-500 my-auto" />
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { DateMixin } from './mixins/DateMixin'
  import IconArrow from './icons/IconArrow.vue'
  export default {
    name: 'TripListDateNavigation',
     components: {
      IconArrow
    },
    mixins: [DateMixin],
    props: {
      canChooseYesterday: {
        type: Boolean,
        default: false
      },
      yesterday: {
        type: String,
        default: ''
      },
      tomorrow: {
        type: String,
        default: ''
      },
      isReturn: {
        type: Boolean,
        default: false
      },
      isTop: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters(['lang', 'text', 'returnDate']),
    },
    methods: {
      previous (value) {
        this.$emit('previous', value)
      },
      next (value) {
        this.$emit('next', value)
      }
    }
  }
</script>