<template>
  <div 
    v-if="responseCode === 'Cancel'" 
    id="loading-container" 
    class="p-12 text-center text-blue-500 text-nw-base"
  >
    <img 
      class="mt-5 mx-auto" 
      src="../assets/loading.svg"
    >
  </div>
  <div 
    v-else-if="responseCode === 'OK'" 
    id="loading-container" 
    class="p-12 text-center text-blue-500 text-nw-base"
  >
    <span>{{ text('Confirming transaction please wait') }} ...</span>
    <img 
      class="mt-5 mx-auto" 
      src="../assets/loading.svg"
    >
  </div>
</template>

<script>

import api from '../api'
import { mapGetters } from 'vuex'
import { createExpressCompleteObject } from '../utilities/CheckoutUtility'
import { DateMixin } from '../components/mixins/DateMixin'
import { localStorageGet } from '../utilities/LocalStorage'
import { logPurchase } from '../utilities/GaUtility'

export default {
  name: 'VippsCallback',
  mixins: [DateMixin],
  data () {
    return {
      error: false,
      responseCode: undefined
    }
  },
  computed: {
    ...mapGetters([
      'text'
    ])
  },
  async created () {
    let token = this.$router.currentRoute.query.token
    let parentTransactionId = this.$router.currentRoute.query.parent_transaction_id
    this.responseCode = this.$router.currentRoute.query.responseCode
    let code = this.$router.currentRoute.query.code

    if (this.responseCode === 'OK') {
      let expressCompleteInput = createExpressCompleteObject(parentTransactionId, code)
      try {
        let checkoutResult = await api.ExpressCheckout(token, expressCompleteInput)
        logPurchase(checkoutResult, 'nets')
        try {
          api.SendOrderMail(checkoutResult.id, checkoutResult.booking_number)
        } catch {}
        this.$router.push(
          { 
            name: 'receipt-new', 
            params: { reference: checkoutResult.booking_number, id: checkoutResult.id },
            query : { showHeading: true } 
          }
        )
      } catch (e) {{
        console.log(e)
        let query = JSON.parse(localStorageGet('query'))
        this.$router.push({ name: 'betale', query: { ...query, err: 1 } })
        return
      }
    }
    } else {
      let query = JSON.parse(localStorageGet('query'))
      this.$router.push({ name: 'betale', query: { ...query, err: 1 } })
      return
    }
  },
}
</script>
