<template>
  <div />
</template>

<script>

export default {

  name: 'ReceiptRewrite',

  props: {
    reference: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    departure: {
      type: String,
      default: null,
    },
  },
  created () {
    this.$router.push({ name: 'receipt', params: { reference: this.reference, departure: this.departure } })
  }
}

</script>
