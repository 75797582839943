<template>
  <!-- Used to break the css hierachy -->
  <div class="n-tw mx-auto min-h-1/4 p-5 py-10 max-w-xl"> 
    <h1 class="text-nw-xl">
      {{ text('My account') }}
    </h1>
    <hr class="mt-2 md:mt-4">
    <div class="w-full">
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2">
          <table 
            v-if="profile" 
            class="text-black mt-2 md:mt-4"
          >
            <tr v-if="profile.givenName !== null">
              <td><span>{{ text('Name') }}:</span></td>
              <td class="ml-2">
                {{ profile.givenName + ' ' + profile.familyName }}
              </td>
            </tr>
            <tr>
              <td><span>{{ text('Mobile') }}:</span></td>
              <td class="ml-2">
                {{ profile.phoneNumber }}
              </td>
            </tr>
            <tr>
              <td><span class="whitespace-nowrap">{{ text('Email') }}:</span></td>
              <td class="pl-2">
                {{ profile.email }}
              </td>
            </tr>
          </table>
        </div>
        <div class="w-full md:w-1/2">
          <ul class="float-left md:float-right mt-2 md:mt-4">
            <li>
              <router-link 
                class="text-blue-500 underline mt-2" 
                :to="{ name: 'edit-profile'}"
              >
                {{ text('Edit profile') }}
              </router-link>
            </li>
            <li>
              <router-link 
                class="text-blue-500 underline mt-1" 
                :to="{ name: 'change-password'}"
              >
                {{ text('Change password') }}
              </router-link>
            </li>
            <li>
              <router-link 
                class="text-blue-500 underline mt-1" 
                :to="{ name: 'delete-profile'}"
              >
                {{ text('Delete account') }}
              </router-link>
            </li>
            <li>
              <router-link
                class="text-blue-500 underline mt-1"
                :to="{ name: 'import-ticket'}"
              >
                {{ text('Import ticket link') }}
              </router-link>
            </li>
            <li>
              <a 
                href="#"
                class="text-blue-500 underline mt-1"
                @click.prevent="logOut"
              >
                {{ text('Logout') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div>
      <h2 class="text-nw-lg mt-10">
        {{ text('My trips') }}
      </h2>
      <ul class="flex mt-5 text-center cursor-pointer">
        <li 
          class="py-2 w-1/2 px-6 rounded-t-lg" 
          :class="selected === 1 ? 'border-b-4 border-blue-500' : 'text-grey-500 border-b-4 border-grey-400'" 
          @click.prevent="selected = 1"
        >
          {{ text('Active') }}
        </li>
        <li 
          class="py-2 w-1/2 px-6 rounded-t-lg"
          :class="selected === 2 ? 'border-b-4 border-blue-500' : 'text-grey-500 border-b-4 border-grey-400'"
          @click.prevent="selected = 2"
        >
          {{ text('Archived') }}
        </li>
      </ul>

      <div v-if="loading">
        <div class="text-center mt-8">
          <div class="loading" />
        </div>
      </div>
      <div v-else>
        <ul v-if="selected === 1">
          <li
            v-for="order in activeOrders"
            :key="order.id"
          >
            <order :order="order" />
          </li>
        </ul>
        <ul v-if="selected === 2">
          <li 
            v-for="order in archivedOrders"
            :key="order.id"
          >
            <order :order="order" />
          </li>
        </ul>
      </div>
    </div>

    <success-message 
      v-if="successMessage"
      class="mt-5" 
      :text="successMessage" 
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import api from '../api/index'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import map from 'lodash/map'
import Order from '../components/account/Order.vue'
import { localStorageGet, localStorageRemove } from '../utilities/LocalStorage'
import { orderStatus } from '../utilities/OrderUtility.js'
import SuccessMessage from '../components/SuccessMessage.vue'

export default {

  name: 'Account',
  
  components: {
    Order,
    SuccessMessage,
    SuccessMessage
  },

  props: {
    successMessage: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      profile: undefined,
      selected: 1,
      loading: true,
      orders: []
    }
  },
  
  computed: {
    ...mapGetters([
      'text',
      'lang'
    ]),
    archivedOrders () {
      return orderBy(filter(map(this.orders, value => {
        value.clientStatus = this.orderStatus(value)
        return value
      }), value => value.clientStatus.archived === true), r => r.legs[0].steps[0].transit.depart_at, 'desc')
    },
    activeOrders () {
      return orderBy(filter(map(this.orders, value => {
        value.clientStatus = this.orderStatus(value)
        return value
      }), value => value.clientStatus.archived === false), r => r.legs[0].steps[0].transit.depart_at)
    }
  },
  
  async created () {
    try {
      this.profile = await api.UserGet()
      this.orders = orderBy(await api.GetOrdersByCustomer(localStorageGet('memberId')), function (e) { return e.id }, ['desc'])
      this.loading = false
    } catch (e) {
      this.logOut()
    }
  },

  methods: {
    orderStatus,
    logOut () {
      localStorageRemove('authtoken')
      localStorageRemove('email')
      localStorageRemove('memberId')
      localStorageRemove('mobile')
      this.$router.push({ name: 'login' })
    }
  },
}
</script>

<style lang="css" scoped>
  .loading {
    background-image: url(../assets/loading.svg);
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
    width: 36px;
    height: 100px;
  }
</style>
