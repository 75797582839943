import { localStorageGet, localStorageSet } from './LocalStorage';

export function memberId () {
  return localStorageGet('memberId');
}

export function isAuthenticated () {
  return memberId() !== null && memberId() !== undefined;
}

export function setAuthenticated(token, memberId, email, mobile) {
  localStorageSet('authtoken', token)
  localStorageSet('memberId', memberId)
  localStorageSet('email', email)
  localStorageSet('mobile', mobile)
}

export function getEmail () {
  return localStorageGet('email');
}
export function getMobile () {
  return localStorageGet('mobile');
}

export default {
  memberId,
  isAuthenticated,
  setAuthenticated,
  getEmail,
  getMobile
}