export const texts = Object.freeze({
  common: {
    abort: {
      nb: 'Avbryt',
      en: 'Cancel'
    },
    back: {
      nb: 'Tilbake',
      en: 'Back',
    },
    close: {
      nb: 'Lukk',
      en: 'Close',
    },
    departures: {
      nb: 'avganger',
      en: 'departures'
    },
    email: {
      nb: 'E-post',
      en: 'Email',
    },
    findmybus: {
      nb: 'Hvor er bussen?',
      en: 'Find my bus'
    },
    loading: {
      nb: 'laster',
      en: 'loading'
    },
    password: {
      nb: 'Passord',
      en: 'Password',
    },
    phone: {
      nb: 'Telefonnummer',
      en: 'Phone number'
    },
    pleasewait: {
      nb: 'vennligst vent litt',
      en: 'please wait'
    },
    return: {
      nb: 'retur',
      en: 'return'
    },
    reference: {
      nb: 'Referanse',
      en: 'Reference'
    },
    price: {
      nb: 'Pris',
      en: 'Price'
    },
    duration: {
      nb: 'reisetid',
      en: 'travel time'
    },
    currency: {
      nb: 'kr',
      en: 'NOK'
    },
    send: {
      nb: 'Send',
      en: 'Send'
    },
    sending: {
      nb: 'Sender',
      en: 'Sending'
    }
  },
  travelinfo: {
    title: {
      nb: 'Hvor vil du reise?',
      en: 'Book your next journey'
    },
    titleedit: {
      nb: 'Rediger reisedetaljer',
      en: 'Edit travel details'
    },
    departure: {
      nb: 'Avgang',
      en: 'Departure'
    },
    return: {
      nb: 'Retur',
      en: 'Return'
    },
    travelfrom: {
      nb: 'jeg skal reise fra',
      en: 'i am travelling from'
    },
    travelto: {
      nb: 'jeg skal reise til',
      en: 'i am travelling to'
    },
    flipdirection: {
      nb: 'Bytt retning',
      en: 'flip direction'
    },
    button: {
      nb: 'Vis tid og pris',
      en: 'Show time and price'
    },
    error: {
      nb: 'Velg både fra og til',
      en: 'Select both from and to'
    },
    discount: {
      nb: 'Rabattkode',
      en: 'Discount'
    }
  },
  'show stops': {
    nb: 'Vis holdeplasser',
    en: 'Show stops'
  },
  departure: {
    title: {
      nb: 'Velg avgang',
      en: 'Select departure'
    }
  },
  return: {
    title: {
      nb: 'Velg retur',
      en: 'Select return'
    }
  },
  triplist: {
    errortitle: {
      nb: 'Kunne ikke hente reiser',
      en: 'Could not load trips'
    },
    errortext: {
      nb: 'Ta kontakt med kundeservice så vil de hjelpe deg',
      en: 'Please contact customer service for assistance'
    },
    showpasttrips: {
      nb: 'vis passerte avganger',
      en: 'show departed trips'
    },
    hidepasttrips: {
      nb: 'skjul passerte avganger',
      en: 'hide departed trips'
    },
    nooutboundtripsfound: {
      nb: 'Vi finner dessverre ingen avganger på dette søket. Se avganger kommende dato, eller rediger søket.',
      en: 'Unfortunately, we cannot find any results to your search. Please check the following day or edit your search'
    },
  },
  tripitem: {
    choosedeparture: {
      nb: 'velg avgang',
      en: 'select'
    },
    details: {
      nb: 'se detaljer',
      en: 'see details'
    },
    bustransfer: {
      nb: 'bytte',
      en: 'transfer'
    },
    bustransfers: {
      nb: 'bytter',
      en: 'transfers'
    },
    past: {
      nb: 'avgang passert',
      en: 'departed'
    },
    soldout: {
      nb: 'utsolgt',
      en: 'sold out'
    },
    closed: {
      nb: 'forhåndssalg stengt',
      en: 'online booking closed'
    },
    notopen: {
      nb: 'ikke åpnet for salg',
      en: 'online booking not opened'
    },
    error: {
      nb: 'ikke tilgjengelig',
      en: 'not available'
    },
  },
  products: {
    heading: {
      nb: 'Trenger du tillegg til reisen?',
      en: 'Would you like extra products for the journey?'
    },
    subheading: {
      nb: 'Bagasje, sykkel, barnesete, sitt alene osv.',
      en: 'Luggage, bike, child seat, sit alone etc.'
    },
    button : {
      nb: 'gå videre',
      en: 'continue'
    },
    loading: {
      nb: 'laster bestilling',
      en: 'loading booking'
    },
    noproducts: {
      nb: 'ingen produkter tilgjengelig',
      en: 'no products available'
    }
  },
  authenticate: {
    title: {
      nb: 'Logg inn før betaling',
      en: 'Log in before payment'
    },
    subheading: {
      nb: 'Logg inn før betaling for å',
      en: 'Log in before payment to'
    },
    benefit1: {
      nb: 'få tilgang til endring av billett etter kjøp',
      en: 'get access to change of booking after purchase'
    },
    benefit2: {
      nb: 'få oversikt over kjøp og kvitteringer',
      en: 'find an overview of purchases and receipts'
    },
    forgottenpassword: {
      nb: 'glemt passord?',
      en: 'forgotten password?'
    },
    error: {
      nb: 'Pålogging mislyktes - sjekk  brukernavn og passord',
      en: 'Login failed - check username and password'
    },
    createprofile: {
      nb: 'Opprett profil',
      en: 'Create profile'
    },
    continuewithoutlogin: {
      nb: 'Fortsett uten å registrere deg',
      en: 'Continue without registering'
    },
    warning: {
      nb: 'For å få tilgang til endring av billett må du være logget inn når du gjennomfører kjøpet',
      en: 'To get access to changing the ticket, you must log in before payment'
    },
    continuewithoutloginbutton: {
      nb: 'Fortsett uten å logge inn',
      en: 'Continue without logging in'
    }
  },
  resetpassword: {
    title: {
      nb: 'Glemt passord?',
      en: 'Forgotten password?'
    },
    text: {
      nb: 'Skriv inn e-postadressen som er ditt brukernavn, så sender vi deg lenke for å endre passord.',
      en: 'Enter the email you are registered with and we will send you a link to create new password.'
    },
    button: {
      nb: 'Send inn',
      en: 'Send'
    },
    message: {
      nb: 'Hvis vi fant en profil registrert med din e-post så har vi sendt en lenke til:',
      en: 'If we found an account registered with your email, we have sent a link to:'
    }
  },
  register: {
    title: {
      nb: 'Registrer meg',
      en: 'Register an account'
    }
  },
  payment: {
    title : {
      nb: 'bekreft reisen din og velg betalingsmåte',
      en: 'confirm your journey and choose payment method'
    },
    edittitle: {
      nb:'Bekreft endring',
      en: 'Confirm change'
    },
    newjourney: {
      nb: 'Din nye reise',
      en: 'Your new journey'
    },
    information: {
      nb: 'din informasjon',
      en: 'your information'
    },
    redirectpayment: {
      nb: 'Sender deg til betaling',
      en: 'Redirecting you to payment'
    },
    redirectreceipt: {
      nb: 'Sender deg til kvittering',
      en: 'Redirecting you to receipt'
    },
    failed: {
      nb: 'Betalingen ble avbrutt eller mislyktes, du kan forsøke igjen med en annen betalingsmåte',
      en: 'Payment was cancelled or failed. You can try again with another payment method'
    },
    pricechange: {
      title: {
        nb: 'Prisen du valgte er dessverre ikke lenger tilgjengelig. Se ny pris i sammendraget ovenfor.',
        en: 'The price you chose is unfortunately no longer available. Please find your new total above. '
      },
      text: {
        nb: 'Gå til betaling eller gjør reisesøk på nytt.',
        en: 'Please proceed to payment or make a new search.'
      },
    }
  },
  capacityerror: {
    title : {
      nb: 'Etter at du valgte reise og produkter, har noe i ordren din blitt utsolgt.',
      en: 'One of the products in your order has sold out. Please choose journey and add-ons again.'
    },
    text: {
      nb: 'Vi tar deg tilbake så du kan forsøke på nytt.',
      en: 'We will redirect you to try again.'
    }
  },
  receipt: {
    emailnotification: {
      nb: 'Du mottar bekreftelse via epost innen kort tid.',
      en: 'You will receive confirmation by email shortly.'
    },
    payed: {
      nb: 'Betalt',
      en: 'Payed'
    },
    download: {
      nb: 'Se kvittering for reisen',
      en: 'See receipt for your journey'
    },
    questiontitle: {
      nb: 'Har du spørsmål om din reise?',
      en: 'Do you have questions regarding your journey?'
    }
  },
  account: {
    nb: 'konto',
    en: 'account'
  },
  'account info': {
    nb: 'logg inn før betaling for å ha tilgang til å endre billetten din etter kjøp.',
    en: 'For access to changing ticket, please make sure you are logged in before purchase.'
  },
  'account deleted': {
    nb: 'Kontoen er slettet',
    en: 'The account has been deleted'
  },
  'account could not be deleted': {
    nb: 'Kunne ikke slette konto, sjekk passord',
    en: 'Could not delete account, please check your password'
  },
  active: {
    nb: 'Kommende',
    en: 'Active'
  },
  
  'import ticket link': {
    nb: 'Importer billett til konto',
    en: 'Import ticket to account'
  },
  'import ticket heading': {
    nb: 'Importer billett',
    en: 'Import ticket'
  },
  'import ticket main text': {
    nb: 'Var du logget inn da du kjøpe billett så har du tilgang til endring av billett. Her gir vi deg mulighet til å importere billett som du kjøpte uten å være logget inn.<br/><br/>Epost på ordre og konto må være identiske for at import skal fungere. Etter import vil billetten vises i din billettoversikt på ‘Min side’ med lenke til endring.',
    en: 'If you were logged in when purchasing your ticket, you have access to changing ticket. Here we give you the opportunity to import tickets you purchased when you were not logged in.<br/><br/>Please note that the email on your account and order must be identical. After import, the ticket will show in the ticket overview with a link to changing ticket.'
  },
  'import ticket terms heading': {
    nb: 'Import kan gjøres når:',
    en: 'The requirements for ticket import are:'
  },
  'import ticket term one': {
    nb: 'E-postadresse på billett er samme adresse som konto er registrert på.',
    en: 'The email used when booking ticket must be the same email adress the account is registered on.'
  },
  'import ticket term two': {
    nb: 'Du har tilgang på billettens referansenummer (vises i ordrebekreftelse).',
    en: 'You have access to the ticket reference number displayed on the booking confirmation.'
  },
  'import ticket button': {
    nb: 'Importer billett',
    en: 'Import ticket'
  },
  'import ticket button in progress': {
    nb: 'Importerer billett',
    en: 'Importing ticket'
  },
  'import failed': {
    nb: 'Importen feilet dessverre. Se om informasjonen du oppgav er riktig',
    en: 'Import failed. Please check that the information you provided is correct'
  },
  'import succeeded title': {
    nb: 'Billetten ble importert',
    en: 'Import succeeded'
  },
  'import succeeded html': {
    nb: 'Du finner den nå på <a class="underline text-blue-500" href="/min-side/#/min-side">min side</a>',
    en: 'You can find it on <a class="underline text-blue-500" href="/en/my-page/#/min-side">my account</a>'
  },
  archived: {
    nb: 'Arkivert',
    en: 'Archived'
  },
  amount: {
    nb: 'beløp',
    en: 'amount'
  },
  'and prices': {
    nb: 'og priser',
    en: 'and prices'
  },
  arrival: {
    nb: 'ankomst',
    en: 'arrival'
  },
  back: {
    nb: 'tilbake',
    en: 'back'
  },
  'back to login': {
    nb: 'tilbake til pålogging',
    en: 'back to login'
  },
  'back to purchase': {
    nb: 'Tilbake til kjøp',
    en: 'Back to puchase'
  },
  'back to frontpage': {
    nb: 'Tilbake til forsiden',
    en: 'Back to frontpage'
  },
  'barcode instructions': {
    nb: 'Vis qr koden til sjåføren når du går ombord i bussen.',
    en: 'Show the qr code to the driver when boarding the bus.'
  },
  
  change: {
    nb: 'endre',
    en: 'change'
  },
  'change password': {
    nb: 'endre passord',
    en: 'change password'
  },
  'changing password': {
    nb: 'oppdaterer passord',
    en: 'updating password'
  },
  'check your traveldetails': {
    nb: 'se over dine reisedetaljer',
    en: 'check your travel details'
  },
  
  'choose password': {
    nb: 'velg passord',
    en: 'create password'
  },
  city: {
    nb: 'poststed',
    en: 'city'
  },
  'confirm password': {
    nb: 'bekreft nytt passord',
    en: 'confirm new password'
  },
  'confirming transaction please wait': {
    nb: 'Vent litt',
    en: 'Please wait'
  },
  'contact our customer service by phone': {
    nb: 'Se <a href=\'https://www.nor-way.no/reiseinfo/\'>reiseinfo</a> for vår informasjon rundt din reise. Kundeservice kan kontaktes på tlf ',
    en: 'Do you have questions regarding your journey? See our <a href=\'https://www.nor-way.no/en/travel-information/\'>travel information</a> for more details. For other questions, please get in touch with customer service on '
  },
  'contact disclaimer': {
    nb: 'Mobilnummer brukes kun til å informere om  eventuelle endringer på reisen din.',
    en: 'Phone number is only used if there are changes to your journey.'
  },
  'continue without logging in': {
    nb: 'fortsett uten å logge inn',
    en: 'continue without logging in'
  },
  'communication by email': {
    nb: 'Innstillinger for kommunikasjon via e-post',
    en: 'Settings for email communication'
  },
  'cookies blocked': {
    nb: 'Nettleseren din blokkerer for cookies. Innlogging vil dessverre ikke fungere så lenge disse er blokkert.',
    en: 'Your browser is blocking cookies. Logon wont work while cookies are being blocked.'
  },
  country: {
    nb: 'Land',
    en: 'Country'
  },
  'could not load booking': {
    nb: 'Kunne ikke laste bestilling. Gå til kontosiden din og velg reise derfra',
    en: 'Could not load booking. Go to your account and select your booking'
  },
  'create account': {
    nb: 'opprett konto',
    en: 'create account'
  },
  date: {
    nb: 'dato',
    en: 'date'
  },
  'delete account': {
    nb: 'slett konto',
    en: 'delete account'
  },
  'delete my profile': {
    nb: 'slett min konto',
    en: 'delete my account'
  },
  'delete profile': {
    nb: 'slett konto',
    en: 'delete account'
  },
  'delete profile first warning text 1': {
    nb: 'er du sikker på at du vil slette din konto?',
    en: 'are you sure you want to delete your account?'
  },
  'delete profile first warning text 2': {
    nb: 'er du sikker på at du vil slette din konto? Vi kan ikke tilbakestille dette.',
    en: 'are you sure you want to delete your account?. We cannot undo this.'
  },
  'departure trip': {
    nb: 'avreise',
    en: 'departure trip'
  },
  departure_alt: {
    nb: 'utreise',
    en: 'departure'
  },
  
  discount: {
    nb: 'rabattkode',
    en: 'discount code'
  },
  'do you have an account': {
    nb: 'Har du opprettet konto på nor-way.no',
    en: 'Do you have an account at nor-way.no'
  },
  'download your ticket': {
    nb: 'last ned din kvittering',
    en: 'download your receipt'
  },
  
  'edit ticket': {
    nb: 'Endre billett',
    en: 'Change ticket'
  },
  'edit profile': {
    nb: 'endre profil',
    en: 'edit profile'
  },
  email: {
    nb: 'e-post',
    en: 'email'
  },
  'enter location': {
    nb: 'skriv inn sted',
    en: 'enter location'
  },
  'error title': {
    nb: 'en feil har oppstått',
    en: 'an error has occured'
  },
  'error invalid email': {
    nb: 'ugyldig e-post format',
    en: 'invalid email format'
  },
  'error password to short': {
    nb: 'passordet er for kort',
    en: 'the password is to short'
  },
  'error passwords do not match': {
    nb: 'passord og bekreft passord er ikke like',
    en: 'password and confirm password does not match'
  },
  'existing customer': {
    nb: 'kunde fra før',
    en: 'already a customer'
  },
  'fill in your information': {
    nb: 'Fyll inn din informasjon',
    en: 'Fill in your contact information'
  },
  firstname: {
    nb: 'fornavn',
    en: 'firstname'
  },
  from: {
    nb: 'fra',
    en: 'from'
  },
  'forgotten password': {
    nb: 'glemt passord',
    en: 'forgotten password'
  },
  'good journey': {
    nb: 'ha en god reise ',
    en: 'have a good journey '
  },
  'have you forgotten your': {
    nb: 'har du glemt ditt',
    en: 'have you forgotten your'
  },
  'hide details': {
    nb: 'skjul detaljer',
    en: 'hide details'
  },
  'how do you want to pay': {
    nb: 'hvordan vil du betale',
    en: 'how would you like to pay'
  },
  'i have a discount code': {
    nb: 'jeg har rabattkode',
    en: 'i have a discount code'
  },
  'invalid email': {
    nb: 'Ugylding epost format',
    en: 'Invalid email format'
  },
  'i want': {
    nb: 'jeg ønsker',
    en: 'i wish'
  },
  lastname: {
    nb: 'etternavn',
    en: 'surname'
  },
  
  'loading ticket': {
    nb: 'laster billett',
    en: 'loading ticket'
  },
  login: {
    nb: 'logg inn',
    en: 'login'
  },
  'login in progress': {
    nb: 'logger inn',
    en: 'logging in'
  },
  'login and continue': {
    nb: 'logg inn og fortsett',
    en: 'log in'
  },
  'login intro': {
    nb: 'få oversikt over dine bestillinger.',
    en: 'get an overview of your orders.'
  },
  'logon failed title': {
    nb: 'pålogging mislyktes',
    en: 'logon failed'
  },
  'logon failed text': {
    nb: 'feil brukernavn eller passord',
    en: 'wrong username or password'
  },
  'login to access': {
    nb: 'Logg inn før betaling for å få tilgang til endring av billett etter kjøp',
    en: 'Log in before payment to be able to change your ticket after purchase'
  },
  logout: {
    nb: 'logg ut',
    en: 'log out'
  },
  map: {
    nb: 'kart',
    en: 'map'
  },
  mobile: {
    nb: 'mobil',
    en: 'mobile'
  },
  'my account': {
    nb: 'Min konto',
    en: 'My account'
  },
  'my profile': {
    nb: 'min profil',
    en: 'my profile'
  },
  'my trips': {
    nb: 'mine reiser',
    en: 'my trips'
  },
  name: {
    nb: 'navn',
    en: 'name'
  },
  'new travel details': {
    nb: 'Velg din nye reise',
    en: 'Choose your new journey'
  },
  newpassword: {
    nb: 'nytt passord',
    en: 'new password'
  },
  'new password confirmation header': {
    nb: 'Gå til epost-innboksen din',
    en: 'Please check your email inbox'
  },
  'new password confirmation text': {
    nb: 'Om det er registrert en konto på denne adressen har vi sendt deg en e-post med lenke til å tilbakestille passord',
    en: 'If your email address is registered in our systems, you will receive an email with a link to password reset.'
  },
  
  open: {
    nb: 'Åpen',
    en: 'Open'
  },
  oldpassword: {
    nb: 'eksisterende passord',
    en: 'exisiting password'
  },
  optional: {
    nb: '(frivillig)',
    en: '(optional)'
  },
  or: {
    nb: 'eller',
    en: 'or'
  },
  'or are you': {
    nb: 'eller er du',
    en: 'or are you'
  },
  password: {
    nb: 'passord',
    en: 'password'
  },
  'password confirm': {
    nb: 'bekreft passord',
    en: 'confirm password'
  },
  'password updated': {
    nb: 'passordet ditt har blitt endret',
    en: 'your password has been changed'
  },
  'password could not be updated': {
    nb: 'passordet kunne ikke oppdateres',
    en: 'password could not be changed'
  },
  'pay with card': {
    nb: 'betal med kort',
    en: 'pay with card'
  },
  'pay with vipps': {
    nb: 'betal med Vipps',
    en: 'pay with Vipps'
  },
  'payment login or contact details': {
    nb: 'du må logge inn eller fortsette uten å logge inn før du kan betale',
    en: 'you must either log in or choose to continue without logging in before payment'
  },
  'payment valid email': {
    nb: 'du må angi en gyldig epostadresse',
    en: 'please provide a valid email address'
  },
  'payment email and phone': {
    nb: 'du må angi en epostadresse og telefon før du kan gå videre til betaling',
    en: 'please provide an email address and phone number before proceeding to payment'
  },
  'payment inititate failed': {
    nb: 'Kunne ikke sende deg til betaling. Ta kontakt med kundeservice for assistanse',
    en: 'Could not inititate payment. Contact customer support for further help'
  },
  'payment failed': {
    nb: 'betaling feilet - prøv gjerne igjen. Husk å legge til aktuelle tilleggsprodukter på nytt.',
    en: 'payment failed - please try again. Remember to add additional products before you try again'
  },
 'payment register info': {
    nb: 'Har du ikke egen konto?',
    en: 'Are you missing an account?'
  },
  'payment register link': {
    nb: 'Opprett ny konto',
    en: 'create account'
  },
  'payment register button': {
    nb: 'Opprett ny konto',
    en: 'create account'
  },
  'payment register link info': {
    nb: '(åpnes i nytt vindu)',
    en: '(opens in a new window)'
  },
  'payment register title': {
    nb: 'registerer',
    en: 'register'
  },
  platform: {
    nb: 'Plattform',
    en: 'Platform'
  },
  
  'amount to pay': {
    nb: 'Beløp å betale',
    en: 'Amount to pay'
  },
  profile: {
    nb: 'profil',
    en: 'profile'
  },
  'profile updated': {
    nb: 'Profil oppdatert',
    en: 'Profile updated'
  },
  'questions about your journey': {
    nb: 'spørsmål om din reise?',
    en: 'questions regarding your trip?'
  },
  'register 2': {
    nb: 'registrer deg',
    en: 'register'
  },
  'register me': {
    nb: 'registrer',
    en: 'register'
  },
  'registration in progress': {
    nb: 'registrerer ...',
    en: 'registration in progress'
  },
  'register terms': {
    nb: 'Når du registrerer deg, samtykker du samtidig til <a class=\'underline\' target=\'_blank\' href=\'https://www.nor-way.no/om-nor-way/personvern\'>Personvernreglene.</a>',
    en: 'By registering, you also approve our <a class=\'underline\' target=\'_blank\' href=\'https://www.nor-way.no/en/about-us/privacy-policy\'>Privacy Policy.</a>'
  },
  'registration failed': {
    nb: 'Du har allerede en konto registrert på denne e-postadressen. Velg glemt passord om du ikke får logget inn.',
    en: 'An account is registered on this address. Please choose forgotten password if you are not able to log in.'
  },
  'registration successful': {
    nb: 'Registreringen er vellykket og du er nå logget inn. Følg lenken nedenfor',
    en: 'Registration is successful and you are now logged you in. For travel search, please follow the link below'
  },
  'remember this': {
    nb: 'Husk dette',
    en: 'Remember this'
  },
  'reset password (page title)': {
    nb: 'Nytt passord',
    en: 'New password'
  },
  
  
  'return traveldetails': {
    nb: 'hjemreise-detaljer',
    en: 'return details'
  },
  'return trip': {
    nb: 'returreise',
    en: 'return trip'
  },
  
  'select ticket type': {
    nb: 'Velg billett',
    en: 'Select ticket'
  },
  'send new password': {
    nb: 'send nytt passord',
    en: 'send new password'
  },
  'sending new password': {
    nb: 'sender nytt passord',
    en: 'sending new password'
  },
  'show map': {
    nb: 'vis kart',
    en: 'show map'
  },
  'show time and price': {
    nb: 'vis tid og pris',
    en: 'show time and price'
  },
  'status: ticket not found': {
    nb: 'Vi kunne ikke finne billetten',
    en: 'We could not load your ticket'
  },
  'stay logged on': {
    nb: 'husk meg',
    en: 'remember me'
  },
  stop: {
    nb: 'stopp',
    en: 'stop'
  },
  stops: {
    nb: 'holdeplasser',
    en: 'stops'
  },
  street: {
    nb: 'gateadresse',
    en: 'street'
  },
  'subscribe to newsletter intro': {
    nb: 'Hold deg informert om våre tilbud, ruteendringer og reisemuligheter. Vi trenger ditt samtykke for å kunne sende siste informasjon. Du samtykker gjennom å krysse av i boksen under. God grønn reise!',
    en: 'Stay up to date regarding our offers, route changes and travel opportunities'
  },
  'subscribe to newsletter': {
    nb: 'Ja takk, jeg ønsker å få tilbud og informasjon på e-post',
    en: 'Yes, I would like to receive newsletter with offers and information'
  },
  'terms and conditions': {
    nb: 'kjøpsvilkårene',
    en: 'terms and conditions'
  },
  'ticket type': {
    nb: 'billettype',
    en: 'ticket type'
  },
  'ticket details': {
    nb: 'billettdetaljer',
    en: 'ticket details'
  },
  ticketerror: {
    nb: 'ukjent status',
    en: 'unknown status'
  },
  ticketexpired: {
    nb: 'utløpt',
    en: 'expired'
  },
  ticketrefunded: {
    nb: 'refundert',
    en: 'refunded'
  },
  ticketvalid: {
    nb: 'gyldig',
    en: 'valid'
  },
  travellers: {
    nb: 'reisende',
    en: 'travellers'
  },
  to: {
    nb: 'til',
    en: 'to'
  },
  towards: {
    nb: 'mot',
    en: 'towards'
  },
  
  'total price alt': {
    nb: 'Total pris',
    en: 'Price total'
  },
 
  'url-purchase-terms': {
    nb: 'https://www.nor-way.no/reiseinfo/kjoepsvilkaar/',
    en: 'https://www.nor-way.no/en/travel-information/conditions-of-purchase/'
  },
  'update profile': {
    nb: 'oppdater profil',
    en: 'update profile'
  },
  'updating': {
    nb: 'oppdaterer',
    en: 'updating'
  },
  'when i buy this ticket, i agree with the': {
    nb: 'når du kjøper billetten sier du deg enig i',
    en: 'when you buy this ticket you agree with the'
  },
  'you are logged in as': {
    nb: 'Du er logget inn som',
    en: 'You are logged in as'
  },
  'you must be logged in to edit orders': {
    nb: 'du må være innlogget for å kunne endre bestillinger',
    en: 'you must be logged in to be able to change your bookings'
  },
  'you are now changing your ticket': {
    nb: 'Du er nå i ferd med å endre reisetidspunkt på billetten din. Bekreft og få oppdatert billett på Min side og epost.',
    en: 'You are now changing your ticket. You will find your new ticket on your account and in your email'
  },
  'your ordered journey': {
    nb: 'Reisen din før endring',
    en: 'Your journey before change'
  },
  'your new journey': {
    nb: 'Reisen din etter endring',
    en: 'Your journey after the change'
  },
  'your password': {
    nb: 'ditt passord',
    en: 'your password'
  },
  'your ticket': {
    nb: 'din billett',
    en: 'your ticket'
  },
  zip: {
    nb: 'postnummer',
    en: 'zip'
  },
  save: {
    nb: 'Lagre',
    en: 'Save'
  },
  saving: {
    nb: 'lagrer',
    en: 'saving'
  },
  
  
  
  'confirm change': {
    nb: 'bekreft endring',
    en: 'confirm change'
  },
  'confirm': {
    nb: 'bekreft',
    en: 'confirm'
  },
  'edit modal title': {
    nb: 'Vil du endre billetten din?',
    en: 'Would you like to change your ticket?'
  },
  'edit modal text': {
    nb: 'Du får mulighet til å gjøre billettendringer fram til en time før avgang. Din originale billett blir kansellert ved endring og du får en ny bekreftelse.<br/><br/>Et eventuelt mellomlegg må dekkes.',
    en: 'You can make ticket changes up until one hour before departure. Your original ticket will be cancelled and new confirmation sent when confirming the change. <br/><br/>Any extra cost must be covered.'
  },
  'edit modal action': {
    nb: 'Fortsett',
    en: 'Continue'
  },
  'password and confirm password are not the same': {
    nb: 'nytt passord og bekreft passord er ikke identiske',
    en: 'new password and confirm password are not identical'
  },
  'new password too short': {
    nb: 'Passordet er for kort',
    en: 'The password is too short'
  },
  'missing password': {
    nb: 'eksisterende passord mangler',
    en: 'existing password missing'
  },
  'new password is missing': {
    nb: 'nytt passord mangler',
    en: 'new password missing'
  },
  
  'show tickets': {
    nb: 'Vis billett',
    en: 'Show booking'
  },
  quantity : {
    nb: 'antall',
    en: 'quantity'
  },
  type: {
    nb: 'type',
    en: 'type'
  },
  total: {
    nb: 'total',
    en: 'total'
  },

  // -- FARES -- //
  'fare-3d2fe8d3-c3c9-44b8-917c-725d859376ba-name': {
    nb: 'Voksen',
    en: 'Adult'
  },
  'fare-3d2fe8d3-c3c9-44b8-917c-725d859376ba-text': {
    nb: 'Fra 18 år',
    en: 'From 18 years'
  },
  'fare-b942bbca-99df-446f-99e8-ee8191066fa6-name': {
    nb: 'Barn',
    en: 'Child'
  },
  'fare-b942bbca-99df-446f-99e8-ee8191066fa6-text': {
    nb: '6 - 17 år',
    en: '6 - 17 years'
  },
  'fare-21650b77-1a9b-48eb-972f-d5ea43a78faf-name': {
    nb: 'Småbarn',
    en: 'Child'
  },
  'fare-21650b77-1a9b-48eb-972f-d5ea43a78faf-text': {
    nb: '0 - 5 år',
    en: '0 - 5 years'
  },
  'fare-83d3e907-afd2-4e26-9d73-c812efa9fc83-name': {
    nb: 'Student',
    en: 'Student'
  },
  'fare-83d3e907-afd2-4e26-9d73-c812efa9fc83-text': {
    nb: 'Elev eller student, 18 - 30 år med gyldig skolebevis/studentbevis.',
    en: 'Student, 18 - 30 years old with a valid student card.'
  },
  'fare-89c95211-ea6a-49d8-a46d-2513b82e8e39-name': {
    nb: 'Honnør',
    en: 'Senior'
  },
  'fare-89c95211-ea6a-49d8-a46d-2513b82e8e39-text': {
    nb: 'Fra fylte 67 år og personer med uførebevis fra NAV.',
    en: 'From 67 years and holders of NAV disability certificate.'
  },
  'fare-b06ed0cd-5b19-4d43-91d1-e7b318d1cb5e-name': {
    nb: 'Vernepliktig',
    en: 'Military'
  },
  'fare-b06ed0cd-5b19-4d43-91d1-e7b318d1cb5e-text': {
    nb: 'Avtjener førstegangstjeneste og kan fremvise forsvarets ID-kort for vernepliktige eller innkallingsbrev til førstegangstjeneste.',
    en: 'In compulsory military service with valid ID-card from the Defence ministry.'
  },
  'fare-2beab689-2023-421a-8112-6efbca15e961-name': {
    nb: 'Ledsager',
    en: 'Carer'
  },
  'fare-2beab689-2023-421a-8112-6efbca15e961-text': {
    nb: 'Person som ledsager en innehaver av kommunalt ledsagerbevis.',
    en: 'Person who accompanies a holder of carer certificate issued by the local municipality.'
  },
  
})
