<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
        class="fixed inset-0 bg-grey-500 bg-opacity-75" 
        aria-hidden="false" 
      />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span 
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div class="inline-block align-middle bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl visible transform my-8  sm:max-w-lg sm:w-full sm:p-6">
        <div class="block absolute top-0 right-0 pt-6 pr-5">
          <button 
            type="button" 
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click.prevent="$emit('close')"
          >
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path 
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="">
          <div class="text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 
              id="modal-title"
              class="text-nw-base border-b-2 border-yellow-500 pb-5 leading-6 font-medium text-gray-900"
            >
              {{ title }}
            </h3>
            <div class="mt-5">
              <div class="text-nw-sm text-gray-500">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="text" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button 
            type="button"
            class="mt-4 w-full justify-center rounded-full bg-blue-500 hover:bg-blue-400 text-white text-nw-base font-semibold font-sans2 p-3"
            @click.prevent="$emit('proceed')"
          >
            {{ actionText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      title: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      actionText: {
        type: String,
        default: ''
      }
    },
  }
</script>