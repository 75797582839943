<template>
  <div>
    <trip-list-date-navigation
      :can-choose-yesteday="showYesterday"
      :yesterday="yesterday"
      :tomorrow="tomorrow"
      :is-return="isReturn"
      :is-top="true"
      @previous="emitYesterday" 
      @next="emitTomorrow" 
    />
    <div
      v-if="tripsLoading"
      class="text-center my-10"
    >
      {{ text('Common.loading') }} 
      {{ isReturn ? text('common.return') : text('common.departures') }}, 
      {{ text('common.pleasewait') }} ...
      <n-loading />
    </div>

    <error-message 
      v-else-if="error" 
      class="text-center my-5" 
      :title="text('triplist.errortitle')"
      :text="text('triplist.errortext')"
    />
    <div v-else>
      <section 
        v-if="showPastTrips" 
        class=" bg-white"
      >
        <TripItem
          v-for="(trip, index) in pastTrips"
          :key="index"
          :trip="trip"
          :index="index"
          :is-past="true"
          @tripSelected="handleTripSelected"
        />
      </section>
      <section
        v-if="pastTrips.length > 0"
        class="mt-7 mb-7"
      >
        <div class="flex justify-center">
          <a 
            class="underline text-nw-sm flex"
            href="#"
            @click.prevent="showPastTrips = !showPastTrips"
          >
           
            <span v-if="showPastTrips">{{ text('Triplist.hidepasttrips') }}</span>
            <span v-else>{{ text('Triplist.showpasttrips') }}</span>
            <div class="ml-3 flex">
              <icon-arrow
                class="my-auto"
                :css-class="showPastTrips ? 'rotate-180 transform' : ''" 
              />
            </div>
          </a>
        </div>
      </section>
      <section 
        :data-hits="eligebleTrips.length > 0" 
        class="bg-white mt-1"
      >
        <TripItem
          v-for="(trip, index) in eligebleTrips"
          :key="index"
          :trip="trip"
          :is-first="index === 0"
          :is-last="index === eligebleTrips.length - 1"
          :is-return="isReturn"
          :index="index"
          @tripSelected="handleTripSelected"
        />
      </section>
      <div
        v-if="eligebleTrips.length == 0 && !tripsLoading"
        id="ga-notrips"
        class="p-5 mb-10 text-center text-nw-base"
      >
        {{ text("triplist.nooutboundtripsfound") }}
      </div>
    </div>

    <trip-list-date-navigation
      class="mt-0"
      :can-choose-yesteday="showYesterday"
      :yesterday="yesterday"
      :tomorrow="tomorrow"
      :is-return="false"
      :is-top="false"
      @previous="emitYesterday" 
      @next="emitTomorrow" 
    /> 
  </div>
</template>

<script>
import TripItem from './TripItem.vue'
import IconArrow from '@/components/icons/IconArrow.vue'
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import filter from 'lodash/filter'
import first from 'lodash/first'
import upperFirst from 'lodash/upperFirst'
import { DateMixin } from './mixins/DateMixin'
import ErrorMessage from './ErrorMessage.vue'
import TripListDateNavigation from './TripListDateNavigation.vue'
import NLoading from '../components/NLoading.vue'

export default {
  name: 'TripList',
  components: {
    TripItem,
    ErrorMessage,
    TripListDateNavigation,
    NLoading,
    IconArrow
  },
  mixins: [DateMixin],
  props: {
    trips: {
      type: Array,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    tripsLoading: {
      type: Boolean,
      required: true
    },
    isReturn: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPastTrips: false
    }
  },
  computed: {
    ...mapGetters([
      'dateOnServer',
      'lang',
      'text',
      'originId',
      'originName',
      'destinationId',
      'destinationName',
      'departureDate',
      'returnDate',
      'returnDate',
      'faresWithCount',
      'step',
      'ret', // Indicates if user want to buy return aswell
      'showEarlierReturnTrips',
      'showLaterReturnTrips',
      'serverTime'
    ]),

    showYesterday () {
      if (!this.isReturn && this.departureDate <= this.dateOnServer) {
        return false
      } else if (this.isReturn && this.returnDate <= this.departureDate) {
        return false
      } else {
        return true
      }
    },
    showTomorrow () {
      if (!this.isReturn && this.ret && this.departureDate >= this.returnDate) {
        return false
      } else {
        return true
      }
    },
    allTrips () {
      return get(this, 'trips', []) // TODO: Check if this is needed
    },
    pastTrips () {
      return filter(this.allTrips, r => first(r.steps).transit.local_depart_at <= this.serverTime)
    },
    eligebleTrips () {
      return filter(this.allTrips, r => first(r.steps).transit.local_depart_at > this.serverTime)
    },
    yesterday () {
      return this.previousDay(
        this.isReturn ? this.returnDate : this.departureDate
      )
    },
    tomorrow () {
      return this.nextDay(this.isReturn ? this.returnDate : this.departureDate)
    },
    tripsWithError () {
      return filter(this.eligebleTrips, r => {
        return filter(r.steps, {
          transit: { availability_status: 'error' }
        }).length > 0
      })
    }
  },
  watch: {
    tripsLoading (value) {
      if (value === true) {
        this.showPastTrips = false
      }
    }
  },
  methods: {
    handleTripSelected (trip) {
      this.$emit('tripSelected', trip)
    },
    emitTomorrow (date) {
      this.$emit('dateChanged', date)
    },
    emitYesterday (date) {
      this.$emit('dateChanged', date)
    },
    upperFirst (value) {
      return upperFirst(value)
    }
  }
}
</script>