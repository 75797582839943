<template>
  <div class="p-5">
    <h1 class="text-nw-3xl">
      {{ text('Change password') }}
    </h1>

    <error-message 
      v-if="errors.length > 0" 
      class="mt-5" 
      :list="errors" 
    />
    <success-message 
      v-if="success"
      class="mt-5"
      :text="text('Password updated')"
    />

    <n-input 
      id="oldPwd" 
      v-model="oldPwd" 
      class="mt-5" 
      type="password" 
      :label="text('OldPassword')" 
    />

    <n-input 
      id="newPwd" 
      v-model="newPwd" 
      class="mt-5" 
      type="password" 
      :label="text('NewPassword')"
    />

    <n-input 
      id="newPwdConfirm"
      v-model="newPwdConfirm"
      class="mt-5"
      type="password"
      :label="text('Confirm password')"
    />

    <div class="flex flex-wrap justify-between mt-5">
      <n-button 
        :working="working" 
        @click="update()"
      >
        {{ working ? text('Changing password') : text('Change password') }}
      </n-button>
      <router-link 
        class="text-nw-sm my-auto text-blue-500 underline"
        :to="{ name: 'account'}"
      >
        {{ text('Back') }}
      </router-link>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import api from '../api'
import NButton from '../components/forms/NButton.vue'
import NInput from '../components/forms/NInput.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import SuccessMessage from '../components/SuccessMessage.vue'

export default {

  name: 'ChangePassword',

  components: {
    NButton,
    NInput,
    ErrorMessage,
    SuccessMessage
  },

  data () {
    return {
      oldPwd: '',
      newPwd: '',
      newPwdConfirm: '',
      errors: [],
      status: -1,
      profile: undefined,
      success: false,
      working: false
    }
  },

  computed: {
    ...mapGetters([
      'text'
    ])
  },

  async created () {
    this.profile = await api.UserGet()
  },

  methods: {
    async update () {
      this.working = true
      this.errors = []
      this.success = false
      this.status = -1
      if (this.oldPwd === '') {
        this.errors.push(this.text('Missing password'))
      }
      if (this.newPwd === '') {
        this.errors.push(this.text('New password is missing'))
      }
      if (this.newPwd !== this.newPwdConfirm) {
        this.errors.push(this.text('Password and confirm password are not the same'))
      }
      if (this.newPwd.length < 6) {
        this.errors.push(this.text('New password too short'))
      }
      if (this.errors.length > 0) {
        this.working = false
        return
      }
      try {
        await api.ChangePassword(this.profile.email, this.oldPwd, this.newPwd)
        this.oldPwd = ''
        this.newPwd = ''
        this.newPwdConfirm = ''
        this.success = true
      } catch {
        this.errors.push(this.text('Password could not be updated'))
      }
      this.working = false
    }
  }
}
</script>
