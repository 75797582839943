<template>
  <svg 
    width="36" 
    height="44" 
    viewBox="0 0 36 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M4 4L21.6 30C21.6 21.3333 24.48 4 36 4" 
      stroke="#216316" 
      stroke-width="8" 
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCheck',
}
</script>