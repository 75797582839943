<template>
  <div class="px-5">
    <a
      class="underline cursor-pointer inline-block"
      @click.prevent="goBack"
    >
      &#8592; {{ text('common.Back') }}
    </a>

    <h2 class="text-nw-xl mt-5 text-blue-norway">
      {{ edit ? text('Payment.edittitle') : text('Payment.title') }}  
    </h2>

    <div 
      v-if="failed"
      class="mt-3 text-red-error"
    >
      {{ text('Payment.failed') }}
    </div>
 
    <existing-booking />
  
    <purchase-details 
      :shopping-cart="shoppingCart"
      :price-change="priceChange"
      class="mt-5" 
    />
    <form 
      @submit.prevent="submit"
    >
      <h2 class="mt-5 text-nw-lg text-blue-norway">
        {{ text('Payment.information') }}
      </h2>
      <n-input
        id="email"
        v-model="email" 
        class="mt-5" 
        :required="true" 
        type="email"
        :placeholder="text('Common.email')"
        autocomplete="email"
      />
      <n-input
        id="mobile"
        v-model="mobile" 
        class="mt-5" 
        :required="true" 
        type="phone"
        :placeholder="text('Common.phone')"
        autocomplete="phone"
      />
      <div
        v-if="terminalLoading"
        class="mt-12 flex flex-col items-center"
      >
        <n-loading />
        {{ paymentDue > 0 ? text('Payment.redirectpayment') : text('Payment.redirectreceipt') }}
      </div>
      <purchase-buttons
        v-else
        class="mt-8"
        :payment-due="paymentDue" 
        :is-change="existingBooking !== null"
      />
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import purchaseDetails from '../components/PurchaseDetails.vue'
import purchaseButtons from '../components/PurchaseButtons.vue'
import ExistingBooking from '../components/ExistingBooking.vue'
import { localStorageSet } from '@/utilities/LocalStorage'
import { memberId, isAuthenticated, getEmail, getMobile } from '@/utilities/UserUtility'
import api from '@/api/index'
import filter from 'lodash/filter'
import get from 'lodash/get'
import NInput from '@/components/forms/NInput.vue'
import NLoading from '@/components/NLoading.vue'
import { calculateShoppingCart } from '@/utilities/ShoppingCartUtility'
import { logAddPaymentInfo } from '@/utilities/GaUtility'
import 
{ 
  createCheckoutObject, 
  createInitiatePaymentObject, 
  createCallbackUrl, 
  createOrderCheckoutObject
} from '@/utilities/CheckoutUtility'
import first from 'lodash/first'
import { createQueryFromShoppingCart } from '@/utilities/QueryUtility'

export default {
  name: 'BookingPayment',
  components: {
    purchaseDetails,
    purchaseButtons,
    ExistingBooking,
    NInput,
    NLoading,
  },
  data () {
    return {
      terminalLoading: false,
      paymentInititateFailed: false,
      email: '',
      mobile: '',
      priceChange: false
    }
  },
  computed: {
    ...mapState([
      'query'
    ]),
    ...mapGetters([
      'existingBooking',
      'step',
      'departureDate',
      'departureTrip',
      'ret',
      'text',
      'lang',
      'shoppingCart',
      'edit',
      'discountCode',
      'existingBooking'
    ]),
    paymentDue () {
      return this.shoppingCart ? this.shoppingCart.payment_due : 0
    },
    fares () {
      return filter(this.shoppingCart.product_lines, r => r.additional === false)
    },
    failed () {
      return this.query.err == '1'
    },
  },
  mounted () {
    if (isAuthenticated()) {
      this.email = getEmail()
      this.mobile = getMobile()
    }
    this.$scrollTo('#app')
  },
  methods: {
    goBack () {
      if (isAuthenticated()) {
        this.$router.push({ name: 'produkter', query: { ...this.query, back: true } })
      } else {
        this.$router.push({ name: 'autentisere', query: { ...this.query } })
      }
    },
    async handleError (error) {
      const errorCode = get(error, 'response.data.error_code')
      const errors = get(error, 'response.data.errors')

      if (errorCode === 'error_price_changed') { // The price has changed, recalculate shopping cart and show new price
        this.$scrollTo('#price-details', { offset: -25 })
        this.priceChange = true
        await calculateShoppingCart(isAuthenticated() ? memberId() : null)
        this.terminalLoading = false
        return
      } 
      // If we get a capacity error, there is not option but to restart the search with a message
      else if (errors.hasOwnProperty('capacity') && first(errors.capacity) === 'not_enough_capacity') {
        const searchQuery = createQueryFromShoppingCart(this.edit?.id, this.edit?.bookingNumber, true)
        this.$router.push({ name: 'utreise', query: { ...searchQuery } })
        return
      }
    },

    async submit (event) {
      // Reset state
      this.paymentInititateFailed = false
      this.terminalLoading = true
      const paymentProvider = event.submitter.value 
      const callbackBaseUrl = createCallbackUrl(paymentProvider)
      let checkoutResult = undefined

      if (!this.existingBooking) { // We are not editing an existing booking
        try {
          const checkoutInput = createCheckoutObject(this.shoppingCart, this.email, this.mobile, memberId())
          checkoutResult = await api.CreateCheckout(checkoutInput)
        } catch (error) {
          await this.handleError(error)
          return
        }
      } else { // We are editing an existing booking
        try {
          const checkoutInput = createOrderCheckoutObject(this.shoppingCart)
          checkoutResult = await api.CreateOrderCheckout(checkoutInput, this.existingBooking.id)
        } catch (error) {
          await this.handleError(error)
          return
        }
      }

      // if we end up with a price of 0, we can skip the payment provider and go straight to the confirmation page
      if (checkoutResult.payment_due < 1) { // No price, straight to confirmation page
        logAddPaymentInfo(this.shoppingCart, 'None', this.shoppingCart.id)
        location.href = `${callbackBaseUrl}?token=${checkoutResult.token}&responseCode=OK`
        return
      }
   
      // We have a price, so we need to initiate payment
      try {
        const initiatePaymentInput = createInitiatePaymentObject(callbackBaseUrl, checkoutResult.token, paymentProvider)
        const initiatePaymentResult = await api.InitiatePayment(checkoutResult.token, initiatePaymentInput)

        // Save the query in local storage so we can use it if we return from the payment provider with an error
        localStorageSet('query', JSON.stringify(this.$router.currentRoute.query))
        logAddPaymentInfo(this.shoppingCart, paymentProvider, initiatePaymentResult.id)
        // Redirect to payment provider
        location.href = initiatePaymentResult.payment_url
      } catch {

        // If we get an error, we need to show the error message and stop the loading spinner, This is a last resort and should ideally never happen
        this.terminalLoading = false
        this.paymentInititateFailed = true
      }
    },
  }
}

</script>