<template>
  <div class="font-medium px-5 text-nw-base text-blue-500">
    <section 
      v-if="loading" 
      class="mt-10"
    >
      <div class="text-center text-blue-500">
        <h1 class="text-nw-3xl align-middle inline">
          {{ text("Loading ticket") }}
        </h1>
        <img 
          class="ml-5 inline" 
          src="../assets/loading.svg" 
        >
      </div>
    </section>
    <section v-else-if="order != null && order != ''">
      <h1 class="text-nw-xl mt-5 text-blue-norway">
        {{ text("Your ticket") }}
      </h1>

      <p class="text-nw-lg mt-5">
        {{ text("Common.reference") }}: {{ order.booking_number }}
      </p>

      <div 
        v-if="showHeading"
        class="mt-5 flex flex-row items-center"
      >
        <icon-check class="w-8" />
        <span class="ml-5">
          <span>{{ text('Receipt.emailnotification') }}</span> 
        </span>
      </div>

      <receipt-details 
        class="mt-10" 
        :order="order" 
      />

      <div 
        v-if="order.status == 'closed'"
        class="mt-5"
      >
        <div class="border border-blue-500 rounded-md w-full sm:w-72 p-3">
          <img 
            :src="apiUrl + '/barcode?width=400&reference=' + order.booking_number" 
            alt="strekkode" 
            class="mb-2 mx-auto"
          >
        </div>
      </div>
      <div 
        v-if="order.status === 'cancelled'" 
        class="bg-blue-400 mt-10 text-white text-center p-3"
      >
        {{ text("TicketRefunded") }}
      </div>


      <!-- Discount -->
      <div 
        v-if="order.discount_code" 
        class="mt-5"
      >
        <span>{{ text('Discount') }}: {{ order.discount_code }}</span>
      </div>

      <!-- Price specification -->
      <div class="w-full mt-5">
        <h2 class="text-nw-lg block text-blue-norway">
          {{ text('Ticket details') }}
        </h2>
        <table class="text-nw-base text-black w-full mt-1">
          <tbody class="">
            <tr 
              v-for="item in order.product_lines" 
              :key="item.productId"
            >
              <td class="text-left w-auto">
                {{ item.title }} x {{ item.quantity }}
              </td>
              <td class="text-right">
                {{ item.line_total }} kr
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-black -mr-5 -ml-5 mt-5 p-5 text-nw-base-plus bg-grey-300">
          <span>{{ text('Receipt.payed') }}:</span>
          <span class="float-right">{{ order.total }} kr</span>
        </div>
      </div>

      <div class="mt-5 text-center">
        <a 
          target="_blank" 
          :href="pdfUrl" 
          class="underline"
        >
          {{ text('Receipt.download') }}
        </a>
      </div>

      <div 
        v-if="order.note" 
        class="mt-10"
      >
        <h2 class="text-nw-lg mb-0">
          {{ text("Remember this") }}
        </h2>
        <ul class="text-nw-base text-black">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <li v-html="order.note" />
        </ul>
      </div>

      <div class="mt-10 text-black">
        <h2 class="text-nw-lg mb-0">
          {{ text('Receipt.questiontitle') }}
        </h2>
        <p class="text-nw-base mt-5">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="text('Contact our customer service by phone')" />
          <a class="underline whitespace-no-wrap">22 31 31 50</a> {{ text("or") }}
          <a class="underline whitespace-no-wrap">kundeservice@nor-way.no</a>.
        </p>
      </div>
    </section>
    <section 
      v-else
      class="mt-10" 
    >
      <error-message :text="text('Status: Ticket not found')" />
    </section>
  </div>
</template>

<script>

import api from '../api'
import { mapGetters } from 'vuex'
import ReceiptDetails from './../components/ReceiptDetails.vue'
import { DateMixin } from './../components/mixins/DateMixin'
import { orderFares } from '@/utilities/OrderUtility'
import ErrorMessage from '../components/ErrorMessage.vue'
import map from 'lodash/map'
import IconCheck from '@/components/IconCheck.vue'

export default {

  name: 'Receipt',

  components: {
    ReceiptDetails,
    ErrorMessage,
    IconCheck
  },

  mixins: [DateMixin],
  props: {
    reference:  {
      type: String,
      default: null
    },
    departure: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    showHeading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      passwordIsSet: false,
      password: '',
      emailExists: false,
      email: false,
      order: undefined,
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'text',
      'apiUrl'
    ]),
    orderFares () {
      const fares =  map(orderFares(this.order), fare => {
        return fare.quantity + ' ' + fare.title 
      })
      return fares.join(', ')
    },
    pdfUrl () {
      let param = encodeURIComponent(`?booking_number=${this.order.booking_number}&template=default`)
      return `${this.apiUrl}proxy?resource/orders/${this.order.id}/creation/ticket/pdf${param}`
    }
  },

  async created () {
    try {
      this.order = await api.GetOrderByBookingAndId(this.reference, this.id)
    } catch (e) {
      this.order = undefined
    }
    this.loading = false
    this.email = ''
    this.emailExists = true
  }
}
</script>
