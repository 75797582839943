export function createCalculateCheckoutObject (departureTrip, returnTrip, locale, customerId, discountCode) {
  let checkoutObject = {}
  checkoutObject.discount_code = discountCode
  checkoutObject.locale = locale
  if (customerId) {
    checkoutObject.customer_id = customerId
  }
  checkoutObject.legs = []
  checkoutObject.legs.push(departureTrip)
  if (returnTrip && Object.keys(returnTrip).length > 0) {
    checkoutObject.legs.push(returnTrip)
  }
  return checkoutObject
}

export function createCheckoutObject (shoppingCart, email, phone, customerId) {
  let checkoutObject = {
    test: process.env.VUE_APP_MODE_TEST,
    ...shoppingCart,
    customer_id: customerId,
    contact: {
      email: email,
      phone_number: phone
    },
    passengers: [
      {
        email: email,
        phone_number: phone
      }
    ]
  }

  // Update checkoutObject with payment_due + 1
  checkoutObject.payment_due += 100
  checkoutObject.total += 100
  checkoutObject.discount_total += 100
  console.log(checkoutObject)

  return checkoutObject
}

export function createOrderCheckoutObject (shoppingCart) {
  return {
    test: process.env.VUE_APP_MODE_TEST,
    ...shoppingCart
  }
}

export function createApplyDiscountObject (discountCode) {
  return {
    discount_code: discountCode
  }
}

export function createInitiatePaymentObject (callbackBaseUrl, token, paymentProvider) {
  let code = Math.floor(Math.random() * 9999999)

  if (paymentProvider === 'vipps') {
    return {
      redirect_url: `${callbackBaseUrl}?token=${token}&code=${code}`,
      code_challenge: code,
      gateway: 'vipps',
      locale: 'nb-NO',
      currency: 'NOK'
    }
  } else {
    return {
      redirect_url: `${callbackBaseUrl}?token=${token}&code=${code}`,
      code_challenge: code,
      gateway: 'nets',
      locale: 'nb-NO',
      currency: 'NOK'
    }
  }
}

export function createExpressCompleteObject (transactionId, code) {
  return {
    parent_transaction_id: transactionId,
    code_verifier: code
  }
}

export function createCallbackUrl (paymentProvider) {
  let callbackBaseUrl = ''
  if (paymentProvider === 'vipps') {
    callbackBaseUrl = location.protocol + '//' + location.host + location.pathname + '#/vipps-callback'
  } else {
    callbackBaseUrl = location.protocol + '//' + location.host + location.pathname + '#/nets-callback'
  }
  return callbackBaseUrl
}

export function createShoppingCartObject (departureTrip, returnTrip, locale, customerId, discountCode, transactions) {
  let shoppingCartObject = {}

  shoppingCartObject.persistent = true
  shoppingCartObject.discount_code = discountCode
  shoppingCartObject.locale = locale
  shoppingCartObject.roundtrip = false
  shoppingCartObject.transactions = []
  shoppingCartObject.legs = []

  if (customerId) {
    shoppingCartObject.customer_id = customerId
  }
  if (transactions) {
    shoppingCartObject.transactions = transactions
  }

  shoppingCartObject.legs.push(departureTrip)

  if (returnTrip && Object.keys(returnTrip).length > 0) {
    shoppingCartObject.legs.push(returnTrip)
    shoppingCartObject.roundtrip = true
  }
  return shoppingCartObject
}

export default {
  createCalculateCheckoutObject,
  createCheckoutObject,
  createOrderCheckoutObject,
  createApplyDiscountObject,
  createInitiatePaymentObject,
  createExpressCompleteObject,
  createCallbackUrl,
  createShoppingCartObject
}
