<template>
  <div class="p-5">
    <h1 
      class="text-nw-3xl text-blue-500"
    >
      {{ text('Import ticket heading') }}
    </h1>
    <hr class="mt-2 md:mt-4">

    <!-- eslint-disable-next-line vue/max-attributes-per-line, eslint-disable-next-line vue/no-v-html -->
    <p class="mt-5 text-nw-base" v-html="text('Import ticket main text')" />
    
    <h2 class="text-nw-base-plus mt-5">
      {{ text('Import ticket terms heading') }}
    </h2>
    <ul class="mt-2 text-nw-base list-inside">
      <li class="ml-4">
        - {{ text('Import ticket term one') }}
      </li>
      <li class="ml-4">
        - {{ text('Import ticket term two') }}
      </li>
    </ul>

    <n-input 
      id="booking_number" 
      v-model="bookingNumber" 
      class="mt-10"
      label="Skriv inn refereansenummer og importer billett" 
    />
    <n-button
      class="mt-5"
      :working="working"
      @click="addBooking"
    >
      {{ working ? text('Import ticket button in progress') : text('Import ticket button') }}
    </n-button>
    <error-message 
      v-if="error" 
      class="mt-5" 
      :text="text('Import failed')" 
    />
    <success-message 
      v-if="success" 
      class="mt-5" 
      :title="text('Import succeeded title')"
      :html="text('Import succeeded html')"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import NInput from '../components/forms/NInput.vue'
  import NButton from '../components/forms/NButton.vue'
  import ErrorMessage from '../components/ErrorMessage.vue'
  import SuccessMessage from '../components/SuccessMessage.vue'
  import { memberId } from '../utilities/UserUtility'
  import api from '../api'

  export default {
    name: 'ImportTicket',
    components: {
      NInput,
      NButton,
      ErrorMessage,
      SuccessMessage
    },
    data () {
      return {
        bookingNumber: '',
        working: false,
        error: false,
        success: false
      }
    },
    computed: {
      ...mapGetters(['text'])
    },
    methods: {
      async addBooking () {
        this.working = true
        this.success = false
        this.error = false
        try {
          const response = await api.AddBookingToAccount(this.bookingNumber)
          this.success = true
        } catch {
          this.error = true
        }
        this.working = false
      }
    },
  }
</script>
