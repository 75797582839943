<template>
  <div class="">
    <h1 class="text-nw-3xl text-blue-500">
      {{ text("Account") }}
    </h1>
    <div class="text-nw-base mt-5 text-blue-500 font-bold">
      {{ text("Login intro") }}
    </div>

    <error-message
      v-if="cookiesDisabled"
      class="mt-5"
      :text="text('Cookies blocked')"
    />
    <error-message
      v-if="error"
      id="logon-error"
      class="mt-5"
      :title="text('Logon failed title')"
      :text="text('Logon failed text')" 
    />
    <info-message
      v-if="message"
      class="mt-5"
      :text="message"
    />

    <form @submit.prevent="submit">
      <n-input
        id="email"
        v-model="email"
        autocomplete="email"
        :required="true"
        type="email"
        :label="text('Email')"
        class="mt-3"
      />
      <n-input 
        id="password"
        v-model="password"
        autocomplete="current-password"
        :required="true"
        type="password"
        :label="text('Password')"
        class="mt-3" 
      />

      <div class="mt-5 flex justify-between content-center flex-wrap">
        <n-button
          :working="working"
          class="mr-2" 
          @submit="submit"
        >
          {{ working ? text("Login in progress") : text("Login") }}
        </n-button>
        <n-checkbox 
          id="remember" 
          v-model="rememberMe" 
          class="my-auto" 
          :label="text('Stay logged on')" 
        />
      </div>
      <div class="mt-10 text-left text-nw-sm">
        <router-link
          class="text-blue-500 underline"
          to="/innlogging/registrer"
        >
          {{ text("Register 2") }}
        </router-link>
        <span class="ml-2 mr-2">
          {{ text("or") }} {{ text("have you forgotten your") }}
        </span>
        <router-link
          class="text-blue-500 underline"
          to="/innlogging/glemt-passord"
        >
          {{ text("password") }}
        </router-link>
        <span>?</span>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../api/index'
import { mapGetters } from 'vuex'
import VueScrollTo from 'vue-scrollto'
import get from 'lodash/get'
import { localStorageSet, localStorageGet } from '../utilities/LocalStorage'
import NInput from './forms/NInput.vue'
import NButton from './forms/NButton.vue'
import NCheckbox from './forms/NCheckbox.vue'
import ErrorMessage from './ErrorMessage.vue'
import InfoMessage from './InfoMessage.vue'
import Vue from 'vue'

export default {
  name: 'Logon',
  components: {
    NInput,
    NButton,
    NCheckbox,
    ErrorMessage,
    InfoMessage
  },
  data () {
    return {
      email: '',
      password: '',
      error: false,
      rememberMe: true,
      cookiesDisabled: false,
      message: undefined,
      working: false
    }
  },
  computed: {
    ...mapGetters(['text'])
  },
  created () {
    try {
      let isLoggedIn = localStorageGet('authtoken') !== null
      if (isLoggedIn) {
        this.$router.push({ name: 'account' })
      }
    } catch {}
    this.message = this.$router.currentRoute.query.message
    if (navigator.cookieEnabled) {
      this.cookiesDisabled = false
    } else {
      this.cookiesDisabled = true
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.scrollTo(0, 0)
    })
  },
  methods: {
    submit: async function () {
      this.working = true
      let expiry = null
      if (this.rememberMe) {
        expiry = new Date()
        expiry.setFullYear(expiry.getFullYear() + 1)
      }

      try {
        const authResult = await api.ProfileAuth(this.email, this.password, expiry)
        let authtoken = get(authResult, 'auth.token')
        let memberId = get(authResult, 'auth.data.userId')
        let mobile = get(authResult, 'auth.data.phone')
        let email = get(authResult, 'auth.data.email')
        localStorageSet('authtoken', authtoken)
        localStorageSet('memberId', memberId)
        localStorageSet('mobile', mobile)
        localStorageSet('email', email)
        this.$router.push({ name: 'account' })
      } catch (error) {
        this.error = true
        Vue.nextTick(function () {
          VueScrollTo.scrollTo('#logon-error', { force: false })
        })
      }
      this.working = false
    },
    toggleRememberMe: function () {
      this.rememberMe = !this.rememberMe
    }
  },
}
</script>
