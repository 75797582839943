import forEach from "lodash/forEach"
import map from 'lodash/map'
import get from 'lodash/get'
import first from 'lodash/first'
import last from 'lodash/last'

// PRIVATE HELPER METHODS
function _createItemIdFromSteps (steps) {
  return map(steps, r => {
    return `${get(r, 'transit.line.number', '')} ${get(r, 'transit.journey.number', '')}`
  }).join(' | ')
}

function _createItemNameFromSteps (steps) {
  return `${get(first(steps), 'transit.departure_stop.name')} - ${get(last(steps), 'transit.arrival_stop.name')}`
}

function _createItemBrandFromSteps (steps) {
  return map(steps, r => get(r, 'transit.line.short_name', '')).join(' | ')
}

// PRIVATE DATA TRANSFORM METHODS
function _traveldetailsToGaEntity(originName, destinationName, fares, discountCode, departureDate, returnDate) {
  let items = []
  forEach(fares, (fare, index) => {
    items.push(
      {
        item_name: `${originName} - ${destinationName}`,
        index: index,
        item_category: returnDate ? 'return' : 'oneway',
        item_variant: fare.name,
        quantity: fare.count,
        item_promotion_code: discountCode,
        item_dd: departureDate,
        item_rd: returnDate
      }
    )
  })
  return items
}

function _tripDetailsToGaEntity(trip, roundtrip, index, inbound, discountCode) {
  const item = {
    item_id:  _createItemIdFromSteps(trip.steps),
    item_name: _createItemNameFromSteps(trip.steps),
    index: index,
    item_brand: _createItemBrandFromSteps(trip.steps),
    item_category: roundtrip ? 'return' : 'oneway',
    item_category1: get(trip, 'steps', []).length > 1 ? 'transit' : 'direct',
    item_category2: inbound ? 'inbound' : 'outbound',
    item_promotion_code: discountCode,
  }
  return item
}

function _tripsToGaEntity(trips, roundtrip, inbound, discountCode) {
  let items = []
  let index = 0
  forEach(trips, trip => {
    items.push(_tripDetailsToGaEntity(trip, roundtrip, index, inbound, discountCode))
    index++
  })
  return items
}

function _cartToGaEntity(cart) {
  var items = []
  let index = 0
  forEach(cart.legs, (leg) => {
    forEach(leg.product_lines, product_line => {
      items.push({
        item_id: _createItemIdFromSteps(leg.steps),
        item_name: _createItemNameFromSteps(leg.steps),
        index: index,
        item_brand: _createItemBrandFromSteps(leg.steps),
        item_category: cart.legs.length > 1 ? 'return' : 'oneway',
        item_category1: leg.steps.length > 1 ? 'transit' : 'direct',
        item_category2: product_line.additional ? 'addons' : '',
        item_variant: product_line.title,
        price: product_line.line_total,
        quantity: product_line.quantity
      })
      index++
    })
    index++
  })
  return items
}

function _productToGaEntity(product) {
  const item = {
    item_id: product.product_id,
    item_name: product.title,
    price: product.total,
    quantity: product.quantity,
    item_category2: 'addons'
  }
  return item
}

function _paymentInfoToGaEntity(cart) {
  var items = []
  forEach(cart.legs, (leg, legIndex) => {
    forEach(leg.product_lines, product_line => {
      items.push({
        item_id: _createItemIdFromSteps(leg.steps),
        item_name: _createItemNameFromSteps(leg.steps),
        index: legIndex,
        item_brand: _createItemBrandFromSteps(leg.steps),
        item_category: cart.legs.length > 1 ? 'return' : 'oneway',
        item_category1: leg.steps.length > 1 ? 'transit' : 'direct',
        item_variant: product_line.title,
        price: product_line.line_total,
        quantity: product_line.quantity,
      })
    })
  })
  return items
}

function _purchaseToGaEntity(order) {
  let items = []
  let index = 0
  forEach(order.legs, (leg, legIndex) => {
    forEach(leg.product_lines, product_line => {
      items.push({
        item_id: _createItemIdFromSteps(leg.steps),
        item_name: _createItemNameFromSteps(leg.steps),
        index: index,
        item_brand: _createItemBrandFromSteps(leg.steps),
        item_category: order.legs.length > 1 ? 'return' : 'oneway',
        item_category1: leg.steps.length > 1 ? 'transit' : 'direct',
        item_category2: product_line.additional ? 'addons' : '',
        item_variant: product_line.title,
        price: product_line.line_total,
        quantity: product_line.quantity
      })
      index++
    })
    index++
  })
  return items
}



// PRIVATE GA WRAPPERS
export function _pushToDataLayer (eventName, items) {
  window.dataLayer.push({ ecommerce: null }) // Clear
  let event = {
    event: eventName,
    ecommerce:{
      items: (Array.isArray(items) ? items : ([items]))
    }
  }
  window.dataLayer.push(event)
}

export function _pushPaymentInfoToDataLayer(eventName, items, paymentType, currency, value, transactionId) {
  window.dataLayer.push({ ecommerce: null }) // Clear
  let event = {
    event: eventName,
    ecommerce:{
      transaction_id: transactionId,
      payment_type: paymentType,
      currency: currency,
      value: value,
      items: (Array.isArray(items) ? items : ([items]))
    }
  }
  window.dataLayer.push(event)
}

export function _pushPurchaseToDataLayer(eventName, items, transactionId, paymentType, currency, value) {
  window.dataLayer.push({ ecommerce: null }) // Clear
  let event = {
    event: eventName,
    ecommerce: {
      transaction_id: transactionId,
      value: value,
      currency: currency,
      payment_type: paymentType,
      items: (Array.isArray(items) ? items : ([items]))
    }
  }
  window.dataLayer.push(event)
}


// PUBLIC METHODS

// 1
// This method gathers all the initial data the the user provided to do their trip search
export function logSelectItem (originName, destinationName, fares, discountCode, departureDate, returnDate) {
  const eventName = "select_item"
  try {
    const items = _traveldetailsToGaEntity(originName, destinationName, fares, discountCode, departureDate, returnDate)
    _pushToDataLayer(eventName, items)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

// 2
// This method logs the number of trips we ended up displaying to the user
export function logViewItemList(trips, twoway, inbound, discountCode) {
  console.log(trips)
  const eventName = 'view_item_list';
  try {
    const items = _tripsToGaEntity(trips, twoway, inbound, discountCode)
    _pushToDataLayer(eventName, items)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

// 2.5
// This methods logs details about the trip when the user decides to view details on 
//  the listing page
export function logViewItem(trip, roundtrip, index, inbound, discountCode) {
  const eventName = 'view_item'
  try {
    const item = _tripDetailsToGaEntity(trip, roundtrip, index, inbound, discountCode)
    _pushToDataLayer(eventName, item)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

// 3
export function logAddToCart(cart) {
  const eventName = 'add_to_cart'
  try {
    const items = _cartToGaEntity(cart)
    _pushToDataLayer(eventName, items)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

// 3.5
export function logDeleteCart(shoppingCartId) {
  const eventName = 'delete_cart'
  try {
    _pushToDataLayer(eventName, { id: shoppingCartId })
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

// 4
export function logAddShoppingCartItem(product) {
  const eventName = 'add_additional_item'
  try {
    const item = _productToGaEntity(product)
    _pushToDataLayer(eventName, item)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

// 4.5
export function logRemoveShoppingCartItem(product) {
  const eventName = 'remove_item'
  try {
    const item = _productToGaEntity(product)
    _pushToDataLayer(eventName, item)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

// 5
export function logBeginCheckout(cart) {
  const eventName = 'begin_checkout'
  try {
    const items = _cartToGaEntity(cart)
    _pushToDataLayer(eventName, items)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

export function logAddPaymentInfo(cart, paymentType, transactionId) {
  const eventName = "add_payment_info"
  try {
    const items = _paymentInfoToGaEntity(cart)
    _pushPaymentInfoToDataLayer(eventName, items, paymentType, 'NOK', cart.payment_due, transactionId)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

export function logPurchase(order, paymentType) {
  const eventName = "purchase"
  try {
    const items = _purchaseToGaEntity(order)
    _pushPurchaseToDataLayer(eventName, items, order.id, paymentType, order.currency, order.paid_total)
  } catch (e) {
    console.log(`Could not log ${eventName} error below`)
    console.log(e)
  }
}

export default {
  logBeginCheckout,
  logSelectItem, 
  logViewItemList, 
  logViewItem, 
  logAddToCart, 
  logAddShoppingCartItem,
  logDeleteCart,
  logRemoveShoppingCartItem,
  logAddPaymentInfo, 
  logPurchase 
}