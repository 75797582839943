<template>
  <div>
    <div 
      v-for="(leg, index) in order.legs" 
      :key="index" 
      class="mt-5 p-3 border border-blue-500 rounded shadow-md text-nw-base"
    >
      <div class="text-nw-base leading-6 text-blue-norway">
        {{ legDepartureName(leg) + ' - ' + legArrivalName(leg) }}
      </div>
      <div class="mt-2 text-black text-nw-base leading-6">
        {{ toTravelDate(lang, legDepartureDate(leg)) + ', ' + toTime(lang, legDepartureTime(leg)) + ' - ' + toTime(lang, legArrivalTime(leg)) }}
      </div>

      <div v-if="leg.steps.length > 1">
        <div
          v-for="(step, j) in leg.steps"
          :key="j"
          class=" text-grey-font leading-4 mt-2 text-nw-xs"
        >
          <div 
            v-if="step.travel_mode === 'transit'" 
            class=""
          >
            <span>{{ toTime(lang, stepDepartureTime(step)) + ' - ' + toTime(lang, stepArrivalTime(step)) + ' ' + stepDepartureName(step) + ' - ' + stepArrivalName(step) }}</span>
          </div>
        </div>
      </div>
      <div>
        <ul
          class="mt-5"
        >
          <li
            v-for="(name, j) in legShortNames(leg)"
            :key="j"
            class="bg-grey-400 text-black w-auto inline mr-2 py-1 px-4 text-nw-sm rounded-full"
          >
            {{ name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateMixin } from '../components/mixins/DateMixin'
import map from 'lodash/map'
import { 
  tripDepartureDate, 
  legDepartureName, 
  legArrivalName, 
  legDepartureDate, 
  legDepartureTime, 
  legArrivalTime,
  stepDepartureTime,
  stepArrivalTime,
  stepDepartureName,
  stepArrivalName,
  legShortNames
} from '../utilities/TripUtility'
export default {
  name: 'ReceiptDetails',
  mixins: [DateMixin],
  props: {
    order: {
      type: Object,
      default: () => { return null }
    }
  },
  computed: {
    ...mapGetters(['text', 'lang'])
  },
  methods: {
    tripDepartureDate,
    legDepartureName,
    legArrivalName,
    legDepartureDate,
    legDepartureTime,
    legArrivalTime,
    stepDepartureTime,
    stepArrivalTime,
    stepDepartureName,
    stepArrivalName,
    legShortNames,
    map,
    toggleMap (input) {
      if (input.hasOwnProperty('showMapOrigin')) {
        input.showMapOrigin = !input.showMapOrigin
      } else {
        this.$set(input, 'showMapOrigin', true)
      }
    }
  }
}
</script>

<style scoped>
  .map {
    width: 100%;
    height: 320px;
    border: 3px solid #D2D2D2;
    background: #D2D2D2;
    margin-bottom: -6px;
  }

  .map-wrap {
    background: #fff;
    position: relative;
    z-index: 1;
    margin-top: 12px;
    margin-bottom: 12px;
  }

</style>
