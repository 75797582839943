import { LocalDateTime } from './../entities/LocalDateTime'

export const DateMixin = {
  methods: {
    toDate (input) {
      return new LocalDateTime(input).originalDate
    },
    nextDay (input) {
      let d = new LocalDateTime(input)
      return d.nextDay()
    },
    previousDay (input) {
      let d = new LocalDateTime(input)
      return d.previousDay()
    },
    toTime (lang, date) {
      if (date != null) {
        let time = new LocalDateTime(date)
        return time.toTime(lang)
      } else {
        return ''
      }
    },
    toDuration (lang, departure_, arrival_) {
      let departure = new LocalDateTime(departure_)
      let arrival = new LocalDateTime(arrival_)
      let _MS_PER_MINUTE = 1000 * 60
      const utc1 = departure.asUtc()
      const utc2 = arrival.asUtc()
      let durationTotalMinutes = Math.floor((utc2 - utc1) / _MS_PER_MINUTE)

      let durationHours = Math.floor(durationTotalMinutes / 60)
      let durationMinutes = durationTotalMinutes % 60

      let result = ''
      if (lang === 'nb') {
        result += durationHours > 0 ? durationHours + 't ' : ''
        result += durationMinutes > 0 ? durationMinutes + 'min' : ''
      } else {
        result += durationHours > 0 ? durationHours + 'h ' : ''
        result += durationMinutes > 0 ? durationMinutes + 'min' : ''
      }
      return result
    },
    toTravelDateTime (lang, departure_, arrival_) {
      let departure = new LocalDateTime(departure_)
      let arrival = new LocalDateTime(arrival_)
      let result = ''
      result += departure.format(lang, 'ddd D. MMM')
      result += ', ' + departure.toTime(lang) + ' - ' + arrival.toTime(lang)
      return result
    },
    toDateTimeWithDay (lang, departure_) {
      let departure = new LocalDateTime(departure_)
      return departure.format(lang, 'LLLL')
    },
    toTravelDate (lang, date_) {
      let date = new LocalDateTime(date_)
      return date.format(lang, 'dddd DD. MMMM')
    },
    toTravelDateShort (lang, departure_) {
      let departure = new LocalDateTime(departure_)
      return departure.format(lang, 'ddd D. MMM')
    },
    // Input is expected to be on the format hour:minute:seconds
    toDurationText (lang, input) {
      try {
        let inputs = input.split(':')
        let hours = input[0]
        let minutes = input[1]
        let seconds = input[2]
        let totalSeconds = (hours * 3600) + (minutes * 60) + seconds

        let EXACT_CUTOFF = 5
        let APPROX_CUTOFF = 15
        var t_ca = 'ca. '
        var t_under = lang.toLowerCase() === 'en-us' || lang.toLowerCase() === 'en' ? 'under ' : 'mindre enn '
        var t_hour = lang.toLowerCase() === 'en-us' || lang.toLowerCase() === 'en'
          ? (hours > 1 ? ' hours' : ' hour')
          : (hours > 1 ? ' timer' : ' time')
        var t_min = ' min'
        var t_sec = lang.toLowerCase() == 'en-us' || lang.toLowerCase() === 'en' ? ' sec' : ' sek'

        if (totalSeconds < 45) {
          return t_under + ' 1 ' + t_min
        } else {
          var exact = seconds <= EXACT_CUTOFF || seconds >= 60 - EXACT_CUTOFF
          var ca = seconds <= APPROX_CUTOFF || seconds >= 60 - APPROX_CUTOFF
          var between = seconds >= APPROX_CUTOFF && seconds <= 60 - APPROX_CUTOFF
          if (hours > 0) {
            return hours + t_hour + ', ' + minutes + ' ' + t_min
          } else if (minutes > 0 || seconds >= 60 - EXACT_CUTOFF) {
            var min = seconds >= 60 - APPROX_CUTOFF ? minutes + 1 : minutes
            if (exact) {
              return min + t_min
            } else if (ca) {
              return t_ca + min + t_min
            } else {
              return minutes + '-' + (minutes + 1) + t_min
            }
          } else if (seconds > 0) {
            return t_under + '1' + t_min
          }
        }
      } catch (ex) {
      }
      return ''
    }

  }
}
