<template>
  <div class="p-5">
    <h1 class="text-blue-500 text-nw-3xl">
      {{ text("reset password (page title)") }}
    </h1>

    <error-message
      v-if="errorMessage"
      class="mt-5"
      :text="errorMessage" 
    />
    <error-message 
      v-if="errors.length > 0"
      class="mt-5"
      :list="errors"
    />

    <form @submit.prevent="submit">
      <n-input
        id="password"
        v-model="password" 
        type="password"
        :label="text('NewPassword')" 
        class="mt-5" 
        @input="errors = []" 
      />
      <n-input
        id="confirmPassword"
        v-model="passwordConfirm"
        type="password"
        :label="text('Confirm password')"
        class="mt-3" 
        @input="errors = []"
      />

      <div class="flex flex-wrap mt-5 content-center justify-between">
        <n-button
          class="mx-auto xs:mx-0"
          :working="working"
          @submit="submit"
        >
          {{ working ? text('Saving') : text('Save') }}
        </n-button>

        <div class="xs:flex xs:w-auto xs:my-auto text-center mt-5 w-full px-auto text-nw-base">
          <span>
            {{ text("or") }}
          </span>
          <router-link 
            class="ml-3 text-blue-500 underline"
            to="/innlogging"
          >
            {{ text("login") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../api'
import { mapGetters } from 'vuex'
import get from 'lodash/get'
import ErrorMessage from '../components/ErrorMessage.vue'
import NButton from '@/components/forms/NButton.vue'
import NInput from '../components/forms/NInput.vue'
import { setAuthenticated } from '@/utilities/UserUtility'

export default {
  name: 'ResetPassword',
  components: {
    NButton,
    NInput,
    ErrorMessage
  },
  data () {
    return {
      code: null,
      email: null,
      password: '',
      passwordConfirm: '',
      errorMessage: null,
      success: false,
      errors: [],
      working: false,
    }
  },
  computed: {
    ...mapGetters([
      'text',
      'shoppingCart'
    ])
  },
  mounted () {
    const { code, email } = this.$router.currentRoute.query
    this.code = code
    this.email = email
  },
  methods: {
    async submit () {
      this.working = true
      this.errors = []
      if (this.password === '') {
        this.errors.push(this.text('New password is missing'))
      }
      if (this.password !== this.passwordConfirm) {
        this.errors.push(this.text('Password and confirm password are not the same'))
      }
      if (this.password.length < 6) {
        this.errors.push(this.text('New password too short'))
      }
      if (this.errors.length > 0) {
        this.working = false
        return
      }
      const data = await api.UpdatePassword(this.email, this.password, this.code)
      if (data.httpStatus !== 200) {
        this.errorMessage = get(data, 'error.message')
      } else {
        setAuthenticated(
          get(data, 'auth.token'), 
          get(data, 'auth.data.userId'), 
          get(data, 'auth.data.email'),
          get(data, 'auth.data.phone')
        )
        // if there is a shoppingcart here, redirect
        if (this.shoppingCart) {
          this.$router.push( { name: 'betale', query: { shoppingCart: this.shoppingCart.id } })
        } else {
          this.$router.push({ name: 'account' })
        }
      }
      this.working = false
    }
  }
}
</script>
