<template>
  <div class="">
    <div class="w-full text-nw-base relative">
      <input 
        v-model="content" 
        :class="classes" 
        type="text"  
        :placeholder="text('Travelinfo.discount')" 
      >
      <a 
        v-if="content.length > 0"
        title="clear" 
        class="absolute right-3 top-3 z-10 cursor-pointer" 
        @click.prevent="content = ''" 
      >
        <icon-remove class="h-6 w-6" />
      </a>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import debounce from 'lodash/debounce'
  import IconRemove from '@/components/IconRemove.vue'
  export default {
    name: 'DiscountCode',
    components: {
      IconRemove
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      position: {
        type: String,
        default: 'left'
      }
    },
    data () {
      return {
        content: this.value,
      }
    },
    computed: {
      ...mapGetters([
        'text'
      ]),
      classes () {
        const classes = [this.content.length == 0 ? 'show-icon' : 'hide-icon'] 
        classes.push(this.position === 'left' ? 'left' : 'right')
        return classes.join(' ')
      }
    },
    watch: {
      content: debounce(function () {
        this.$emit('input', this.content)
      }, 500)
    }
  }
</script>

<style scoped>
  input {
    @apply
    border
    border-blue-norway 
    leading-none 
    w-full 
    h-12 
    pr-3
    pl-12
    outline-none
    text-color-input-active 
    text-nw-base
    }

  input.show-icon {
    @apply 
    bg-plus
    bg-no-repeat
    bg-left
    text-nw-base;
    background-position-x: 16px;
  }

  input.hide-icon {
    @apply
    pl-3
  }

  input::placeholder {
    @apply text-color-input-placeholder
  }

  input:focus {
    @apply border-2 border-yellow-500
  }
</style>