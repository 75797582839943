<template>
  <div class="border-4 border-green-500 bg-green-500 bg-opacity-25 p-3">
    <h2
      v-if="title"
      class="text-nw-base-plus leading-5 text-black"
    >
      {{ title }}
    </h2>
    <p
      v-if="text"
      class="mb-0 text-nw-base text-black"
      :class="title ? 'mt-2' : ''"
    >
      {{ text }}
    </p>
    <p
      v-if="html"
      class="mb-0 text-nw-base text-black"
      :class="title || text ? 'mt-2' : ''"
      v-html="html" 
    />
    <ul v-if="list.length > 0">
      <li 
        v-for="(t, index) in list"
        :key="index"
      >
        {{ t }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SuccessMessage',
  props: {
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    },
    html: {
      type: String,
      default: undefined
    },
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>
