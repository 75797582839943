<template>
  <div>
    <existing-booking-accordion 
      class="mx-5" 
      :open="true" 
    />
    <div class="pt-3 mt-5 p-5">
      <h2 class="text-nw-xl mt-5 text-blue-norway">
        {{ existingBooking ? text('Travelinfo.titleedit') : text('Travelinfo.title') }}
      </h2>
      <div class="relative flex flex-wrap mt-8 md:flex-nowrap items-center">
        <div class="w-full text-nw-base md:w-6/12">
          <stop-search
            :valid="!origin && showWarning ? false : true"
            component-id="departureSearch"
            component-prefix="departure_"
            :current-location-name="get(origin, 'name')"
            :label="text('Travelinfo.travelfrom')"
            @selectStop="setOrigin"
          />
        </div>
        <div 
          style="z-index: 5;" 
          class="absolute md:static top-50px md:top-4 right-3 md:right-auto md:ml-5"
        >
          <button 
            slot="flip-travel"
            :aria-label="text('travelinfo.flipdirection')"
            class="bg-blue-norway block hover:bg-blue-hover focus:outline outline-yellow-500 w-9 h-9 p-1"
            @click.prevent="flipTravel"
          >
            <icon-flip 
              class="h-7 w-7 transform md:rotate-90"
              :alt="text('travelinfo.flipdirection')"
            />
          </button>
        </div>
        <div class="w-full md:w-6/12 md:ml-4 mt-4 md:mt-0"> 
          <stop-search
            :valid="!destination && showWarning ? false : true"
            :classes="destination !== undefined ? 'valid' : 'invalid'"
            :current-location-name="get(destination, 'name')"
            :label="text('Travelinfo.travelto')"
            component-id="arrivalSearch"
            component-prefix="arrival"
            @selectStop="setDestination"
          />
        </div>
      </div>

      <div class="mt-4 grid gap-2 grid-cols-2">
        <div>
          <date-picker
            v-model="departureDate"
            :valid="!departureDate && showWarning ? false : true"
            :clearable="false"
            position="left" 
            :icon="true"
            :placeholder="text('Travelinfo.departure')"
            :options="departureOptions"
          />
        </div>
        <div>
          <date-picker
            v-model="returnDate"
            position="right"
            :clearable="true"
            :dual-icon="true"
            :placeholder="text('travelinfo.return')"
            :options="returnOptions"
          />
        </div>
        <div>
          <fare-picker 
            :valid="selectedFares.length === 0 && showWarning ? false : true" 
            position="left"
          />
        </div>
        <div>
          <discount-code
            v-model="discountCode" 
            position="right"
          />
        </div> 
      </div>

      <div class="flex mt-8 items-center">
        <n-button 
          :soft-disabled="!origin || !destination" 
          @click="next()"
        >
          {{ text('Travelinfo.button') }}
        </n-button>
        <span 
          v-if="showWarning && (!origin || !destination)"
          class="ml-4 h-4 text-red-500"
        >
          {{ text('Travelinfo.error') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from '../components/DatePicker.vue'
import DiscountCode from '../components/DiscountCode.vue'
import ExistingBookingAccordion from '../components/ExistingBookingAccordion.vue'
import FarePicker from '../components/FarePicker.vue'
import IconFlip from '../components/IconFlip.vue'
import NButton from '../components/forms/NButton.vue'
import StopSearch from '../components/StopSearch.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { Norwegian } from 'flatpickr/dist/l10n/no'
import { english } from 'flatpickr/dist/l10n/default'
import { DateMixin } from '../components/mixins/DateMixin'
import filter from 'lodash/filter'
import get from 'lodash/get'
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'BookingTravelInfo',
  components: {
    DatePicker,
    DiscountCode,
    ExistingBookingAccordion,
    IconFlip,
    FarePicker,
    NButton,
    StopSearch
  },
  mixins: [DateMixin],
  data () {
    return {
      departureOptions: {},
      time: new Date().getHours(),
      showWarning: false
    }
  },
  computed: {
    ...mapState (['query']),
    ...mapGetters([
      'originId',
      'originName',
      'step',
      'previousOrigins',
      'text',
      'destinationId',
      'destinationName',
      'previousDestinations',
      'totalCount',
      'fares',
      'dateOnServer',
      'returnDate',
      'departureDate',
      'lang',
      'existingBooking'
    ]),
    canSubmit () {
      return this.origin && this.destination && this.departureDate
    },
    ready () {
      if (this.returnDate && this.returnDate < this.departureDate) {
        return false
      } else if (this.originId == null || this.destinationId == null) {
        return false
      } else if (this.totalCount <= 0) {
        return false
      } else {
        return true
      }
    },
    selectedFares () {
      return filter(this.fares, r => r.count > 0)
    },
    origin: {
      get () {
        return this.$store.getters.origin
      },
      set (value) {
        this.$store.commit('setOrigin', value)
      }
    },

    destination: {
      get () {
        return this.$store.getters.destination
      },
      set (value) {
        this.$store.commit('setDestination', value)
      }
    },

    returnOptions: {
      get () {
        return {
          altFormat: 'd. M',
          dateFormat: 'Y-m-d',
          disableMobile: true,
          altInput: true,
          locale: this.lang === 'en' ? english : Norwegian,
          minDate: this.departureDate,
        }
      }
    },

    selectionText: {
      get () {
        let text = filter(this.fares, o => o.count > 0)
          .map(o => o.count + ' ' + o.name.toLowerCase())
          .join(', ')
        return text === '' ? this.text('Select ticket type') : text
      }
    },

    discountCode: {
      get () {
        return this.$store.getters.discountCode
      },
      set (value) {
        return this.$store.commit('setDiscountCode', value)
      }
    },

    departureDate: {
      get () {
        return this.$store.getters.departureDate
      },
      set (value) {
        this.$store.commit('setDepartureDate', value)
      }
    },

    returnDate: {
      get () {
        return this.$store.getters.returnDate
      },
      set (value) {
        this.$store.commit('setReturnDate', value)
      }
    },
  },
  watch: {
    '$route': {
      handler: function (val, old) {
        if (val) {
          this.$store.dispatch('query', val.query)
        }
      },
      immediate: true
    }
  },
  mounted () {
    // Make sure that to clear any shoppingCarts on this step
    this.$store.commit('setShoppingCart', undefined);
    this.$scrollTo('#app')
  },
  created () {
    this.departureOptions = {
      altFormat: 'd. M',
      dateFormat: 'Y-m-d',
      disableMobile: true,
      altInput: true,
      locale: this.lang === 'en' ? english : Norwegian,
      minDate: this.$store.getters.dateOnServer
    }
  },
  methods: {
    get,
    ...mapMutations([
      'setOrigin',
      'setDestination'
    ]),

    setOrigin (location) {
      this.$store.commit('setOrigin', location)
    },

    setDestination (location) {
      this.$store.commit('setDestination', location)
    },
    flipTravel () {
      setTimeout(() => {
        this.$store.dispatch('flipTravel', false)
      }, 300)
    },
    async next () {
      if (this.canSubmit) {
        this.$router.push({ name: 'utreise', query: { ...this.query } })
      } else {
        this.showWarning = true
      } 
    }
  }
}
</script>
