// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icon-bus.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bus-graphic-bottom[data-v-bc4c6a9c]:after,.bus-graphic-top[data-v-bc4c6a9c]:after{content:\"\";position:absolute;top:4px;left:65px;background-size:24px auto;background-repeat:no-repeat;background-color:#fff;width:24px;height:36px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.bus-graphic-top[data-v-bc4c6a9c]:before{top:32px;height:100%}.bus-graphic-bottom[data-v-bc4c6a9c]:before,.bus-graphic-top[data-v-bc4c6a9c]:before{content:\"\";position:absolute;bottom:0;left:75px;border-left-width:3px;--tw-border-opacity:1;border-color:rgb(210 210 210/var(--tw-border-opacity))}.bus-graphic-bottom[data-v-bc4c6a9c]:before{top:-48px;height:40px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
