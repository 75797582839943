<template>
  <div 
    v-if="order" 
    class="border border-blue-norway bg-white p-4"
  >
    <a 
      :title="expanded ? 'close' : 'open'"
      class="flex cursor-pointer flex-row w-full items-center justify-between"
      @click.prevent="toggle"
    >
      <h2 class="text-blue-norway grow text-nw-base-plus leading-6">
        {{ text("Your ordered journey") }}
      </h2>
      <icon-arrow 
        :class="expanded ? 'rotate-180' : ''"
      />
    </a>

    <div 
      v-if="expanded" 
      class="flex mt-2"
    >
      <div class="flex flex-wrap flex-grow">
        <div class="w-full text-black">
          <h3 class="text-nw-sm leading-5">
            {{ formatDate(orderOrigin(order).local_depart_at) }}
          </h3>
          <!-- Departure -->
          <div class="text-nw-sm leading-5">
            {{ orderOrigin(order).departure_stop.name }} - {{ orderDestination(order).arrival_stop.name }}
          </div>
          
          <!-- Return -->
          <div 
            v-if="orderHasReturn(order)" 
            class="mt-2"
          >
            <div class="text-nw-sm leading-5">
              {{ orderReturnOrigin(order).departure_stop.name }} - {{ orderReturnDestination(order).arrival_stop.name }}
            </div>
            <h5 class="text-nw-sm leading-5">
              {{ formatDate(orderReturnOrigin(order).local_depart_at) }}
            </h5>
          </div>
          <div class="mt-2 text-nw-xs">
            {{ order.product_lines.map(r => { return r.quantity + ' ' + r.title }).join(', ') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateMixin } from './mixins/DateMixin'
import IconArrow from '@/components/icons/IconArrow'
import { orderOrigin, orderDestination, orderHasReturn, orderReturnOrigin, orderReturnDestination } from '../utilities/OrderUtility'
export default {
  name: 'ExistingBookingAccordion',
  components: {
    IconArrow
  },
  mixins: [DateMixin],
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: this.open
    }
  },
  computed: {
    ...mapGetters(['lang', 'text', 'existingBooking']),
    order () {
      return this.existingBooking
    }
  },
  methods: {
    orderOrigin,
    orderDestination,
    orderHasReturn,
    orderReturnOrigin,
    orderReturnDestination,
    getValue (input, path) {
      return get(input, path, '-')
    },
    formatDate (value) {
      return (
        this.toTravelDate(this.lang, value) +
        ', ' +
        this.text('kl') +
        '. ' +
        this.toTime(this.lang, value)
      )
    },
    departAt (input, path) {
      const value = get(input, path, '-')
      if (value === '-') {
        return value
      }
    },
    toggle () {
      this.expanded = !this.expanded
    }
  }
}

</script>