<template>
  <div class="outline-none relative flex items-center">
    <vue-autosuggest
      ref="autosuggest"
      v-model="query"
      class="grow leading-none text-nw-base"
      :class="valid ? 'valid' : 'invalid'"
      :component-attr-id-autosuggest="componentId"
      :suggestions="suggestions"
      :get-suggestion-value="getSuggestionValue"
      :input-props="inputProps"
      :should-render-suggestions="shouldRenderSuggestions"
      @input="fetchResults"
      @selected="onSelected"
      @item-changed="onItemChanged"
      @keydown.tab="tabHandler"
      @keydown.enter="enterHandler"
    >
      <template slot-scope="{suggestion}">
        <div class="flex">
          <span class="flex-grow">{{ getSuggestionName(suggestion) }}</span>
        </div>
      </template>
    </vue-autosuggest>
    <a 
      v-if="query.length > 0"
      title="Clear input" 
      class="absolute right-3 z-10 cursor-pointer" 
      @click.prevent="clearInput" 
    >
      <icon-remove class="h-6 w-6" />
    </a>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { mapGetters } from 'vuex'
import IconRemove from '@/components/IconRemove.vue'
import api from '../api'
import debounce from 'lodash/debounce'
export default {
  name: 'StopSearch',
  components: {
    VueAutosuggest,
    IconRemove
  },
  props: {
    label: undefined,
    currentLocationName: undefined,
    componentId: undefined,
    valid: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      timeout: null,
      debounceMilliseconds: 250,
      suggestions: [],
      query: this.currentLocationName || '',
      selected: null,
      sectionConfig: {
        default: {
          name: 'blabla',
          limit: 4
        }
      },
      currentItem: undefined
    }
  },
  computed: {
    ...mapGetters(['text']),
    inputProps () {
      return {
        id: 'autosuggest__input',
        placeholder: this.label
      }
    },
    getSuggestions () {
      if (this.suggestions && this.suggestions.length > 0) {
        return this.suggestions[0].data
      }
      return []
    }
  },
  
  watch: {
    currentLocationName (value) {
      this.query = value || ''
    },
    query (value) {
      if (!value || value.length === 0)
      this.$emit('selectStop', undefined)
    }
  },
  methods: {
    clearInput () {
      this.query = ''
      this.$emit('selectStop', undefined)
    },
    shouldRenderSuggestions (size, loading) {
      // This is the default behavior
      return size >= 0 && !loading
    },
    onBlur () {
      this.selected = this.getSuggestions[0]
      this.query = this.formatSuggestionName(this.selected)
      this.suggestions = []
      this.$emit('selectStop', this.selected)
    },
    tabHandler () {
      const { listeners, setChangeItem, getItemByIndex } = this.$refs.autosuggest
      let item = getItemByIndex(this.$refs.autosuggest.currentIndex)
      if (item) {
        listeners.selected(true)
        this.$refs.autosuggest.loading = true
      } else {
        item = getItemByIndex(0)
        setChangeItem(item.item, true)
        this.$refs.autosuggest.loading = true
        listeners.selected(true)
      }
    },
    onSelected (suggestion) {
      let item = suggestion
      if (!item) {
        const { getItemByIndex } = this.$refs.autosuggest
        item = getItemByIndex(0)
      }
      if (item) {
        if (this.selected && item && this.selected.id === item.item.id) {
          this.selected = item.item
          this.$emit('selectStop', null) // Hack to prevent vue-autosuggest from acting funky
          this.$nextTick(() => {
            this.$emit('selectStop', item.item)
            this.$scrollTo('#app')
          })
        } else {
          this.selected = item.item
          this.$emit('selectStop', null) // Hack to prevent vue-autosuggest from acting funky
          this.$nextTick(() => {
            this.$emit('selectStop', item.item)
            this.$scrollTo('#app')
          })
        }
      }
    },
    onItemChanged (item) {
      if (item) {
        this.$emit('selectStop', item.item)
      }
    },
    getSuggestionName (suggestion) {
      return this.formatSuggestionName(suggestion.item)
    },
    formatSuggestionName (option) {
      let result = option.name
      if (option.municipality) {
        result += ', ' + option.municipality.name
      }
      if (option.county) {
        result += ', ' + option.county.name
      }
      return result
    },
    getSuggestionValue (suggestion) {
      if (suggestion) {
        return suggestion.name
      } else {
        return 'nothing'
      }
    },
    fetchResults: debounce(function () {
      if (this.query.length < 2) {
        this.suggestions = [{ data: [] }]
        return
      }
      const query = this.query
      api.StopSearch(query).then(locations => {
        this.suggestions = [{ data: locations }]
      })
    }, 300)
  }
}
</script>

<style lang="css">
#autosuggest__input {
  @apply 
  w-full 
  border
  border-blue-norway 
  pr-8
  pl-12
  h-15
  font-normal
  text-color-input-active
  bg-search
  bg-no-repeat
  bg-left
  text-nw-base;
  background-position-x: 16px;
}

#autosuggest__input::after {
  @apply bg-search; 
  background-position-x: 50px;
}

.invalid #autosuggest__input {
  @apply border-red-500 border-2
}

#autosuggest__input:focus {
  @apply border-yellow-500 border-2 outline-none
}

#autosuggest__input::placeholder {
  @apply text-color-input-placeholder
}

.autosuggest__results {
  @apply border-r-2 border-l-2 border-yellow-500 absolute z-20 w-full leading-none
}
.autosuggest__results-item {
  @apply 
  p-3
  pl-5
  bg-white 
  border-b 
  border-black 
  cursor-pointer 
  leading-7
  text-color-input-active
  text-nw-base
}
.autosuggest__results-item:last-child {
  @apply border-b-2 border-yellow-500
}
.autosuggest__results-item:hover,
.autosuggest__results-item--highlighted {
  background-color: #d9e7f8;
}
</style>