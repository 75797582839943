<template>
  <div>
    <div class="ml-8 mb-8 flex-1">
      <div class="-mt-4 mb-12 relative text-blue-norway border-2 border-blue-500 p-5 bg-white">
        <div class="text-nw-lg">
          {{ step.transit.line.short_name }}
        </div>
        <p class="mb-0">
          <button
            v-if="stops.length > 0"
            class="link underline text-nw-sm" 
            @click.prevent="toggleStops()"
          >
            {{ text("Show stops") }}
          </button>
          <table 
            v-if="stopsVisible" 
            class="mt-4"
          >
            <tr 
              v-for="(stop, index) in stops" 
              :key="index"
            >
              <td class="text-nw-xs font-bold">
                {{ toTime(lang, stop.local_depart_at) }}
              </td>
              <td class="text-nw-base">
                {{ stop.name }}
              </td>
            </tr>
          </table>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import  filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import slice from 'lodash/slice'
import { mapGetters } from 'vuex'
import { DateMixin } from './mixins/DateMixin'

export default {
  name: 'StopList',
  mixins: [DateMixin],
  props: {
    step: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    stopsVisible: false
  }),
  computed: {
    ...mapGetters(['lang', 'text']),
    stops () {
      if (this.step.transit && this.step.transit.journey.details) {
        let result = filter(this.step.transit.journey.details, r => r.embark || r.disembark)
        let startIndex = findIndex(result, r => r.id.toUpperCase() === this.step.transit.departure_stop.id.toUpperCase())
        let endIndex = findIndex(this.step.transit.journey.details, r => r.id.toUpperCase() === this.step.transit.arrival_stop.id.toUpperCase())
        return slice(result, startIndex + 1, endIndex)
      } else {
        return []
      }
    }
  },
  methods: {
    toggleStops() {
      this.stopsVisible = !this.stopsVisible
    }
  },
}
</script>