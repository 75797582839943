<template>
  <div 
    v-if="order"
    class="mt-5"
  >
    <h2 
      class="text-nw-lg text-blue-norway leading-none"
    >
      {{ text("Your ordered journey") }}
    </h2>
    <div
      class="px-3 py-3 mt-2 text-blue-norway border-blue-500 border bg-grey-300"
    >
      <div class="flex">
        <div class="flex flex-wrap flex-grow">
          <div class="w-full text-black">
            <h3 class="text-nw-sm leading-5">
              {{ formatDate(orderOrigin(order).local_depart_at) }}
            </h3>
            <!-- Departure -->
            <div class="text-nw-sm leading-5">
              {{ orderOrigin(order).departure_stop.name }} - {{ orderDestination(order).arrival_stop.name }}
            </div>
            
            <!-- Return -->
            <div 
              v-if="orderHasReturn(order)" 
              class="mt-2"
            >
              <div class="text-nw-sm leading-5">
                {{ orderReturnOrigin(order).departure_stop.name }} - {{ orderReturnDestination(order).arrival_stop.name }}
              </div>
              <h5 class="text-nw-sm leading-5">
                {{ formatDate(orderReturnOrigin(order).local_depart_at) }}
              </h5>
            </div>
            <div class="mt-2 text-nw-xs">
              {{ order.product_lines.map(r => { return r.quantity + ' ' + r.title }).join(', ') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import { mapGetters } from 'vuex'
import { DateMixin } from './mixins/DateMixin'
import { orderOrigin, orderDestination, orderHasReturn, orderReturnOrigin, orderReturnDestination } from '../utilities/OrderUtility'

export default {
  name: 'ExistingBooking',
  mixins: [DateMixin],
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      showDetails: this.expanded
    }
  },
  computed: {
    ...mapGetters(['lang', 'text', 'existingBooking']),
    order () {
      return this.existingBooking
    }
  },
  methods: {
    orderOrigin,
    orderDestination,
    orderHasReturn,
    orderReturnOrigin,
    orderReturnDestination,
    getValue (input, path) {
      return get(input, path, '-')
    },
    formatDate (value) {
      return (
        this.toTravelDate(this.lang, value) +
        ', ' +
        this.text('kl') +
        '. ' +
        this.toTime(this.lang, value)
      )
    },
    departAt (input, path) {
      const value = get(input, path, '-')
      if (value === '-') {
        return value
      }
    }
  }
}
</script>
