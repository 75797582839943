var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-2 rounded shadow-xl border-grey-500 mt-4 px-3"},[_vm._l((_vm.order.legs),function(leg,index){return _c('div',{key:index,staticClass:"w-full mt-3"},[_c('h3',{staticClass:"text-nw-lg"},[_vm._v(" "+_vm._s(_vm.toTravelDate(_vm.lang, _vm.departAt(leg)))+" ")]),_c('div',{staticClass:"mt-1 text-nw-base font-bold"},[_vm._v(" "+_vm._s(_vm.departureStop(leg))+" - "+_vm._s(_vm.arrivalStop(leg))+" ")]),_c('div',{staticClass:"text-nw-base"},[_vm._v(" "+_vm._s(_vm.toTime(_vm.lang, _vm.departAt(leg)))+" - "+_vm._s(_vm.toTime(_vm.lang, _vm.arriveAt(leg)))+" ")])])}),_c('div',{staticClass:"flex flex-col mt-3"},[_c('div',{staticClass:"flex justify-end pt-2"},[_c('router-link',{staticClass:"text-nw-base text-blue-400 underline",attrs:{"to":{
          name: 'receipt-new',
          params: {
            reference: _vm.order.booking_number,
            id: _vm.order.id,
          },
        }}},[_vm._v(" "+_vm._s(_vm.text('Show tickets'))+" ")]),(_vm.canEdit)?_c('a',{staticClass:"text-nw-base cursor-pointer underline ml-3 text-blue-400",on:{"click":function($event){$event.preventDefault();_vm.edit = true}}},[_vm._v(" "+_vm._s(_vm.text('Edit ticket'))+" ")]):_vm._e()],1),(_vm.isOpen)?_c('div',{staticClass:"flex text-nw-base justify-end"},[_c('a',{staticClass:"underline text-blue-500",attrs:{"id":"find-my-bus","target":"_blank","href":`https://hvorerbussen.nor-way.no/booking/${_vm.order.booking_number}`}},[_vm._v(" "+_vm._s(_vm.text('common.findmybus'))+" ")])]):_vm._e()]),_c('div',{staticClass:"h-3 -mx-3 mt-2",class:_vm.statusClassesBottom}),(_vm.edit)?_c('modal',{attrs:{"title":_vm.text('Edit modal title'),"text":_vm.text('Edit modal text'),"action-text":_vm.text('Edit modal action')},on:{"close":function($event){_vm.edit = false},"proceed":_vm.proceedToEdit}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }