<template>
  <div class="w-full h-10 bg-grey-400 animate-pulse p-2">
    &nbsp;
  </div>
</template>

<script>
export default({
  name: 'SkeletonTitle',
})

</script>
