<template>
  <div class="p-5">
    <h1 class="text-blue-500 text-nw-3xl">
      {{ text('Delete profile') }}
    </h1>
    <p class="text-nw-base mt-5">
      {{ text('Delete profile first warning text 1') }}
    </p>
    <error-message 
      v-if="showError" 
      class="mt-5"
      :text="text('Account could not be deleted')" 
    />
    <n-input 
      id="password"
      v-model="password"
      class="mt-4"
      type="password"
      :label="text('Your password')"
    />

    <div class="flex flex-wrap justify-between mt-5">
      <n-button 
        :disabled="password.length < 1"
        class="bg-red-500 hover:bg-red-500 hover:bg-opacity-75 disabled:bg-grey-500"
        @click="deleteProfile()"
      >
        {{ text('Delete my profile') }}
      </n-button>
      <router-link
        class="my-auto text-blue-500 text-nw-base underline"
        :to="{ name: 'account'}"
      >
        {{ text('Back') }}
      </router-link>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import api from '../api'
import { localStorageRemove, localStorageGet } from '../utilities/LocalStorage'
import NButton from '../components/forms/NButton'
import NInput from '../components/forms/NInput'
import ErrorMessage from '../components/ErrorMessage.vue'

export default {

  name: 'DeleteProfile',

  components: {
    NButton,
    NInput,
    ErrorMessage
  },

  data () {
    return {
      password: '',
      profile: null,
      showError: false
    }
  },

  computed: {
    ...mapGetters([
      'text',
      'user'
    ])
  },

  async created () {
    this.profile = await api.GetProfileById(localStorageGet('memberId'))
  },

  methods: {
    deleteProfile () {
      this.showError = false
      if (confirm(this.text('Delete profile first warning text 2'))) {
        api.DeleteProfile(this.profile.email, this.password).then(response => {
          if (response.error !== undefined) {
            this.showError = true
          } else {
            localStorageRemove('authtoken')
            localStorageRemove('email')
            localStorageRemove('memberId')
            this.$router.push({ name: 'login', query: { message: this.text('Account deleted') } })
          }
        })
      }
    }
  },
}

</script>
