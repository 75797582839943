import axios from 'axios'
import { localStorageGet, localStorageSet } from './../utilities/LocalStorage'
import get from 'lodash/get'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import router from '../router'
import store from '@/store'
import { getUrlString } from '../utilities/UrlUtility'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

axios.interceptors.request.use(
  config => {
    let authtoken = localStorageGet('authtoken')

    let lang = window.__NWLANG || 'nb-NO'

    // config.url += (config.url.indexOf('?') === -1 ? '?' : '&') + 'language=' + lang

    if (router.currentRoute.query.debug) {
      config.url += (config.url.indexOf('?') === -1 ? '?' : '&') + 'debug=true'
    }

    if (authtoken) {
      config.headers = {
        Authorization: 'Bearer ' + authtoken
      }
      // config.url += (config.url.indexOf('?') === -1 ? '?' : '&') + 'authtoken=' + encodeURIComponent(authtoken)
    }
    return config
  }
)

axios.interceptors.response.use(
  response => {
    let authtoken = get(response, 'data.auth.token')
    if (authtoken) {
      localStorageSet('authtoken', authtoken)
    }
    let serverTime = get(response, 'data.info.timeLocal')
    if (serverTime) {
      store.commit('setServerTime', serverTime)
    }
    return response
  }
)

const api = {

  //
  // THESE METHODS HANDLES THE TRIPS SEARCH
  //
  eagleEndpoint: process.env.VUE_APP_API_EAG_ENDPOINT,

  async JourneyById (id) {
    let response = await axios.get(`${this.eagleEndpoint}journeys/${id}/stops`)
    return response.data
  },

  async StopSearch (name) {
    let params = {
      name_filter: name,
      page_size: 20
    }
    let response = await axios.get(this.eagleEndpoint + 'stops', { params })
    return response.data
  },

  // TODO: Provide locale
  async Trips (originID, destinationID, departureDate, fares, discountCode = '', roundtrip, locale, customerId = null) {
    let input = {
      origin: `stop_id:${originID}`,
      destination: `stop_id:${destinationID}`,
      date: departureDate,
      discount_code: discountCode,
      roundtrip: roundtrip,
      locale: locale === 'nb' ? 'nb-NO' : 'en-US'
    }

    if (customerId) {
      input.customer_id = customerId
    }

    forEach(fares, (fare, index) => {
      input[`products[${index}].id`] = fare.id
      input[`products[${index}].quantity`] = fare.count
    })

    let querystring = '?' + getUrlString(input)
    try {
      let result = await axios.get(`${this.eagleEndpoint}trips/${querystring}`)
      return {
        trips: result.data,
        error: undefined
      }
    } catch (e) {
      return {
        trips: [],
        error: get(e, 'response.data.message', null)
      }
    }
  },

  async GetLocalTime () {
    let result = await axios.get('/check')
    return result.data.info.timeLocal
  },

  //
  // THESE METHODS HANDLES PAYMENT
  //

  async CalculateCheckout (input) {
    let result = await axios.post(`${this.eagleEndpoint}checkouts/calculate`, input)
    return result.data
  },

  async CreateCheckout (input) {
    let result = await axios.post(`${this.eagleEndpoint}checkouts`, input)
    return result.data
  },

  async CreateShoppingCart (input) {
    const response = await axios.post(`${this.eagleEndpoint}shopping-carts`, input, { headers: { 'content-type': 'application/json' } })
    return response.data
  },

  async LoadShoppingCart (id) {
    const response = await axios.get(`${this.eagleEndpoint}shopping-carts/${id}`)
    return response.data
  },

  async CalculateShoppingCart (input) {
    const response = await axios.post(`${this.eagleEndpoint}/shopping-carts/calculate/`, input, { headers: { 'content-type': 'application/json' } })
    return response.data
  },

  async CreateOrderCheckout (input, id) {
    let result = await axios.post(`${this.eagleEndpoint}orders/${id}/checkouts`, input)
    return result.data
  },

  async InitiatePayment (token, input) {
    let result = await axios.post(`${this.eagleEndpoint}checkouts/${token}/payments`, input)
    return result.data
  },

  async ExpressCheckout (token, input) {
    let result = await axios.post(`${this.eagleEndpoint}checkouts/${token}/express-complete`, input)
    return result.data
  },

  //
  // THESE ORDERS HANDLES ORDERS
  //

  // This methods allows you to load the order based on the payment token. 
  //  This is useful to avoid completing orders which have already been completed by the payment
  //  providers callbacks. 
  async GetOrderByToken (token) {
    let response = await axios.get(`${this.eagleEndpoint}orders/find-by-token?token=${token}`, { baseURL: process.env.VUE_APP_EAG_ENDPOINT })
    return response.data
  },

  async OrderUpdateFieldAppData (bookingNumber, value) {
    console.log('OrderUpdateFieldAppData', bookingNumber, value)
    const params = {
      value: value
    }
    let response = await axios.patch(`${this.eagleEndpoint}orders/${bookingNumber}`, params, { baseURL: process.env.VUE_APP_EAG_ENDPOINT })
    return response.data
  },

  async GetOrderByBookingAndId (bookingNumber, id) {
    let params = {
      booking_number: bookingNumber
    }
   
    let response = await axios.get(`${this.eagleEndpoint}orders/${id}`, { params })
    return response.data
  },

  async GetOrdersByCustomer (customerId) {
    let params = {
      customer_id: customerId
    }
    let response = await axios.get(`${this.eagleEndpoint}orders`, { params })
    return response.data
  },

  SendOrderMail (orderId, bookingNumber) {
    let querystring = 'booking_number=' + encodeURIComponent(bookingNumber)
    axios.post(`${this.eagleEndpoint}email/orders/${orderId}/creation/send?${querystring}`, { 'template': 'default' })
  },

  //
  // THESE METHODS HANDLES USER INTERACTIONS
  //

  async GetProfileById (customerId) {
    let response = await axios.get(`${this.eagleEndpoint}customers/${customerId}`)
    return response.data
  },

  async UpdateProfile (profile) {

    let response = await axios.put(`${this.eagleEndpoint}customers/${profile.id}`, profile)
    return response.data
  },

  async ChangePassword (email, oldPassword, newPassword) {
    let args = {
      email: email,
      current_password: oldPassword,
      new_password: newPassword
    }
    let response = await axios.post(`${this.eagleEndpoint}identity/change-password`, args)
    return response.data
  },

  //
  // ADDITIONAL PRODUCTS
  //
  async Products (trip) {
    try {
      const response = await axios.post(`${this.eagleEndpoint}additional-products/search`, trip, { headers: { 'content-type': 'application/json' } })
      return map(response.data, item => {
        item.quantity = 0
        return item
      })
    } catch {
      return []
    }
  },

  async AddToCart (cart) {
    const response = await axios.post(`${this.eagleEndpoint}shopping-carts/products/add`, cart, { headers: { 'content-type': 'application/json' } })
    return response.data
  },

  async UpdateCart (cart) {
    const response = await axios.post(`${this.eagleEndpoint}shopping-carts/products/update`, cart, { headers: { 'content-type': 'application/json' } })
    return response.data
  },

  async RemoveFromCart (cart) {
    const response = await axios.post(`${this.eagleEndpoint}shopping-carts/products/remove`, cart, { headers: { 'content-type': 'application/json' } })
    return response.data
  },

  async AddBookingToAccount (bookingNumber) {
    let args = [
      {
        op: 'replace',
        path: 'customer_id',
        value: '{{customerId}}'
      }
    ]
    console.log(bookingNumber)
    let response = await axios.patch(`${this.eagleEndpoint}assign/${bookingNumber}`, args)
    return response.data
  },

  //
  // CUSTOM METHODS NEEDED TO SHIM EAGLE
  //

  async ProfileAuth (email, password, expiry) {
    if (expiry == null) {
      let expiry = new Date()
      expiry.setHours(expiry.getHours() + 1)
    }

    const data = {
      email,
      password,
      expiry
    }
    let response = await axios.post('/UserAuth', data)
    return response.data
  },

  async UserGet () {
    const response = await axios.get('/UserGet')
    return response.data.data
  },

  DeleteProfile (email, password) {
    let data = {
      email: email,
      password: password
    }
    return axios.post('/UserDelete', data)
      .then(response => {
        return get(response, 'data.data')
      })
      .catch(function (error) {
        return error.response.data
      })
  },

  ResetPassword (email) {
    let args = {
      email: email,
      linkUrl: `${location.origin}/reiseplanlegger/#/innlogging/nullstill-passord`,
      language: window.__NWLANG || 'nb-NO'
    }

    return axios.post('/UserPasswordSendResetEmail', args)
      .then(function (response) {
        return get(response, 'data.data')
      }).catch(function (error) {
        return error
      })
  },

  async ResetPasswordWithShoppingCart (email, shoppingCartId) { 
    let args = {
      email: email,
      linkUrl: `${location.origin}/reiseplanlegger/#/innlogging/nullstill-passord?shoppingCart=${shoppingCartId}`,
      language: window.__NWLANG || 'nb-NO'
    }
    const response = await axios.post("UserPasswordSendResetEmail", args)
    return response.data
  },

  UpdatePassword (email, password, code) {
    let args = {
      email: email,
      password: password,
      code: code,
      explain: true
    }

    return axios.post('/UserPasswordReset', args)
      .then(function (response) {
        return response.data
      }).catch(function (error) {
        return get(error, 'response.data')
      })
  },

  async RegisterProfile (email, password, mobile, firstName, lastName, newsletter) {
    let args = {
      email: email,
      emailConfirm: email,
      password: password,
      passwordConfirm: password,
      phone: mobile,
      givenName: firstName,
      familyName: lastName,
      receiveNewsletter: newsletter
    }

    const response = await axios.post('/UserRegister', args)
    return response.data.data
  },
}

export default api
