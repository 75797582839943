<template>
  <div class="text-center w-full">
    <div class="w-full h-16 text-center n-loading bg-no-repeat bg-position-center-center" />
    <span v-if="text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'NLoading',
  props: {
    text: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="css" scoped>
.n-loading {
    background-image: url(../assets/loading.svg);
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
}
</style>
