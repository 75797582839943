<template>
  <div id="fares-picker">
    <button 
      :class="pickerClasses" 
      class="picker text-nw-base"
      @click.prevent="open = !open" 
    >
      {{ fareSelectionSummary }}
    </button>
    <div
      v-if="open" 
      class="mr-5 z-10 grid absolute border-2 max-w-md bg-white border-yellow-500 p-5 mt-2 grid-cols-1"
    >
      <button 
        aria-label="close" 
        class="cursor-default fixed top-0 bottom-0 left-0 right-0 w-full h-full" 
        @click.prevent="close()"
      />
      <div
        v-for="fare in fares"
        :key="fare.id"
        class="grid z-50 grid-cols-12"
      >
        <div 
          :class="fare.count > 0 ? 'font-semibold' : 'font-normal'" 
          class="col-span-8 text-nw-base text-blue-norway"
        >
          {{ fare.name }}
        </div>
        <div class="col-span-4 flex flex-row-reverse">
          <button 
            aria-label="add"
            class="bg-add w-7 h-7 bg-no-repeat disabled:opacity-40"
            @click.prevent="add(fare)"
          />
          <div class="px-5 text-nw-base">
            {{ fare.count }}
          </div>
          <button
            aria-label="remove"
            :disabled="fare.count == 0"
            class="bg-remove w-7 h-7 bg-no-repeat disabled:opacity-30" 
            @click.prevent="remove(fare)"
          />
        </div>
        <div class="col-span-12 mt-1 mb-2 text-nw-sm">
          {{ fare.helpText }}
        </div>
      </div>
      <a
        title="close"
        href="#"
        class="underline text-center mt-2" 
        @click.prevent="close()" 
      >
        {{ text('Common.close') }}
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import filter from 'lodash/filter'
  import sumBy from 'lodash/sumBy'
  export default {
    name: 'FarePicker',
    props: {
      position: undefined,
      valid: {
        type: Boolean,
        default: true
      },
    },
    data () {
      return {
        open: false
      }
    },
    computed: {
      ...mapGetters([
        'text', 'fares'
      ]),
      pickerClasses () {
        let classes = [ this.position]
        if (this.open) {
          classes.push('open')
        }
        if (this.numberOfSelectedFares === 0) {
          classes.push('placeholder')
        }
        if (!this.valid) {
          classes.push('invalid')
        }
        return classes.join(' ')
      },
      numberOfSelectedFares () {
        var fares = filter(this.fares, o => o.count > 0)
        return fares.length
      },
      fareSelectionSummary () {
        var fares = filter(this.fares, o => o.count > 0)
        if (fares.length > 1) {
          let numberOfFares = sumBy(fares, 'count')
          return `${numberOfFares} ${this.text('travellers')}` 
        } else if (fares.length === 1) {
          return fares[0].count + ' ' + fares[0].name
        } else {
          return this.text('Select passengers')
        }
      }
    },
    methods: {
      add (fare) {
        this.$store.commit('increaseFare', fare)
      },
      remove (fare) {
        this.$store.commit('decreaseFare', fare)
      },
      close () {
        this.open = false
        this.$scrollTo("#app")
      }
    } 
  }
</script>

<style lang="css" scoped="true">
  .picker {
    @apply 
    border-blue-norway 
    w-full 
    text-left 
    p-2 
    pr-3
    pl-12
    h-12 
    outline-none
    text-color-input-active
    bg-person
    bg-no-repeat
    bg-left
    border
    bg-white
    text-nw-base;
    background-position-x: 14px;
  }

  .picker.invalid {
    @apply border-red-500 border-2
  }

  .picker:focus {
    @apply border-2 border-yellow-500 !important
  }

  .picker.placeholder {
    @apply text-color-input-placeholder;
  }

  .picker.open {
    @apply border-2 border-yellow-500
  }

</style>