<template>
  <div v-if="showPriceRules">
    <div class="border-2 border-yellow-500 p-4">
      <table class="w-full text-black font-normal">
        <thead class="text-nw-xxs">
          <tr>
            <th class="text-center pb-2">
              {{ text('Quantity') }}
            </th>
            <th class="text-right pb-2">
              {{ text('Type') }}
            </th>
            <th class="text-right pb-2">
              {{ text('Total') }}
            </th>
          </tr>
        </thead>
        <tbody class="text-nw-xs">
          <tr 
            v-for="item in trip.product_lines" 
            :key="item.productId"
          >
            <td class="text-center">
              {{ item.quantity }}
            </td>
            <td class="text-right">
              {{ item.title }}
            </td>
            <td class="text-right">
              <span v-if="showPriceRules">
                ({{ item.list_price }}) 
              </span>
              {{ item.line_total }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div 
      v-if="showPriceRules" 
      class="border-2 p-4 mt-5 border-green-500"
    >
      <h2 class="text-nw-sm font-semibold">
        Prisregler:
      </h2>

      <table class="mt-5 text-nw-xxs text-left">
        <tbody>
          <tr 
            v-for="rule in trip.price_rules" 
            :key="rule.token"
          >
            <td>{{ rule.title }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PriceDetails',
  props: {
    trip: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters([
      'text',
      'showPriceRules'
    ]),
  }
}
</script>
