<template>
  <div class="text-center">
    <div class="text-center bg-no-repeat bg-position-center-center n-loading-white h-6" />
    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'NLoading',
  props: {
    text: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="css" scoped>
.n-loading-white {
    background-image: url(../assets/loading-white.svg);
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
}
</style>
