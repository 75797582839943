<template>
  <div>
    <span class="text-nw-base">
      {{ text('When i buy this ticket, i agree with the') }}
      <a 
        class="underline"
        target="_blank"
        :href="text('url-purchase-terms')"
      >{{ text('terms and conditions') }}</a>.
    </span>

    <div
      class="flex-wrap md:flex w-52 mx-auto mt-5 md:mx-0 md:w-full px-auto space-y-3 md:space-y-0 md:space-x-4 justify-center"
    >
      <button 
        v-if="paymentDue > 0" 
        type="submit" 
        value="vipps" 
        class="block cursor-pointer" 
        :alt="text('Pay with vipps')"
        :aria-label="text('Pay with vipps')"
      >
        <icon-vipps :lang="lang" />
      </button>
      <button
        v-if="paymentDue > 0"
        type="submit"
        value="nets"
        class="bg-blue-norway w-52 hover:bg-blue-hover text-white font-button text-nw-base rounded-full px-4 py-2"
      >
        {{ text('Pay with card') }}
      </button>
      <button 
        v-if="paymentDue <= 0" 
        type="submit" 
        value="confirm"
        class="bg-blue-norway w-52 hover:bg-blue-hover text-white font-button font-semibold rounded-full items-center px-4 h-11"
      >
        {{ isChange ? text('Confirm change') : text('Confirm') }}
      </button>
      <button 
        v-if="isChange"
        class="border-2 block w-52 border-blue-500 hover:border-blue-hover font-button font-semibold px-4 h-11 border-solid rounded-full"
        @click.prevent="$router.push({ name: 'account' })"
      >
        {{ text('Common.abort') }}
      </button>
    </div>
  </div>
</template>

<script>
import IconVipps from './icons/IconVipps.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'PurchaseButtons',
  components: {
    IconVipps
  },
  props: {
    paymentDue: {
      type: Number,
      default: 0
    },
    isChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'text'
    ])
  },
}
</script>
