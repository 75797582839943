<template>
  <transition
    name="fade" 
    appear
  >
    <div
      class="p-4 px-6 ga-departure-item"
      :class="(isLast ? '' : 'border-b border-grey-400') + (isFirst === 0 ? 'border-t border-grey-400' : '')"
      :data-productId="productId" 
      :data-productName="productName"
      :data-productCategory="productCategory"
      :data-productBrand="productBrand"
      :data-productVariant="productVariant"
      data-productList="Travel Planner Search Result"
      :data-productPosition="index"
      :data-productPrice="productPrice"
      :data-direction="isReturn ? 'return' : 'departure'"
    >
      <div class="grid w-full grid-cols-6 md:grid-cols-5 gap-0">
        <div class="col-span-6 md:col-span-2 flex justify-items-center items-stretch">
          <span class="text-nw-base-plus my-auto">
            <strong>{{ toTime(lang, tripDeparture) }}</strong> 
            → 
            {{ toTime(lang, tripArrival) }}
          </span>
          <div class="ml-2 my-auto text-nw-sm">
            ( {{ toDuration(lang, tripDeparture, tripArrival) }}
            <button
              v-if="trip.steps.length > 1"
              class="text-nw-sm"
              tabindex="0"
              @click.prevent="toggleTripDetails"
            >
              , {{ transfers }} {{ transfers == 1 ? text("tripitem.bustransfer") : text("tripitem.bustransfers") }}
            </button> )
          </div> 
        </div>
        <div class="col-span-5 md:col-span-1">
          <div class="text-black mt-2 md:mt-0 text-nw-base-plus font-light">
            <span>
              {{ isPast ? '-' : getPrice(trip) }}
            </span>
          </div>
        </div>
       
        <div class="col-span-3 md:col-span-1">
          <button
            class="flex text-nw-sm h-full underline outline-none align-middle ga-departure-stops-button" 
            @click.prevent="toggleTripDetails()"
          >
            <div class="text-left my-auto">
              {{ text("Tripitem.details") }}
            </div>
            <div class="fill-current my-auto ml-3 text-grey-font">
              <icon-arrow
                class="mr-auto ml-auto"
                :css-class="detailsVisible ? 'rotate-180 transform' : ''" 
              />
            </div>
          </button>
        </div>
        <div class="col-span-3 md:col-span-1 flex items-center">
          <n-button 
            v-if="tripAvailable"
            class="ml-auto"
            span-class="ga-departure-button"
            @click="handleTripSelected(trip)"
          >
            {{ text("Tripitem.choosedeparture") }}
          </n-button>
          <div 
            v-else
            class="ml-auto"
          >
            <span v-if="isPast">Avgang passert</span>
            <span 
              v-else-if="tripSoldOut" 
            >
              {{ text("Tripitem.soldout") }}
            </span>
            <span 
              v-else-if="tripClosed" 
            >
              {{ text("Tripitem.closed") }}
            </span>
            <span 
              v-else-if="tripNotOpen" 
            >
              {{ text("Tripitem.notopen") }}
            </span>
            <span 
              v-else 
            >
              {{ text("Tripitem.error") }}
            </span>
          </div>
        </div>
      </div>

      <trip-message-list 
        v-if="!detailsVisible" 
        :with-details="false" 
        :trip="trip"
      />

      <TripItemDetails
        v-if="detailsVisible"
        class="w-full"
        :sold-out="tripSoldOut"
        :trip="trip"
        @tripSelected="handleTripSelected"
        @closeTripDetails="toggleTripDetails"
      />
    </div>
  </transition>
</template>

<script>

import { mapGetters } from 'vuex'
import IconArrow from './icons/IconArrow.vue'
import NButton from '@/components/forms/NButton.vue'
import TripItemDetails from './TripItemDetails.vue'
import TripMessageList from './TripMessageList.vue'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { DateMixin } from './mixins/DateMixin'
import { tripDepartureDate, tripArrivalDate, tripDepartureStop, tripArrivalStop } from '../utilities/TripUtility'
import { logViewItem, logViewItemList } from '@/utilities/GaUtility'
export default {
  name: 'TripItem',
  components: {
    IconArrow,
    NButton,
    TripItemDetails,
    TripMessageList
  },
  mixins: [ DateMixin ],
  props: {
    trip: {
      type: Object,
      required: true
    },
    isReturn: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    isPast: {
      type: Boolean,
      default: false
    },
    isLast: {
      type: Boolean,
      default: false
    },
    isFirst: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      legs: [],
      detailsVisible: false
    }
  },

  computed: {
    ...mapGetters([
      'text',
      'totalCount',
      'lang',
      'ret',
      'discountCode'
    ]),
    transfers () {
      return this.trip.steps.length - 1
    },
    tripAvailable () {
      return this.trip.available
    },
    tripDeparture () {
      return tripDepartureDate(this.trip)
    },
    tripArrival () {
      return tripArrivalDate(this.trip)
    },
    tripSoldOut () {
      return filter(this.trip.steps,
        {
          transit: { availability_status: 'full' }
        }).length > 0
    },
    tripOpen () {
      return filter(this.trip.steps,
        {
          transit: { availability_status: 'open' }
        }).length > 0
    },
    tripClosed () {
      return filter(this.trip.steps,
        {
          transit: { availability_status: 'closed' }
        }).length > 0
    },
    tripNotOpen () {
      return filter(this.trip.steps,
        {
          transit: { availability_status: 'error' }
        }).length > 0
    },
    productId () {
      return map(this.trip.steps, (step) => {
        return step.transit.route.number + ' ' + step.transit.journey.number
      }).join(' | ')
    },
    productName () {
      return tripDepartureStop(this.trip).name + ' - ' + tripArrivalStop(this.trip).name
    },
    productCategory () {
      return ''
    },
    productBrand () {
      return map(this.trip.steps, (step) => {
        return step.transit.route.number
      }).join(' | ')
    },
    productVariant () {
      return this.toDate(tripDepartureDate(this.trip))
    },
    productPrice () {
      return this.trip.total
    }
  },

  methods: {

    getPrice (trip) {
      return trip.total + ' ' + this.text('common.currency')
    },

    handleTripSelected (trip) {
      this.$emit('tripSelected', trip)
    },

    toggleTripDetails () {
      this.detailsVisible = !this.detailsVisible
      if (this.detailsVisible) {
        logViewItem(this.trip, this.ret, this.index, this.isReturn, this.discountCode)
      }
    }
  }
}
</script>

<style>
  .fade-enter {
    opacity: 0.1;
  }
  .fade-enter-active {
    transition: opacity 1s ease;
  }

  .fade-enter-to {
    opacity: 1;
  }
</style>
