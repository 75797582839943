<template>
  <div 
    v-if="!error" 
    id="loading-container" 
    class="p-12 text-center text-blue-500 text-nw-base"
  >
    <span>{{ text('Confirming transaction please wait') }} ...</span>
    <img 
      class="mt-5 mx-auto" 
      src="../assets/loading.svg" 
    >
  </div>
  <div 
    v-else 
    class="p-12 text-center"
  >
    <span class="text-nw-base text-red-500">
      {{ text('Payment failed') }}
    </span>
  </div>
</template>

<script>

import api from '../api'
import { mapGetters } from 'vuex'
import { createExpressCompleteObject } from '../utilities/CheckoutUtility'
import { DateMixin } from '../components/mixins/DateMixin'
import { localStorageGet } from '../utilities/LocalStorage'
import { logPurchase } from '../utilities/GaUtility'

export default {
  name: 'VippsCallback',
  mixins: [DateMixin],
  data () {
    return {
      error: false
    }
  },
  computed: {
    ...mapGetters([
      'text'
    ])
  },
  async created () {
    let token = this.$router.currentRoute.query.token
    let parentTransactionId = this.$router.currentRoute.query.parent_transaction_id
    let responseCode = this.$router.currentRoute.query.responseCode
    let code = this.$router.currentRoute.query.code

    if (responseCode === 'Cancel') {
      // If user cancelled, redirect back to the checkout page
      let query = JSON.parse(localStorageGet('query'))
      this.$router.push({ path: 'betale', query: { ...query, err: 1 } })
    } else {
      this.logToAI('UI: Start payment process for token:', { token: token })
      const order = await api.GetOrderByToken(token)

      // if the order is completed
      if (order && order.status === 'closed') {
 
        // Check if order has been registered in GA?
        if (order.app_data && order.app_data.includes('ga-registered')) {
          // No need to to do anything, this is both completed and registered in GA,
          //  redirect to receipt page
          this.$router.push({ name: 'receipt-new', params: { reference: order.booking_number, id: order.id } })
        } else {
          // Order is completed, but not registered in GA, register it, update order and redirect to receipt page
          try {
            logPurchase(order, 'vipps')
            await api.OrderUpdateFieldAppData(order.booking_number, 'ga-registered')
          } catch {
            this.logToAI('UI: Failed to update order with GA registration', { order: order.booking_number })
          }
          this.$router.push({ name: 'receipt-new', params: { reference: order.booking_number, id: order.id } })
        }
      } else {
        // Order is not completed, we need to complete it and register it in GA
        // Create input for express complete
        const checkoutInput = createExpressCompleteObject(parentTransactionId, code)
        try {
          const checkoutOrder = await api.ExpressCheckout(token, checkoutInput)
          logPurchase(checkoutOrder, 'vipps')

          try {
            await api.OrderUpdateFieldAppData(checkoutOrder.booking_number, 'ga-registered')
          } catch {
            this.logToAI('UI: Failed to update order with GA registration', { booking_number: checkoutOrder.booking_number })
          }
          try {
            api.SendOrderMail(checkoutOrder.id, checkoutOrder.booking_number)
          } catch {
            this.logToAI('UI: Failed to send order mail', { booking_number: checkoutOrder.booking_number })
          }
          this.$router.push({ name: 'receipt-new', params: { reference: checkoutOrder.booking_number, id: checkoutOrder.id } })
        } catch (error) {
          this.logToAI('UI: Failed to complete order', { token: token, error: error })
          let query = JSON.parse(localStorageGet('query'))
          this.$router.push({ path: 'betale', query: { ...query, err: 1 } })
        }
      }
    }
  },
  methods: {
    async logToAI (text, props) {
      await this.$appInsights.trackTrace(
        {
          message: text,
          severity: 1,
          properties: props
        }
      )
    }
  }
}
</script>