export function getUrlString (params, keys = [], isArray = false) {
  // https://stackoverflow.com/questions/22678346/convert-javascript-object-to-url-parameters
  const p = Object.keys(params).map(key => {
    let val = params[key]

    if (Object.prototype.toString.call(val) === '[object Object]' || Array.isArray(val)) {
      if (Array.isArray(params)) {
        keys.push('')
      } else {
        keys.push(key)
      }
      return getUrlString(val, keys, Array.isArray(val))
    } else {
      let tKey = key

      if (keys.length > 0) {
        const tKeys = isArray ? keys : [...keys, key]
        tKey = tKeys.reduce((str, k) => { return str === '' ? k : `${str}[${k}]` }, '')
      }
      if (isArray) {
        return `${encodeURIComponent(tKey)}[]=${val}`
      } else {
        return `${encodeURIComponent(tKey)}=${val}`
      }
    }
  }).join('&')

  keys.pop()
  return p
}

export default { getUrlString }
