<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icon-remove"
        fill="#003F8A"
      >
        <g id="Icon-24px">
          <path
            id="Shape"
            d="M15,0 C6.72,0 0,6.72 0,15 C0,23.28 6.72,30 15,30 C23.28,30 30,23.28 30,15 C30,6.72 23.28,0 15,0 L15,0 Z M22.5,16.5 L7.5,16.5 L7.5,13.5 L22.5,13.5 L22.5,16.5 L22.5,16.5 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconRemove'
}
</script>
