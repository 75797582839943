export function localStorageGet (key) {
  try {
    return localStorage.getItem(key)
  } catch {
    return null
  }
}

export function localStorageSet (key, value) {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
  }
}

export function localStorageRemove (key) {
  try {
    localStorage.removeItem(key)
  } catch {}
}

export default { localStorageGet, localStorageSet, localStorageRemove }
