<template>
  <div class="pb-4">
    <div class="grid text-blue-norway pb-8 relative grid-cols-4 gap-8">
      <div class="col-span-1">
        <span class="text-nw-base-plus">
          {{ toTime(lang, dateTime) }}
        </span>
      </div>
      <div 
        class="col-span-3" 
        :class="'bus-graphic-' + position"
      >
        <p class="mb-0 ml-4 xs:ml-0">
          <strong>{{ stopName }}</strong><br>
          <template v-if="platform">
            {{ text('Platform') }} {{ platform }}<br>
          </template>
          <button 
            class="underline" 
            @click="toggleMap()"
          >
            {{ text("Show map") }}
          </button>
        </p>
        <div
          v-if="mapVisible"
          class="h-80 w-full border-2 border-grey-border mt-8" 
        >
          <v-map 
            :zoom="18" 
            :center="[latitude, longitude]"
            class="trip-detail__map"
          >
            <v-tilelayer :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'" />
            <v-marker :lat-lng="[latitude, longitude]" />
          </v-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateMixin } from './mixins/DateMixin'

export default {
  name: 'StopItem',
  mixins: [DateMixin],
  props: {
    dateTime: undefined,
    stopName: undefined,
    platform: undefined,
    latitude: undefined,
    longitude: undefined,
    position: {
      type: String,
      default: 'top'
    }
  },
   data() {
    return {
      mapVisible: false,
    }
  },
  computed: {
    ...mapGetters(['lang', 'text'])
  },
  methods: {
    toggleMap() {
      this.mapVisible = !this.mapVisible
    }
  },
  
}
</script>

<style type="css" scoped>
  .bus-graphic-top::after, .bus-graphic-bottom::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 65px;
    background-size: 24px auto;
    background-repeat: no-repeat;
    background-color: white;
    width: 24px;
    height: 36px;
    @apply bg-bus;
  }
  .bus-graphic-top::before {
    content: "";
    position: absolute;
    top: 32px;
    bottom: 0;
    left: 75px;
    height: 100%;
    @apply border-l-3px border-grey-border
  }

  .bus-graphic-bottom::before {
    content: "";
    position: absolute;
    top: -48px;
    bottom: 0;
    left: 75px;
    height: 40px;
    @apply border-l-3px border-grey-border
  }

  
</style>