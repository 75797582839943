<template>
  <div class="border-4 border-yellow-500 text-center bg-yellow-500 bg-opacity-25 p-3">
    <h2 
      v-if="title"
      class="text-nw-base-plus leading-5 text-black" 
    >
      {{ title }}
    </h2>
    <p
      v-if="text"
      class="mb-0 text-nw-base leading-tight text-black"
      :class="title ? 'mt-2' : ''" 
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'InfoMessage',
  props: {
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    }
  }
}
</script>
