<template>
  <div>
    <a
      class="underline mx-5 cursor-pointer inline-block"
      @click.prevent="goBack"
    >
      &#8592; {{ text('Common.back') }}
    </a>
    <h2 class="text-nw-xl mx-5 mt-5 text-blue-norway">
      {{ text('Register.title') }}
    </h2>
    
    <register-user
      class="mx-5 mt-5"
      :is-modal="false"
      :show-title="false"
      :show-link="false" 
      @registered="registered" 
    />
  </div>
</template>

<script>
  import RegisterUser from '@/components/RegisterUser.vue'
  import { mapGetters } from 'vuex'
  export default {
    name: 'BookingRegister',
    components: {
      RegisterUser
    },
    props: {
      shoppingCart: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        email: ''
      }
    },
    computed: {
      ...mapGetters(['text'])
    },
    mounted () {
      this.$scrollTo('#app')
    },
    methods: {
      goBack () {
        this.$router.push({
          name: 'autentisere',
          query: {
            shoppingCart: this.shoppingCart
          }
        })
      },
      registered () {
        this.$router.push({
          name: 'betale',
          query: {
            shoppingCart: this.shoppingCart
          }
        })
      }
    }
  }
</script>