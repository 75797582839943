import Vue from 'vue'
import VueRouter from 'vue-router'
import BookingTravelInfo from '../pages/BookingTravelInfo.vue'
import BookingDeparture from '../pages/BookingDeparture.vue'
import BookingReturn from '../pages/BookingReturn.vue'
import BookingPayment from '../pages/BookingPayment.vue'
import BookingProducts from '../pages/BookingProducts.vue'
import BookingAuthenticate from '../pages/BookingAuthenticate.vue'
import BookingRegister from '../pages/BookingRegister.vue'
import BookingResetPassword from '../pages/BookingResetPassword.vue'
import NetsCallback from '../pages/NetsCallback.vue'
import VippsCallback from '../pages/VippsCallback.vue'
import Receipt from '../pages/Receipt.vue'
import ReceiptRewrite from '../pages/ReceiptRewrite.vue'
import Login from '../pages/Login.vue'
import EditProfile from '../pages/EditProfile.vue'
import ChangePassword from '../pages/ChangePassword.vue'
import Account from '../pages/Account.vue'
import DeleteProfile from '../pages/DeleteProfile.vue'
import ImportTicket from '../pages/ImportTicket.vue'
import { localStorageGet } from '../utilities/LocalStorage'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'booking', redirect: '/reise' },
  // if path / redirect to /reise

  { path: '/nets-callback', name: 'nets-callback', component: NetsCallback, props: true },
  { path: '/vipps-callback', name: 'vipps-callback', component: VippsCallback, props: true },
  { 
    path: '/kvittering/:reference/:id', 
    name: 'receipt-new', 
    component: Receipt,   
    props: (route) => ({
      showHeading: route.query.showHeading ? true : false,
      reference: route.params.reference,
      id: String(route.params.id)
    })
  },
  { path: '/kvittering/:reference/:departure', name: 'receipt', component: Receipt, props: true },
  { path: '/kvittering/:reference/:email/:departure', name: 'receipt-old', component: ReceiptRewrite, props: true },
  { path: '/innlogging/:show?', name: 'login', component: Login, props: true },
  { path: '/rediger-profil', name: 'edit-profile', component: EditProfile, meta: { requiresAuth: true } },
  { path: '/importer-billett', name: 'import-ticket', component: ImportTicket, meta: { requiresAth: true } },
  { path: '/bytt-passord', name: 'change-password', component: ChangePassword, meta: { requiresAuth: true } },
  { path: '/slett-meg', name: 'delete-profile', component: DeleteProfile, meta: { requiresAuth: true } },
  { path: '/min-side', name: 'account', component: Account, props: true, meta: { requiresAuth: true } },

  /* Booking process */
  { path: '/reise', name: 'reise', component: BookingTravelInfo },
  { path: '/utreise', name: 'utreise', component: BookingDeparture, props: true },
  { path: '/hjemreise', name: 'hjemreise', component: BookingReturn, props: true },
  { path: '/produkter', name: 'produkter', component: BookingProducts, props: true },
  { path: '/betale', name: 'betale', component: BookingPayment, props: true },
  { 
    path: '/autentisere', 
    name: 'autentisere', 
    component: BookingAuthenticate, 
    props: (route) => ({
      shoppingCart: String(route.query.shoppingCart),
    })
  },
  { 
    path: '/registrere', 
    name: 'registrere', 
    component: BookingRegister, 
    props: (route) => ({
      shoppingCart: String(route.query.shoppingCart)
    })
  },
  { 
    path: '/glemt-passord', 
    name: 'glemt-passord', 
    component: BookingResetPassword, 
    props: (route) => ({
      shoppingCart: String(route.query.shoppingCart)
    })
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let isLoggedIn = localStorageGet('authtoken') !== null
  if (!isLoggedIn && to.matched.some(record => record.meta.requiresAuth)) {
    next({
      path: '/innlogging',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
