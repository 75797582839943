<template>
  <div 
    v-if="messages.length > 0" 
    :class="withDetails ? 'border-2 border-yellow-500 p-5 pb-0': ''" 
    class="text-grey-font text-nw-sm mt-3 w-full"
  >
    <div
      v-for="(message, index) in messages" 
      :key="index"
      :class="withDetails ? 'mb-5' : ''"
      class="flex flex-row"
    >
      <div class="">
        <icon-warning class="mt-[3px]" />
      </div>
      <div class="ml-1 grow text-nw-xs">
        <span class="block">{{ message.title }}</span>
        <span 
          v-if="withDetails"
          class="block"
        >
          {{ message.description }} {{ message.suggestions }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import forEach from 'lodash/forEach'
import IconWarning from './icons/IconWarning.vue'

export default {
  name: 'TripMessageList',
  components: {
    IconWarning
  },
  props: {
    withDetails: {
      type: Boolean,
      default: false
    },
    trip: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'text',
      'totalCount' // This is the number of tickets the customer wants to buy
    ]),
    messages () {
      let result = []
      if (this.trip) {
        forEach(this.trip.steps, step => {
          if (step.transit && step.transit.messages && step.transit.messages.length > 0) {
            result.push.apply(result, step.transit.messages)
          }
        })
      }
      return result
    }
  }
}

</script>
