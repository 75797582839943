<template>
  <div v-if="shoppingCart">
    <a
      class="underline mx-5 cursor-pointer inline-block"
      @click.prevent="goBack"
    >
      &#8592; {{ text('Common.back') }}
    </a>
    <div 
      v-if="loadingProducts"
      class="mt-5 mx-5"
    >
      <skeleton-title 
        class="w-1/2" 
      />
      <div class="mt-8">
        <skeleton-product />
        <skeleton-product />
        <skeleton-button />
      </div>
    </div>
    <transition 
      v-else
      appear 
      name="fade"
    >
      <div>
        <div
          class="mt-5 mx-5"
        >
          <h2 
            class="text-nw-xl text-blue-norway"
          >
            {{ text('Products.heading') }}
          </h2>
        </div>
        <div class="mt-8 text-nw-base overflow-hidden">
          <div 
            v-if="!products || products.length === 0"
            class="text-center p-10"
          >
            <span class="text-blue-norway">{{ text('Products.noproducts') }}</span>
          </div>
          <product-picker 
            v-for="(product, index) in sortedProducts" 
            :key="index"
            :base-product="product"
            :shopping-cart="shoppingCart"
            @add="addToCart" 
            @remove="removeFromCart" 
          />
          <div 
            v-if="!loadingProducts" 
            class="text-center"
          >
            <n-button
              class="mt-12 mx-auto"
              @click.prevent="goToPayment()"
            >
              {{ text('Products.button') }}
            </n-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NButton from '../components/forms/NButton.vue'
import SkeletonButton from '@/components/skeletons/SkeletonButton.vue'
import SkeletonTitle from '@/components/skeletons/SkeletonTitle.vue'
import SkeletonProduct from '@/components/skeletons/SkeletonProduct.vue'
import ProductPicker from '../components/ProductPicker.vue'
import { mapGetters, mapState } from 'vuex'
import orderBy from 'lodash/orderBy'
import api from '../api/index'
import { localStorageGet } from '../utilities/LocalStorage'
import { updateShoppingCart } from '../utilities/ShoppingCartUtility'
import { createQueryFromShoppingCart } from '../utilities/QueryUtility'
import { isAuthenticated } from '@/utilities/UserUtility'
import { logAddShoppingCartItem, logRemoveShoppingCartItem, logDeleteCart, logAddToCart, logBeginCheckout } from '@/utilities/GaUtility'

export default {
  name: 'BookingProducts',
  components: {
    NButton,
    SkeletonButton,
    SkeletonTitle,
    SkeletonProduct,
    ProductPicker,
  },
  data () {
    return {
      expanded: true,
      products: undefined,
      shoppingCartLoading: false,
      loadingProducts: true
    }
  },
  computed: {
    shoppingCart: {
      get() {
        return this.$store.getters.shoppingCart;
      }, 
      set (value) {
        this.$store.commit('setShoppingCart', value);
      }
    },
    ...mapState([
      'query'
    ]),
    ...mapGetters([
      'returnDate',
      'departureTrip',
      'text',
      'edit'
    ]),
    sortedProducts () {
      return orderBy(this.products, r => r.title)
    }
  },
  watch: {
    shoppingCart: {
      handler (newValue, oldValue) {
        if (oldValue == null && newValue != null) {
          this.loadProducts()
        }
      },
      deep: false
    }
  },
  async mounted () {
    if (this.shoppingCart) {
      logAddToCart(this.shoppingCart)
      await this.loadProducts()
    }
    if (this.products && this.products.length === 0) {
      if (this.query.back) {
        this.goBack()
      } else {
        this.goToPayment()
      }
    } else {
      this.$scrollTo('#app')
    }
  },
  methods: {
    memberId () {
      return localStorageGet('memberId')
    },
    goBack () {
      const searchQuery = createQueryFromShoppingCart(this.edit?.id, this.edit?.bookingNumber) // We need to go back to the search query
      logDeleteCart(this.shoppingCart.id)
      if (searchQuery.returnDate) {
        this.$router.push({ name: 'hjemreise', query: { ...searchQuery } })
      } else {
        this.$router.push({ name: 'utreise', query: { ...searchQuery } })
      }
    },
    goToPayment () {
      logBeginCheckout(this.shoppingCart)
      if (isAuthenticated()) {
        this.$router.push({ name: 'betale', query: { ...this.query } })
      } else {
        this.$router.push({ name: 'autentisere', query: { ...this.query } })
      }
    },
    async loadProducts () {
      this.loadingProducts = true
      this.products = await api.Products(this.shoppingCart)
      this.loadingProducts = false
    },
    async addToCart (product) {
      logAddShoppingCartItem(product)
      updateShoppingCart(product, this.memberId())
    },
    async removeFromCart (product) {
      logRemoveShoppingCartItem(product)
      updateShoppingCart(product, this.memberId())
    }, 
  },
  
}
</script>

<style>
  .fade-enter {
    opacity: 0.1;
  }
  .fade-enter-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-to {
    opacity: 1;
  }
</style>
