<template>
  <!-- Loading message used when the customer has experienced a sold out product -->
  <div
    v-if="capacityError"
    class="text-center mt-auto"
  >
    <n-loading />
    <message-box 
      id="price-change" 
      class="mt-5 mx-2"
    >
      <template #header>
        {{ text('capacityerror.title') }}
      </template>
      <template #main>
        {{ text('capacityerror.text') }}
      </template>
    </message-box>
  </div>
  <div
    v-else
  >
    <a
      class="underline cursor-pointer mx-5 inline-block"
      @click.prevent="goBack"
    >
      &#8592; {{ text('common.back') }} 
    </a>
    <h2 class="text-nw-xl mx-5 mt-5 mb-5 text-blue-norway">
      {{ text('Departure.title') }}
    </h2>
    <existing-booking-accordion 
      class="mb-5" 
      :open="false" 
    />
    <departure-info class="" />

    <TripList
      id="trip-list"
      :trips="departureTrips"
      :date="departureDate"
      :trips-loading="departureTripsLoading"
      :error="departureTripsError"
      @tripSelected="handleTripSelected"
      @dateChanged="handleDateChanged"
    />
  </div>
</template>

<script>

import TripList from '../components/TripList.vue'
import { mapState, mapGetters } from 'vuex'
import { DateMixin } from '../components/mixins/DateMixin'
import DepartureInfo from '../components/DepartureInfo.vue'
import ExistingBookingAccordion from '../components/ExistingBookingAccordion.vue'
import { buildShoppingCart } from '../utilities/ShoppingCartUtility'
import { memberId } from '../utilities/UserUtility'
import NLoading from '../components/NLoading.vue'
import MessageBox from '@/components/MessageBox.vue'
import { logViewItemList, logSelectItem } from '@/utilities/GaUtility'
import filter from 'lodash/filter'
import first from 'lodash/first'

export default {
  name: 'BookingDeparture',
  components: {
    DepartureInfo,
    ExistingBookingAccordion,
    NLoading,
    MessageBox,
    TripList,
  },
  mixins: [ DateMixin ],
  computed: {
    ...mapState([
      'query',
      'departureTrip',
      'departureTripLoading',
      'departureTrips',
      'departureTripsError',
      'departureTripsLoading'
    ]),
    ...mapGetters([
      'step',
      'departureDate',
      'departureTrip',
      'returnDate',
      'origin',
      'destination',
      'discountCode',
      'originId',
      'originName',
      'destinationId',
      'destinationName',
      'departureDate',
      'returnDate',
      'ret',
      'text',
      'lang',
      'edit',
      'capacityError',
      'serverTime',
      'faresWithCount'
    ])
  },

  watch: {
    departureTrips: {
      handler (trips) {
        const visibleTrips = filter(trips, r => first(r.steps).transit.local_depart_at > this.serverTime)
        logViewItemList(visibleTrips, this.ret, false, this.discountCode)
      },
      immediate: false
    }
  },
  mounted () {
    // Make sure that to clear any shoppingCarts on this step
    this.$store.commit('setShoppingCart', undefined);
    this.$scrollTo('#app')

    if (this.capacityError) {
      setTimeout(() => {
        this.$store.commit('resetCapacityError')
      }, 4500)
    }
  },
  created () {
    logSelectItem(this.originName, this.destinationName, this.faresWithCount, this.discountCode, this.departureDate, this.returnDate)
  },
  methods: {
    async handleTripSelected (trip) {
      this.$store.commit('setDepartureTrip', trip)
      const shoppingCart = await buildShoppingCart(memberId())

      if (this.returnDate) {
        this.$router.push({ name: 'hjemreise', query: { ...this.query, departureTripId: trip.id } })
      } else {
        if (this.edit) {
          this.$router.push({ name: 'produkter', query: { shoppingCart: shoppingCart.id, edit_id: this.edit.id, edit_booking_number: this.edit.bookingNumber } })
        } else {
          this.$router.push({ name: 'produkter', query: { shoppingCart: shoppingCart.id } })
        }
      }
    },
    handleDateChanged (date) {
      this.$store.commit('setDepartureDate', date)
    },
    goBack () {
      this.$router.push({ name: 'reise', query: { ...this.query } })
    }
  }
}

</script>

