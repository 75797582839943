<template>
  <div 
    class="border-4 text-left flex flex-row gap-4 bg-opacity-25 p-3"
    :class="{
      'border-yellow-500': (type === 'info'),
      'border-red-error': (type === 'error'),
      'border-black': (type === 'warning')
    }"
  >
    <div> 
      <icon-info 
        v-if="type === 'info'"
        class="w-8"
      />
      <icon-warning 
        v-if="type === 'warning'"
        class="w-8"
      />
      <icon-error 
        v-if="type === 'error'"
        class="w-8"
      />
    </div>
    <div>
      <h2 
        v-if="$slots.header"
        class="text-nw-base text-black" 
      >
        <slot name="header" />
      </h2>
      <div
        v-if="$slots.main"
        class="text-nw-base text-left text-black"
        :class="$slots.header ? 'mt-5' : ''" 
      >
        <slot name="main" />
      </div>
    </div>
  </div>
</template>
<script>
import IconInfo from '@/components/icons/IconInfo.vue'
import IconWarning from '@/components/icons/IconWarning.vue'
import IconError from '@/components/icons/IconError.vue'
export default {
  name: 'MessageBox',
  components: {
    IconInfo,
    IconWarning,
    IconError
  },
  props: {
    type: {
      type: String,
      default: 'info' // Supports info, error, warning
    }
  },
  computed: {
    classes () {
      return {
      'border-yellow-500': (this.type === 'info'),
      'border-red-error': (this.type === 'error'),
      'border-yellow-600': (this.type === 'warning')
    }
    }
  }
}
</script>