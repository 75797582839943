<template>
  <button
    :disabled="working"
    class="flex text-nw-sm justify-center py-[6px] px-3 rounded-full"
    :class="buttonClass"
    @click="click"
  >
    <div class="flex flex-row">
      <span 
        class="my-auto whitespace-no-wrap"
        :class="spanClass"
      >
        <slot />
      </span>
      <n-loading-white 
        v-if="working" 
        class="col w-6 ml-2" 
      />
    </div>
  </button>
</template>

<script>
import NLoadingWhite from '../NLoadingWhite.vue'
export default {
  name: 'NButton',
  components: {
    NLoadingWhite
  },
  props: {
    working: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    softDisabled: {
      type: Boolean,
      default: false
    },
    spanClass: {
      type: String,
      default: ''
    }
  },
 
  computed: {
    buttonClass () {
      return {
        'bg-blue-500 hover:bg-blue-hover text-white': !this.inverted,
        'border-2 hover:border-blue-hover hover:text-blue-hover hover:bg-grey-300 border-blue-500 text-blue-500': this.inverted,
        'opacity-75 cursor-wait': this.working,
        'bg-color-button-disabled hover:bg-color-button-disabled hover:opacity-90  cursor-not-allowed': this.softDisabled
      }
    }
  },
  methods: {
    click (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style scoped>

button {

}

</style>
