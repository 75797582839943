<template>
  <!-- Tailwind UI -->
  <div>
    <label
      :for="id"
      class="block text-nw-base font-medium opacity-1 text-blue-500"
    >
      {{ label }}
      <small v-if="sublabel">
        {{ sublabel }}
      </small>
    </label>
    <div 
      class="mt-1 relative"
    >
      <input
        :id="id"
        v-model="content"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        class="form-input outline-none p-3 text-nw-sm md:text-nw-base text-blue-500 border-blue-500 border focus:border-yellow-500 focus:border-2 shadow-none rounded-none block w-full sm:leading-5 disabled:opacity-75"
        :class="error ? 'border-red-500' : ''"
        :type="type"
        @input="input"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'NInput',
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    sublabel: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: undefined
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    autocomplete: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      content: this.value
    }
  },
  watch: {
    value (value) {
      this.content = value
    }
  },
  methods: {
    input () {
      this.$emit('input', this.content)
    }
  },
}
</script>

<style scoped>
  input:read-only {
    @apply text-grey-500 bg-greylight-500 cursor-not-allowed !important
  }
</style>
