<template>
  <div 
    v-if="step.transit" 
    class="mt-10"
  >
    <stop-item 
      :date-time="step.transit.local_depart_at"
      :stop-name="step.transit.departure_stop.name"
      :platform="step.transit.departure_platform"
      :latitude="step.start_location.latitude"
      :longitude="step.start_location.longitude"
      position="top"
    />

    <stop-list :step="step" />

    <stop-item 
      :date-time="step.transit.local_arrive_at"
      :stop-name="step.transit.arrival_stop.name"
      :platform="step.transit.arrival_platform"
      :latitude="step.end_location.latitude"
      :longitude="step.end_location.longitude"
      position="bottom"
    />
  </div>
</template>

<script>

import api from './../api/index'
import StopItem from './StopItem.vue'
import StopList from './StopList'
import { DateMixin } from './mixins/DateMixin'

export default {
  name: 'TripExpandedStop',
  components: {
    StopItem,
    StopList
  },
  mixins: [DateMixin],
  props: {
    step: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      stopsVisible: false,
      startMapVisible: false,
      endMapVisible: false,
      loaded: false
    }
  },
  async created() {
    if (this.step.transit) {
      let details = await api.JourneyById(this.step.transit.journey.id)
      this.$set(this.step.transit.journey, 'details', details)
    }
  }
}

</script>

