<template>
  <div class="p-5">
    <!-- If not logged in, show login -->
    <logon v-if="show == null" />
    <!-- If forgotten password -->
    <div v-if="show == 'glemt-passord'">
      <forgotten-password />
    </div>
    <!-- if reset password -->
    <div v-if="show == 'nullstill-passord'">
      <reset-password />
    </div>
    <!-- if register -->
    <div v-if="show == 'registrer'">
      <register-user />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RegisterUser from '../components/RegisterUser.vue'
import ForgottenPassword from '../components/ForgottenPassword.vue'
import ResetPassword from '../components/ResetPassword.vue'
import Logon from '../components/Logon.vue'

export default {
  name: 'Login',
  components: {
    RegisterUser,
    ForgottenPassword,
    ResetPassword,
    Logon
  },
  props: {
    show: {
      type: String,
      default: null
    }
  },
}
</script>
