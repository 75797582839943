// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icon-person.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".picker[data-v-60a931f2]{height:3rem;width:100%;border-width:1px;--tw-border-opacity:1;border-color:rgb(42 52 122/var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgb(255 255 255/var(--tw-bg-opacity));background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0;background-repeat:no-repeat;padding:.5rem;padding-right:.75rem;padding-left:3rem;text-align:left;font-size:1.125rem;--tw-text-opacity:1;color:rgb(42 52 122/var(--tw-text-opacity));outline:2px solid transparent;outline-offset:2px;background-position-x:14px}.picker.invalid[data-v-60a931f2]{border-width:2px;--tw-border-opacity:1;border-color:rgb(249 78 78/var(--tw-border-opacity))}.picker[data-v-60a931f2]:focus{border-width:2px!important;--tw-border-opacity:1!important;border-color:rgb(255 223 90/var(--tw-border-opacity))!important}.picker.placeholder[data-v-60a931f2]{--tw-text-opacity:1;color:rgb(89 89 89/var(--tw-text-opacity))}.picker.open[data-v-60a931f2]{border-width:2px;--tw-border-opacity:1;border-color:rgb(255 223 90/var(--tw-border-opacity))}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
